import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../Store/Slices/User/userSlice";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { sentMessage } from "../api";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useSelector } from "react-redux";

const useSentMessage = ({ refetch }) => {
  const { userID } = useParams();
  const activeUser = userID.split(/active="[^"]*"(?:&type="[^"]*")?/)[0] ||
  sessionStorage.getItem("active-user");
  const dispatch = useDispatch();
  const { messageData } = useSelector((state) => state.global);
  const validationSchema = Yup.object({
    messageList:  Yup.object(),
    message: Yup.string()
    .matches(/^[^\s].*$/, "No leading or trailing spaces allowed")
    .required("Enter Message"),
    ar_message: Yup.string()
    .required("Enter Message(أدخل الرسالة)").matches(/^[^\s].*$/, "(لا يسمح بالمسافات في البداية أو النهاية)No leading or trailing spaces allowed"),
  });
  const formik = useFormik({
    initialValues: {
      messageList: "",
      message: "",
      ar_message:"",
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      let data = {
        user_id: activeUser,
        template_id: values?.messageList?._id ??null,
        message: values?.message,
        ar_message: values?.ar_message
      };
      sentMessage(data).then((response) => {
        if (response?.data?.code === 200) {
          handleCloseModal();
          toast.success("Message sent successfully");
          refetch();
        } else if (response?.code === 422) {
          let errors = response?.errors;
          let errorFields = Object.keys(errors);
          errorFields?.forEach((field) => {
            formik.setFieldError(field, errors[field]);
          });
          setSubmitting(false);
        } else {
          toast.error("Something went wrong");
          setSubmitting(false);
        }
      });
    },
  });
  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.messageModal = false)));
  };

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  return {
    formik,
    messageData,
    getFieldError,
    handleCloseModal,
  };
};

export default useSentMessage;
