import { getAxiosInstance } from "../../../API";

export const ExportReportTable = async (body) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/admin/report/export", body);
      return response;
    } catch (error) {
      return error.response.data;
    }
  };