import React from "react";
import useContact from "./useContact";
import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import Select from "react-select";
import Style from "./contact.module.scss";

const Contact = () => {
  const { formik, countryCodeOptions, canUpdate, getFieldError, handleReset } =
    useContact();
  return (
    <div className={Style.root}>
      <Input
        label={"Full Name"}
        type="text"
        id="name"
        name="name"
        className={`pro-input lg ${getFieldError("name") && " error"}`}
        {...formik.getFieldProps("name")}
        error={getFieldError("name")}
        errorMessage={getFieldError("name")}
      />
      <Input
        label={"Email"}
        type="text"
        id="email"
        name="email"
        className={`pro-input lg ${getFieldError("email") && " error"}`}
        {...formik.getFieldProps("email")}
        error={getFieldError("email")}
        errorMessage={getFieldError("email")}
      />
      <div className="input-wrap pro-mb-4 ">
        <div className="input-country-wrap ">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Contact Number
          </label>
          <div className="input-main-wrap verify-btn-wrap">
            <div className="code-wrap pro-mb-4 ">
              <Select
                id="country_code"
                options={countryCodeOptions}
                getOptionLabel={(option) => `${option.label}`}
                getOptionValue={(option) => `${option.value}`}
                value={formik?.values?.country_code}
                classNamePrefix="pro-input"
                onBlur={formik.handleBlur("country_code")}
                menuPlacement="auto"
                onChange={(value) =>
                  formik.setFieldValue("country_code", value)
                }
              ></Select>
            </div>
            <input
              type="text"
              id="mobile"
              name="mobile"
              className={`pro-input lg ${
                formik.errors.mobile && formik.touched.mobile && " error"
              }`}
              {...formik.getFieldProps("mobile")}
            />
          </div>
          {formik.errors.mobile && formik.touched.mobile && (
            <span className="error-text">{formik.errors.mobile}</span>
          )}
        </div>
        <div
          className={`col-12 pro-d-flex pro-justify-end pro-mt-4 ${"offcanvas-footer-sticky-btn"} m-4 `}
        >
          <Button
            className={"pro-btn-link lg pro-px-5"}
            onClick={handleReset}
            type="button"
          >
            Reset
          </Button>

          <Button
            type="submit"
            className={"pro-btn-primary lg pro-ms-3"}
            onClick={formik.handleSubmit}
            disabled={!canUpdate}
          >
            {"Update"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Contact;
