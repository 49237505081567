import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../API";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    if (method === "get") {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("start")}${getParams("end")}filter=${
            params?.filter || "all"
          }&${getParams("sort_by")}sort_order=${
            params?.sort_order || "dec"
          }&items_per_page=${params?.items_per_page || "10"}&${getParams(
            "search"
          )}&status=${params?.status || "All"}&current_page=${
            params?.page || "1"
          }`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    } else {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("start")}${getParams("end")}filter=${
            params?.filter || "all"
          }&${getParams("sort_by")}sort_order=${
            params?.sort_order || "dec"
          }&${getParams("start_date")}&items_per_page=${
            params?.items_per_page || "10"
          }&${getParams("search")}&status=${
            params?.status || "All"
          }&${getParams("social_media")}page=${params?.page || ""}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };

export const payments = createApi({
  reducerPath: "paymentsApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["payout", "PaymentReport"],
  endpoints: (builder) => ({
    getPayoutData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/wallet/payout-list`,
      }),
      providesTags: ["payout"],
    }),
    updatePayoutStatusData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/wallet/payout-request-status-change`,
      }),
      invalidatesTags: ["payout"],
    }),
    updatePayoutTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/field-update`,
      }),

      invalidatesTags: ["payout"],
    }),
    //payment report section
    getPaymentReport: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/wallet/payout-count`,
      }),
      providesTags: ["PaymentReport"],
    }),
  }),
});

export const {
  useGetPayoutDataQuery,
  useGetPaymentReportQuery,
  useUpdatePayoutTableHeadDataMutation,
  useUpdatePayoutStatusDataMutation,
} = payments;
