import {
  Button,
  DataContainer,
  Image,
  // SimpleReport,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Style from "./socialcontainer.module.scss";
import SocialIcon from "../SocialIcon";
import SocialContainnerShimmer from "../Shimmers/SocialContainerShimmer";
import Assets from "../../../Assets";
import SimpleReport from "../SimpleReport";

const SocialContainer = ({
  isLoading,
  headerData,
  shimmerCount = 3,
  handleEditAction,
  handleDeleteClick,
  hasRemovePermission,
  hasUpdatePermission,
  socialMediaStatus,
  createMedia,
  handleCreateClick,
}) => {
  return (
    <div>
      {isLoading ? (
        <div className="pro-d-flex">
          {[...Array(shimmerCount)].map((_, i) => (
            <div className="col-4 " key={i}>
              <SocialContainnerShimmer key={i} />
            </div>
          ))}
        </div>
      ) : (
        <div className={`row row-cols-1 row-cols-lg-2 g-3 ${Style.row}`}>
          {createMedia && (
            <div className={`${Style.social_wrap}`}>
              <DataContainer>
                <div className="pro-d-flex pro-justify-between pro-items-center ">
                  <div
                    className={`pro-d-flex pro-items-center ${Style.icon_msg}`}
                    onClick={handleCreateClick}
                  >
                    <Image
                      src={Assets.MESSAGE_ICON}
                      width={28}
                      height={28}
                      alt={`MESSAGE`}
                    />
                    <span>Social Media</span>
                  </div>

                  <Button
                    className={`pro-btn-primary pro-ml-2 pro-fw-medium ${Style.btn_connect}`}
                    onClick={handleCreateClick}
                  >
                    Connect
                  </Button>
                </div>
              </DataContainer>
            </div>
          )}
          {headerData?.map((value, index) => {
            return (
              <div key={index} className={Style.cols}>
                <DataContainer key={index}>
                  <>
                    <div className="pro-d-flex pro-justify-between">
                      <div className="pro-d-flex pro-items-center pro-gap-1 pro-mb-4">
                        <SocialIcon icons={[`${value?.stream}`]} />
                        <h6 className="pro-ttl pro-mb-0">
                          {value?.stream?.charAt(0)?.toUpperCase() +
                            value?.stream?.slice(1)}
                        </h6>
                      </div>
                      <div className="pro-d-flex pro-items-center pro-gap-1 pro-mb-4">
                        {socialMediaStatus?.data[value?.stream] === 2 &&
                          value?.stream !== "google" && (
                            <span className="pro-badge badge-warning-outline">
                              Pending
                            </span>
                          )}

                        {hasUpdatePermission && value?.stream !== "google" && (
                          <span
                            onClick={() => handleEditAction(value)}
                            className="pro-ms-auto pro-badge badge-outline-primary cursor-pointer"
                          >
                            Edit
                          </span>
                        )}

                        {hasRemovePermission && value?.stream !== "google" && (
                          <span
                            onClick={() => handleDeleteClick(value)}
                            className="pro-ms-auto pro-badge badge-danger cursor-pointer"
                          >
                            Delete
                          </span>
                        )}
                      </div>
                    </div>
                    {value?.stream !== "google" ? (
                      // <SimpleReport
                      //   data={value?.socialData}
                      //   propStyle={{ item: Style.report_root }}
                      // />
                      <SimpleReport
                        data={value?.socialData}
                        // propStyle={{ item: Style.report_root }}
                      />
                    ) : (
                      <div className={Style.google_card}></div>
                    )}
                  </>
                </DataContainer>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SocialContainer;
