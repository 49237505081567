import { useSelector, useDispatch } from "react-redux";
import { updateConfig } from "../../../../../Store/Slices/User/userSlice";

const useOverview = () => {
  const dispatch = useDispatch();
  const { basicData } = useSelector((state) => state.global);
  const { isSocialConnectionEdit } = useSelector((state) => state.users);

  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.showCreateModal = false)));
  };

  const handleNext = () => {
    dispatch(updateConfig((state) => (state.formActiveTab = "Posts")));
  };

  return { handleCloseModal, handleNext, basicData, isSocialConnectionEdit };
};

export default useOverview;
