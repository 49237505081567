import moment from 'moment-timezone';

export const getFormatedDate = (date) => {
  return moment(new Date(date)).format("YYYY-MM-DD");
};
export const getFormatDate = (date) => {
  return moment(new Date(date)).format("hh:mm A,  DD-MM-YYYY");
};
export const formattedDate = (date) => {
  return moment(date).format('D MMM YYYY');
};
