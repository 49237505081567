import React from "react";
import usePosts from "./usePosts";
import PostItem from "../Posts/PostItem";
import { Button } from "@wac-ui-dashboard/wac_component_library";

const PostsTab = ({ formik, getFieldError, handleSubmit }) => {
  const { basicData, handleCloseModal, isSocialConnectionEdit, handleAddPost } =
    usePosts({ formik });
  let temp = [];

  return (
    <div className="pro-mb-4">
      {formik?.values?.post?.map((value, index) => {
        temp = [...temp, formik?.values?.post[index]?.post_url];
        return (
          <>
            <label className="pro-font-sm pro-mt-3 pro-fw-medium">
              Post {index + 1}
            </label>
            <PostItem
              formik={formik}
              values={value}
              key={index}
              getFieldError={getFieldError}
              itmIndex={index}
              basicData={basicData}
              tempIds={temp}
            />
          </>
        );
      })}

      <button
        className={"pro-btn pro-btn-outline pro-items-center pro-mt-3 lg"}
        type="button"
        onClick={handleAddPost}
      >
        <span className="material-symbols-outlined">add_circle</span>
        <span>Add Post</span>
      </button>

      <div
        className={`col-12 pro-d-flex pro-justify-end pro-py-4  pro-mt-auto ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={(e) => handleSubmit(e)}
        >
          {isSocialConnectionEdit ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default PostsTab;
