import { getAxiosInstance } from "../../../../API";

export const getGlobalSearchData = async ({ searchKey, page }) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `admin/global-search?search=${searchKey}&page_size=10&page=${page}`
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const markNotificationAsRead = async () => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/admin/notification/mark-all-read`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const MarkNotificationRead = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/notification/read`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const ProfileSubmit = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/profile/update`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
