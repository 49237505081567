import { getAxiosInstance } from "../../API";

export const campaignStatusChange = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(
      "/admin/campaign/request-approve-reject",
      body
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const campaignStop = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("/admin/campaign/stop-campaign", body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const ExportCampTable = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("/admin/campaign/export", body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const ExportCampInnerTable = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(
      "/admin/campaign/campaign-user-export",
      body
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};
