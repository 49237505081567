import React from "react";
import useVerifyContent from "./useVerifyContent";
import { Button, Image } from "@wac-ui-dashboard/wac_component_library";
import Assets from "../../../../Assets";
import ReactPlayer from "react-player";
import Lables from "./Lables";
import Style from "../contentVerification.module.scss";

const VerifyContent = ({hasBlockPermission,refetch }) => {
  const {
    verifyData,
    formik1,
    formik2,
    rejectBox1,
    rejectBox2,
    getFieldError1,
    getFieldError2,
    handleApproveClick,
    handleRejectClick,
  } = useVerifyContent({ refetch });
  return (
    <div>
      {verifyData?.content_data?.map((val, index) => {
        return (
          <div key={index} className={Style.content_verify_wrap}>
            {val?.content_type === 1 ? (
              <div className={Style.img_verify_wrap}>
                <Image src={val?.content_file_thumbnail ?? Assets?.NO_IMAGE} />
                <Lables value={val} />
                <div className="pro-mb-0 pro-mt-2 no-wrap pro-d-flex pro-gap-3">
                  {val?.status === 1 && (
                    <>
                      <Button
                        className={`pro-btn pro-btn-outline-danger pro-mt-4 pro-px-5 pro-rounded-pill pro-lh-1`}
                        onClick={() => handleRejectClick(1, "reject")}
                      >
                        Reject
                      </Button>
                      <Button
                        className={`pro-btn-outline-success pro-btn pro-rounded-pill pro-mt-4  pro-lh-1`}
                        onClick={() => handleApproveClick(1)}
                      >
                        Approve
                      </Button>
                      {hasBlockPermission && (
                        <Button
                          className={`pro-btn-primary pro-btn pro-mt-4  pro-ms-auto pro-px-5 pro-rounded-pill pro-lh-1`}
                          onClick={() => handleRejectClick(1, "block")}
                        >
                          Block
                        </Button>
                      )}
                    </>
                  )}
                  {val?.status === 2 && (
                    <span className={"badge-success-outline pro-badge"}>
                      Approved
                    </span>
                  )}
                  {val?.status === 3 && (
                    <span className={"badge-danger-outline pro-badge"}>
                      Rejected
                    </span>
                  )}
                </div>
                {rejectBox1 && (
                  <div>
                    <div className="col-12 pro-mb-4">
                      <div className="input-wrap">
                        <label
                          htmlFor="reason"
                          className="pro-font-sm pro-mb-1 pro-fw-medium"
                        >
                          Reason
                        </label>
                        <textarea
                          id="reason"
                          rows={3}
                          name="reason"
                          className={`pro-input lg ${
                            getFieldError1("reason") && "error"
                          } `}
                          value={formik1?.values?.reason ?? ""}
                          onChange={(e) =>
                            formik1.setFieldValue("reason", e.target.value)
                          }
                          onBlur={formik1?.handleBlur("reason")}
                        ></textarea>
                        {getFieldError1("reason") && (
                          <span className="error-text">
                            {getFieldError1("reason")}
                          </span>
                        )}
                      </div>

                      <div className="input-wrap pro-rtl">
                        <label
                          htmlFor="ar_reason"
                          className="pro-font-sm pro-mb-1 pro-fw-medium"
                        >
                          Reason (سبب)
                        </label>
                        <textarea
                          id="ar_reason"
                          rows={3}
                          name="ar_reason"
                          className={`pro-input lg ${
                            getFieldError1("ar_reason") && "error"
                          } `}
                          value={formik1?.values?.ar_reason ?? ""}
                          onChange={(e) =>
                            formik1.setFieldValue("ar_reason", e.target.value)
                          }
                          onBlur={formik1?.handleBlur("ar_reason")}
                        ></textarea>
                        {getFieldError1("ar_reason") && (
                          <span className="error-text">
                            {getFieldError1("ar_reason")}
                          </span>
                        )}
                      </div>
                    </div>

                    <Button
                      className={`pro-btn-outline-primary pro-mt-4  pro-px-5 pro-rounded-pill pro-lh-1`}
                      onClick={formik1?.handleSubmit}
                      type={rejectBox1 ? "submit" : "button"}
                    >
                      Submit
                    </Button>
                  </div>
                )}
              </div>
            ) : (
              <div>
                {val?.content_file ? (
                  <div className={Style.video_verify_wrap}>
                    <div className="video-wrap">
                      <ReactPlayer
                        width={100}
                        height={260}
                        url={val?.content_file}
                        controls
                        
                      />
                    </div>
                    <Lables value={val} />
                    <div className="pro-mb-0 no-wrap pro-d-flex pro-gap-3 ">
                      {val?.status === 1 && (
                        <>
                          <Button
                            className={`pro-btn pro-btn-outline-danger pro-mt-4 pro-px-5 pro-rounded-pill pro-lh-1`}
                            onClick={() => handleRejectClick(2, "reject")}
                          >
                            Reject
                          </Button>
                          <Button
                            className={`pro-btn pro-btn-outline-success pro-rounded-pill pro-mt-4 pro-lh-1`}
                            onClick={() => handleApproveClick(2)}
                          >
                            Approve
                          </Button>
                          {hasBlockPermission && (
                            <Button
                              className={`pro-btn pro-btn-primary pro-mt-4 pro-ms-auto pro-px-5 pro-rounded-pill pro-lh-1`}
                              onClick={() => handleRejectClick(2, "block")}
                            >
                              Block
                            </Button>
                          )}
                        </>
                      )}

                      {val?.status === 2 && (
                        <span className={"badge-success-outline pro-badge"}>
                          Approved
                        </span>
                      )}
                      {val?.status === 3 && (
                        <span className={"badge-danger-outline pro-badge"}>
                          Rejected
                        </span>
                      )}
                    </div>
                    {rejectBox2 && (
                      <div>
                        <div className="col-12 pro-mb-4">
                          <div className="input-wrap">
                            <label
                              htmlFor="reason"
                              className="pro-font-sm pro-mb-1 pro-fw-medium"
                            >
                              Reason
                            </label>
                            <textarea
                              id="reason"
                              rows={3}
                              name="reason"
                              className={`pro-input lg ${
                                getFieldError2("reason") && "error"
                              } `}
                              value={formik2?.values?.reason ?? ""}
                              onChange={(e) =>
                                formik2.setFieldValue("reason", e.target.value)
                              }
                              onBlur={formik2?.handleBlur("reason")}
                            ></textarea>
                            {getFieldError2("reason") && (
                              <span className="error-text">
                                {getFieldError2("reason")}
                              </span>
                            )}
                          </div>

                          <div className="input-wrap pro-rtl">
                            <label
                              htmlFor="ar_reason"
                              className="pro-font-sm pro-mb-1 pro-fw-medium"
                            >
                              Reason (سبب)
                            </label>
                            <textarea
                              id="ar_reason"
                              rows={3}
                              name="ar_reason"
                              className={`pro-input lg ${
                                getFieldError2("ar_reason") && "error"
                              } `}
                              value={formik2?.values?.ar_reason ?? ""}
                              onChange={(e) =>
                                formik2.setFieldValue(
                                  "ar_reason",
                                  e.target.value
                                )
                              }
                              onBlur={formik2?.handleBlur("ar_reason")}
                            ></textarea>
                            {getFieldError2("ar_reason") && (
                              <span className="error-text">
                                {getFieldError2("ar_reason")}
                              </span>
                            )}
                          </div>
                        </div>
                        <Button
                          className={`pro-btn-outline-primary pro-px-5 pro-mt-4  pro-rounded-pill pro-lh-1`}
                          onClick={formik2?.handleSubmit}
                          type={rejectBox2 ? "submit" : "button"}
                        >
                          Submit
                        </Button>
                      </div>
                    )}
                  </div>
                ) : (
                  <Image src={Assets?.NO_VIDEO} />
                )}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default VerifyContent;
