import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../Store/Slices/Global";
import {
  useGetCampaignCreateDataQuery,
  useUpdateCampaignCreateTableHeadDataMutation,
} from "../../../Store/Queries/Campaign";
import {
  resetValuesAndErrors,
  updateConfig,
} from "../../../Store/Slices/Campaign/Creation/CreationSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { Image } from "@wac-ui-dashboard/wac_component_library";
import Style from "../campaign.module.scss";
import { getFormatedDate } from "../../../utils/Table";
import { getCampaignEditData } from "./CreateForm/api";
import SocialIcons from "../../Global/SocialIcons";
import { ExportCampTable } from "../api";
import { toast } from "react-toastify";
import { getInterestData } from "../../../Store/Slices/Global";
const useCreation = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const activeTab = searchParams.get("activeTab");
  const [selectValues, setSelectValues] = useState({
    editText: "Edit",
    editIcon: <span className="material-symbols-outlined">edit</span>,
    formTitle: "Edit Campaign",
  });
  const dispatch = useDispatch();
  const campaignCreateState = useSelector((state) => state.campaignCreate);
  const { tableFields, showEditModal, currentUrl } = useSelector(
    (state) => state.global
  );
  const [updateCampaignCreateFields] =
    useUpdateCampaignCreateTableHeadDataMutation();

  const {
    data: campaignCreateList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetCampaignCreateDataQuery({
    items_per_page: campaignCreateState.currentPageSize,
    current_page: campaignCreateState.currentPage,
    sort_by: campaignCreateState.sortBy,
    sort_order: campaignCreateState.sortOrder,
    start_date: campaignCreateState?.filter?.fromDate
      ? getFormatedDate(campaignCreateState?.filter?.fromDate)
      : "",
    end_date: campaignCreateState?.filter?.toDate
      ? getFormatedDate(campaignCreateState?.filter?.toDate)
      : "",
    search: campaignCreateState.search,
    social_media:
      campaignCreateState.activeTab === "all"
        ? ["instagram", "youtube", "facebook", "tiktok", "google"]
        : [campaignCreateState.activeTab],
    live_status:
      campaignCreateState?.filter?.liveStatus?.id === 1
        ? true
        : campaignCreateState?.filter?.liveStatus?.id === 2
        ? false
        : "",
    campaign_type: campaignCreateState?.filter?.campaignType?.id ?? "",
    status: campaignCreateState?.filter?.status?.id ?? "",
    payment_type: campaignCreateState?.filter?.paymentType?.id ?? "",
    interests: campaignCreateState?.filter?.interests?.map((v) => v?._id) ?? [],
  });

  useEffect(() => {
    if (currentUrl?.includes("campaign-details")) {
      dispatch(
        updateConfig((state) => {
          state.activeTab = activeTab;
          state.clearSelection = true;
        })
      );
      dispatch(
        globalUpdateConfig((state) => {
          state.currentUrl = "";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.activeTab = activeTab;
          state.clearSelection = true;
          state.currentPage = 1;
          state.currentPageSize = 10;
        })
      );
    }

    const clearSelectionTimeout = setTimeout(() => {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }, 200);

    return () => clearTimeout(clearSelectionTimeout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const navigation = [
    {
      label: "All",
      title: "All",
      link: `/campaign?activeTab=all`,
      active: activeTab === "all",
    },
    {
      label: "Instagram",
      title: "Instagram",
      link: `/campaign?activeTab=instagram`,
      active: activeTab === "instagram",
    },
    {
      label: "Facebook",
      title: "Facebook",
      link: `/campaign?activeTab=facebook`,
      active: activeTab === "facebook",
    },
    {
      label: "Youtube",
      title: "Youtube",
      link: `/campaign?activeTab=youtube`,
      active: activeTab === "youtube",
    },
    {
      label: "Tiktok",
      title: "Tiktok",
      link: `/campaign?activeTab=tiktok`,
      active: activeTab === "tiktok",
    },
    // {
    //   label: "LinkedIn",
    //   title: "LinkedIn",
    //   link: `/campaign?activeTab=linkedin`,
    //   active: activeTab === "linkedin",
    // },
    {
      label: "Google",
      title: "Google",
      link: `/campaign?activeTab=google`,
      active: activeTab === "google",
    },
  ];
  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = campaignCreateList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [campaignCreateList]);

  const handleLiveStop = (data) => {
    const id = data?._id;
    dispatch(
      updateConfig((state) => {
        state.selectedIteamId = id;
        state.campaignStopModal = true;
      })
    );
  };

  // removed as per cr
  // const handleChange = (data) => {
  //   const newStatus = data.status === "Active" ? true : false;
  //   let params = {
  //     campaign_id: data?._id,
  //     status: newStatus ? 2 : 1,
  //   };
  //   updateStatus(params);
  //   dispatch(
  //     campaign.util.updateQueryData(
  //       "getCampaignCreateData",
  //       {
  //         items_per_page: campaignCreateState.currentPageSize,
  //         current_page: campaignCreateState.currentPage,
  //         sort_by: campaignCreateState.sortBy,
  //         sort_order: campaignCreateState.sortOrder,
  //         start_date: getFormatedDate(campaignCreateState.startDate),
  //         end_date: getFormatedDate(campaignCreateState.endDate),
  //         search: campaignCreateState.search,
  //         social_media:
  //           campaignCreateState.activeTab === "all"
  //             ? ["instagram", "youtube", "linkedin", "facebook", "tiktok"]
  //             : [campaignCreateState.activeTab],
  //       },
  //       (cachedData) => {
  //         let updatedCachedData = { ...current(cachedData) };
  //         let newResult = updatedCachedData?.data?.data?.map((res) =>
  //           res?._id === data?._id
  //             ? {
  //                 ...res,
  //                 status: data?.status === "Active" ? "Inactive" : "Active",
  //               }
  //             : res
  //         );
  //         updatedCachedData = {
  //           ...updatedCachedData,
  //           data: {
  //             ...updatedCachedData.data,
  //             data: newResult,
  //           },
  //         };
  //         return updatedCachedData;
  //       }
  //     )
  //   );
  // };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      // interest: (feild, data) => (
      //   <div>
      //     {data[feild]?.map((value, index, array) => {
      //       const isLastItem = index === array.length - 1;
      //       return (
      //         <React.Fragment key={index}>
      //           <p className="pro-mb-1 no-wrap ">
      //             {value}
      //             {!isLastItem && ","}
      //           </p>
      //         </React.Fragment>
      //       );
      //     })}
      //   </div>
      // ),
      interest: (field, data) => {
        const interests = data[field];
        if (interests?.length > 2) {
          const displayedInterests = interests.slice(0, 2);
          return (
            <div className=" pro-d-flex pro-flex-wrap pro-gap-2">
              {displayedInterests.map((value, index) => (
                <span key={index} className={`pro-badge badge-primary-light`}>
                  {value}
                </span>
              ))}
              <span className={`pro-badge badge-primary-light`}>...</span>
            </div>
          );
        } else {
          return (
            <div className=" pro-d-flex pro-flex-wrap pro-gap-2">
              {interests?.map((value, index) => (
                <span key={index} className={`pro-badge badge-primary-light`}>
                  {value}
                </span>
              ))}
            </div>
          );
        }
      },
      language: (feild, data) => (
        <div>
          {data[feild]?.map((value, index, array) => {
            const isLastItem = index === array.length - 1;
            return (
              <React.Fragment key={index}>
                <p className="pro-mb-1 no-wrap ">
                  {value}
                  {!isLastItem && ","}
                </p>
              </React.Fragment>
            );
          })}
        </div>
      ),
      start_date: (feild, data) => (
        <p className="pro-mb-0 no-wrap ">{data[feild]}</p>
      ),
      end_date: (feild, data) => (
        <p className="pro-mb-0 no-wrap ">{data[feild]}</p>
      ),
      campaign_unique_id: (feild, data) => (
        <p className="pro-mb-0 no-wrap">{data[feild]}</p>
      ),
      title: (feild, data) => (
        <p
          className={`pro-mb-0 ${
            campaignCreateList?.data?.permissions?.can_view
              ? `pro-pnt text-truncate ${Style.campaign_title}`
              : ""
          }`}
          onClick={() => {
            if (campaignCreateList?.data?.permissions?.can_view) {
              dispatch(
                updateConfig((state) => {
                  state.detailsPage.currentPageSize = 10;
                  state.detailsPage.currentPage = 1;
                })
              );
              navigate({
                pathname: `/campaign-details/${data?._id}activeTab="all"&type="campaign"&active="${activeTab}"`,
              });
              sessionStorage.setItem("active-campaign", `${data?._id}`);
            }
          }}
        >
          {data?.[feild]}
        </p>
      ),
      brand_name: (feild, data) => (
        <div className={Style.brand}>
          <div className="pro-avatar">
            <Image
              src={data["brand_logo_thumbnail"]}
              alt="brand-logo"
              width={25}
              height={25}
            />
          </div>
          <p className="pro-mb-0 pro-ms-2 no-wrap">{data[feild]}</p>
        </div>
      ),
      budget: (feild, data) => (
        <div>
          {(data[feild]?.total || data[feild]?.total === 0) && (
            <p className="pro-mb-1 no-wrap ">Total: {data[feild]?.total}</p>
          )}
          {(!!data[feild]?.balance || data[feild]?.balance === 0) && (
            <p className="pro-mb-0 no-wrap ">Balance: {data[feild]?.balance}</p>
          )}
        </div>
      ),
      social_media: (feild, data) => {
        return <SocialIcons icons={data[feild]} hasToolTip />;
      },
      is_live: (feild, data) => (
        <div className="pro-d-flex pro-items-center pro-gap-2 pro-p-2">
          <p className="pro-mb-0">{data[feild] ? "Yes" : "No"}</p>
          {data[feild] && (
            <span
              className={"badge-danger pro-badge cursor-pointer"}
              onClick={() => handleLiveStop(data)}
            >
              {"Stop"}
            </span>
          )}
        </div>
      ),
      status: (feild, data) => {
        const status = data[feild];
        return (
          <>
            {status === "Active" ? (
              <span className={"badge-success-outline pro-badge"}>
                {status}
              </span>
            ) : status === "Pending" ? (
              <span className={"badge-warning-outline pro-badge"}>
                {status}
              </span>
            ) : status === "Inactive" ? (
              <span className={"badge-pending-outline pro-badge"}>
                {status}
              </span>
            ) : status === "Completed" ? (
              <span className={"badge-success-outline pro-badge"}>
                {status}
              </span>
            ) : status === "Stopped" ? (
              <span className={"badge-danger-outline pro-badge"}>{status}</span>
            ) : (
              <span className={"badge-grey-outline pro-badge"}>{status}</span>
            )}
          </>
        );
      },
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.filter.modal = false;
        state.filter.campaignType = "";
        state.filter.interests = [];
        state.filter.status = "";
        state.filter.liveStatus = "";
        state.filter.paymentType = "";
        state.filter.toDate = "";
        state.filter.fromDate = "";
        state.search = "";
        state.currentPage = 1;
        state.currentPageSize = 10;
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (campaignCreateState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            campaignCreateState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleCreateClick = () => {
    dispatch(getInterestData());
    dispatch(
      updateConfig((state) => {
        state.createModal = !state.createModal;
        state.isLiveEdit = false;
        state.formActiveTab = "Overview";
        state.isEdit = false;
        state.isDuplicate = false;
        state.campainId = "";
        state.isCampaignStatus="";
        state.maps = {
          lat: 23.4241,
          lng: 53.8478,
          place: "",
        };
        state.selectedLocation = {
          lat: "",
          lng: "",
          place: "",
        };
      })
    );
    dispatch(resetValuesAndErrors());
  };

  const handleEditClick = (data) => {
    dispatch(getInterestData());
    const id = data?.[0];
    dispatch(
      updateConfig((state) => {
        state.isEdit = true;
        state.isDuplicate = false;
        state.createModal = true;
        state.formActiveTab = "Overview";
        state.campainId = id;
      })
    );
    getCampaignEditData(id).then((response) => {
      if (response?.data?.code === 200) {
        dispatch(
          updateConfig((state) => {
            state.selectedData = response?.data?.data;
          })
        );
      }
    });
  };
  const handleDuplicateClick = (data) => {
    dispatch(getInterestData());
    const id = data?.[0];
    dispatch(
      updateConfig((state) => {
        state.isDuplicate = true;
        state.createModal = true;
        state.formActiveTab = "Overview";
        state.isCampaignStatus ="";
        state.isLiveEdit=""
        state.isEdit= false
      })
    );
    getCampaignEditData(id).then((response) => {
      if (response?.data?.code === 200) {
        dispatch(
          updateConfig((state) => {
            state.selectedData = response?.data?.data;
          })
        );
      }
    });
  };

  const handleCloseCamStopModal = () => {
    dispatch(updateConfig((state) => (state.campaignStopModal = false)));
  };

  const handleFilterClick = () => {
    dispatch(updateConfig((state) => (state.filter.modal = true)));
  };

  const handleFilterClose = () => {
    dispatch(updateConfig((state) => (state.filter.modal = false)));
  };

  const handleExportTable = () => {
    let data = {
      start_date: campaignCreateState?.filter?.fromDate
        ? getFormatedDate(campaignCreateState?.filter?.fromDate)
        : "",
      end_date: campaignCreateState?.filter?.toDate
        ? getFormatedDate(campaignCreateState?.filter?.toDate)
        : "",
      search: campaignCreateState.search,
      social_media:
        campaignCreateState.activeTab === "all"
          ? ["instagram", "youtube", "facebook", "tiktok", "google"]
          : [campaignCreateState.activeTab],
      live_status:
        campaignCreateState?.filter?.liveStatus?.id === 1
          ? true
          : campaignCreateState?.filter?.liveStatus?.id === 2
          ? false
          : "",
      campaign_type: campaignCreateState?.filter?.campaignType?.id ?? "",
      status: campaignCreateState?.filter?.status?.id ?? "",
      payment_type: campaignCreateState?.filter?.paymentType?.id ?? "",
      interests:
        campaignCreateState?.filter?.interests?.map((v) => v?._id) ?? [],
    };
    ExportCampTable(data).then((response) => {
      if (response?.data?.code === 200) {
        toast.success(
          response?.data?.message ?? "Table has send to registered email"
        );
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  const handleSelectedId = (id) => {
    const selectedId = id[0];
    const selectedCampaign = campaignCreateList?.data?.data?.filter(
      (item) => item?._id === selectedId
    );
    if(campaignCreateState?.isDuplicate){
      dispatch(
        updateConfig((state) => {
          state.isLiveEdit =
            selectedCampaign?.[0]?.is_live ||
            selectedCampaign?.[0]?.status === "Completed";
          state.isCampaignStatus = selectedCampaign?.[0]?.is_live
            ? "live"
            : selectedCampaign?.[0]?.status === "Completed"
            ? "completed"
            : selectedCampaign?.[0]?.status === "Stopped"
            ? "stopped"
            : "";
        })
      );
    }
    if (
      selectedCampaign?.[0]?.status === "Completed"||selectedCampaign?.[0]?.status === "Stopped"
    ) {
      setSelectValues({
        editText: "View",
        editIcon: <span className="material-symbols-outlined">Visibility</span>,
        formTitle: "View Campaign",
      });
    } else {
      setSelectValues({
        editText: "Edit",
        editIcon: <span className="material-symbols-outlined">edit</span>,
        formTitle: "Edit Campaign",
      });
    }
  };

  return {
    isLoading,
    isFetching,
    tableFields,
    showEditModal,
    paginationOptions,
    campaignCreateList,
    campaignCreateState,
    tabNavs: navigation,
    selectValues,
    handleSelectedId,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    handlePageSize,
    handlePagination,
    handleEditClick,
    handleClearClick,
    handleExportTable,
    handleCreateClick,
    handleFilterClick,
    handleFilterClose,
    handleEditColumnsClick,
    handleCloseCamStopModal,
    updateCampaignCreateFields,
    handleDuplicateClick,
  };
};

export default useCreation;
