import { useFormik } from "formik";
import countryData from "../../../../utils/countryCode";
import * as Yup from "yup";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addUser, updateUser } from "../api";
import { updateConfig } from "../../../../Store/Slices/User/userSlice";
import { toast } from "react-toastify";

const useAddUser = ({ refetch }) => {
  const { isEdit, profileThumb, submitStatus, userDetails } = useSelector(
    (state) => state.users
  );
  const { basicData,interestData} = useSelector((state) => state.global);
  const dispatch = useDispatch();

  const today = new Date();
  const minDate = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  );

  const initialValues = {
    profile: "",
    name: "",
    email: "",
    country_code: "",
    mobile: "",
    dob: "",
    country: "",
    nationality:"",
    emirates: "",
    city: "",
    gender: "",
    languages: [],
    interests: [],
  };

  const validationSchema = Yup.object().shape({
    profile:
      (!isEdit || !profileThumb) &&
      Yup.mixed()
        .test("fileType", "Please upload a valid image file", (value) => {
          if (value) {
            const supportedFormats = ["image/jpeg", "image/png", "image/gif"]; // Add more formats if needed
            return supportedFormats.includes(value.type);
          }
          return true; // If no file uploaded, pass validation (assuming it's not a required field)
        })
        .test("fileSize", "File size must be less than 5MB", (value) => {
          if (value) {
            const maxSize = 5 * 1024 * 1024; // 2MB in bytes
            return value.size <= maxSize;
          }
          return true; // If no file uploaded, pass validation (assuming it's not a required field)
        }),
    name: Yup.string().required("Enter Name"),
    dob: Yup.string().required("Select Date of Birth"),
    email: Yup.string().required("Enter Email").email("Enter Valid Email"),
    mobile: Yup.string()
      .required("Enter Mobile Number")
      .min(7, "Invalid Mobile Number")
      .max(14, "Invalid Mobile Number"),
    country: Yup.object().required("Select Country"),
    nationality: Yup.object().required("Select Nationality"),
    emirates: Yup.object().required("Select Emirates"),
    gender: Yup.object().required("Select Gender"),
    city: Yup.object().required("Select City"),
    languages: Yup.array()
      .required("Select Atleast One Language")
      .min(1, "Select Atleast One Language"),
    interests: Yup.array()
      .required("Select Interests")
      .min(1, "Select Interests")
      .max(5, "Maximum 5 Interets Allowed"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      dispatch(updateConfig((state) => (state.submitStatus = "pending")));
      const formData = new FormData();
      if (isEdit) {
        formData?.append("user_id", userDetails?.data?._id);
      }
      formData.append("name", values?.name);
      formData.append("email", values?.email);
      formData.append("dob", values?.dob);
      formData.append("country", values?.country?.unique_country_id);
      formData.append("nationality", values?.nationality?.unique_country_id);
      formData.append("emirates", values?.emirates?.unique_state_id);
      formData.append("mobile", values?.mobile?.replace(/ /g, ""));
      formData.append("country_code", values?.country_code?.value);
      formData.append("shortname", values?.country_code?.code);
      formData.append("city", values?.city._id);
      formData.append("gender", values?.gender.name);
      if (values?.profile) {
        formData.append("image", values?.profile);
      }
      values?.interests?.forEach((interest, index) => {
        formData.append(`interest[${index}]`, interest._id);
      });
      values?.languages?.forEach((language, index) => {
        formData.append(`language[${index}]`, language._id);
      });
      if (isEdit) {
        updateUser(formData).then((response) => {
          if (response?.data?.code === 200) {
            dispatch(updateConfig((state) => (state.submitStatus = "success")));
            toast.success("Success");
            refetch();
            handleCloseModal();
          } else if (response?.code === 422) {
            dispatch(updateConfig((state) => (state.submitStatus = "failed")));
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
          } else {
            toast.error("Something went wrong");
            dispatch(updateConfig((state) => (state.submitStatus = "failed")));
          }
        });
      } else {
        addUser(formData).then((response) => {
          if (response?.data?.code === 200) {
            dispatch(updateConfig((state) => (state.submitStatus = "success")));
            toast.success("Success");
            refetch();
            handleCloseModal();
          } else if (response?.code === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
            dispatch(updateConfig((state) => (state.submitStatus = "failed")));
          } else {
            toast.error("Something went wrong");
            dispatch(updateConfig((state) => (state.submitStatus = "failed")));
          }
        });
      }
    },
  });
  useEffect(() => {
    if (isEdit) {
      const countryCode = countryData?.filter(
        (value) => value?.value === userDetails?.data?.country_code
      );
      const country = basicData?.country?.filter(
        (value) => value?.name === userDetails?.data?.country
      );
      const nationality = basicData?.country?.filter(
        (value) => value?.name === userDetails?.data?.nationality
      );
      const state = country?.[0]?.state?.filter(
        (value) => value?.name === userDetails?.data?.state
      );
      const city = state?.[0]?.city?.filter(
        (value) => value?.name === userDetails?.data?.city
      );
      const languages = userDetails?.data?.language?.map((id) => {
        const match = basicData?.language?.find((item) => item._id === id);
        return match ? match : null;
      });
      const interests = userDetails?.data?.interest_id?.map((id) => {
        const match = interestData?.find((item) => item._id === id);
        return match ? match : null;
      });
      const gender = basicData?.gender?.filter(
        (value) => value?.name === userDetails?.data?.gender
      );
      dispatch(
        updateConfig((state) => (state.profileThumb = userDetails?.data?.image))
      );
      formik?.setFieldValue("name", userDetails?.data?.name);
      formik?.setFieldValue("email", userDetails?.data?.email);
      formik?.setFieldValue("country_code", countryCode?.[0]);
      formik?.setFieldValue("mobile", userDetails?.data?.mobile);
      formik?.setFieldValue("dob", userDetails?.data?.dob);
      formik?.setFieldValue("country", country?.[0]);
      formik?.setFieldValue("nationality", nationality?.[0]);
      formik?.setFieldValue("emirates", state?.[0]);
      formik?.setFieldValue("city", city?.[0]);
      formik?.setFieldValue("languages", languages);
      formik?.setFieldValue("interests", interests);
      formik?.setFieldValue("gender", gender?.[0]);
    }
    if (!isEdit) {
      formik?.setFieldValue("country_code", {
        name: "United Arab Emirates",
        code: "AE",
        label: "+971",
        value: "+971",
      });
      const country = basicData?.country?.filter(
        ({ unique_country_id }) => unique_country_id === 224
      )?.[0];
      formik?.setFieldValue("country", country);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, basicData, userDetails?.data],interestData);

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleDobChange = (e) => {
    const d = new Date(e?.target?.value);
    const day = d.getDate().toString().padStart(2, "0");
    const month = (d.getMonth() + 1).toString().padStart(2, "0");
    const year = d.getFullYear();
    formik?.setFieldValue("dob", `${year}-${month}-${day}`);
  };

  const handleProfileChange = (e) => {
    formik?.setFieldTouched("profile", true);
    const profilefile = e?.target?.files?.[0];
    if (profilefile) {
      formik.setFieldValue("profile", profilefile);
      const file = profilefile;
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(
          updateConfig((state) => {
            state.profileThumb = reader.result;
          })
        );
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };

  const handleProfileDelete = () => {
    formik.setFieldValue("profile", "");
    formik?.setFieldTouched("profile", true);
    dispatch(updateConfig((state) => (state.profileThumb = "")));
  };

  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.createModal = false)));
  };

  return {
    formik,
    isEdit,
    minDate,
    basicData,
    interestData,
    profileThumb,
    submitStatus,
    countryCodeOptions: countryData,
    handleProfileChange,
    handleCloseModal,
    handleProfileDelete,
    getFieldError,
    handleDobChange,
  };
};

export default useAddUser;
