import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "",
  search: "",
  activeTab: "All",
  wallet_transaction_id:"",
  status:'',
  declineStopModal: false
};

const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    statusChange: (state, action) => {
      const payload = action.payload;
      state.wallet_transaction_id = payload.wallet_transaction_id;
      state.status = payload.status;
    }
  },
});

export const { updateConfig,statusChange } = paymentsSlice.actions;
export default paymentsSlice.reducer;
