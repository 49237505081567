import { useDispatch, useSelector } from "react-redux";
import { updateConfig } from "../../../../Store/Slices/Campaign/Creation/CreationSlice";
import { useFormik } from "formik";

const useFilter = () => {
  const dispatch = useDispatch();
  const campaignState = useSelector((state) => state.campaignCreate);
  const { basicData } = useSelector((state) => state.global);

  const formik = useFormik({
    initialValues: {
      fromDate: campaignState?.filter?.fromDate,
      toDate: campaignState?.filter?.toDate,
      campaignType: campaignState?.filter?.campaignType,
      status: campaignState?.filter?.status,
      liveStatus: campaignState?.filter?.liveStatus,
      paymentType: campaignState?.filter?.paymentType,
      interests: campaignState?.filter?.interests,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      dispatch(
        updateConfig((state) => {
          state.filter.fromDate = values?.fromDate;
          state.filter.toDate = values?.toDate;
          state.filter.campaignType = values?.campaignType;
          state.filter.status = values?.status;
          state.filter.liveStatus = values?.liveStatus;
          state.filter.paymentType = values?.paymentType;
          state.filter.interests = values?.interests;
          state.filter.modal = false;
          state.currentPageSize = 10;
          state.currentPage = 1;
        })
      );
    },
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleFromDateChange = (e) => {
    formik?.setFieldValue("fromDate", e.target.value);
  };
  const handleToDateChange = (e) => {
    formik?.setFieldValue("toDate", e.target.value);
  };

  const handleLiveStatusChange = (value) => {
    formik?.setFieldValue("liveStatus", value ?? "");
  };
  const handleStatusChange = (value) => {
    formik?.setFieldValue("status", value ?? "");
  };

  const handlePaymentTypeChange = (value) => {
    formik?.setFieldValue("paymentType", value ?? "");
  };

  const handleCampaignTypeChange = (value) => {
    formik?.setFieldValue("campaignType", value ?? "");
  };

  const handleInterestsChange = (value) => {
    formik?.setFieldValue(
      "interests",
      value?.map((v) => v)
    );
  };

  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.filter.modal = false)));
  };

  return {
    formik,
    basicData,
    campaignState,
    getFieldError,
    handleCloseModal,
    handleToDateChange,
    handleStatusChange,
    handleFromDateChange,
    handleInterestsChange,
    handleLiveStatusChange,
    handlePaymentTypeChange,
    handleCampaignTypeChange,
  };
};

export default useFilter;
