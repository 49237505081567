import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useFAQ from "./useFAQ";
import { FaSort } from "react-icons/fa";
import EmptyData from "../../Global/EmptyData";
import AddFAQ from "./AddFAQ";
import { Pagination } from "../../Global/Pagination";

const FAQ = () => {
  const {
    isLoading,
    isFetching,
    tableFields,
    FAQList,
    FAQState,
    paginationOptions,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    handlePageSize,
    handleEditAction,
    handleClearClick,
    handlePagination,
    handleCloseClick,
    handleCreateClick,
    handleDeleteAction,
    handleDeleteModal
  } = useFAQ();
  return (
    <div>
      <HeadingGroup
        title={"FAQs"}
        className={`pro-mb-4`}
        buttonTitle={FAQList?.data?.permissions?.can_create && "Create"}
        handleClick={handleCreateClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          showActions={false}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters
          handleClear={handleClearClick}
          searchInputProps={{ value: FAQState?.search }}
        />
        {FAQList?.data?.data?.length > 0 ? (
          <>
            <Table
              extraClasssName={`table-wrap`}
              multiSelect={false}
              data={FAQList?.data?.data || []}
              uniqueID={"_id"}
              fields={tableFields}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              handleEdit={handleEditAction}
              handleDelete={handleDeleteModal}
              handleSort={handleSort}
              clear={FAQState.clearSelection}
              getRow={getRow}
              loading={isFetching}
              perpage={FAQState?.currentPageSize}
              editable={FAQList?.data?.permissions?.can_update}
              deletable={FAQList?.data?.permissions?.can_delete}
              assignable={false}
              showCheckBox={FAQList?.data?.permissions?.can_update}
            />
            {FAQList?.data?.data?.length > 0 && (
              <Pagination
                currentPage={FAQState?.currentPage}
                totalPageCount={Math.ceil(
                  FAQList?.data?.total_count / FAQState.currentPageSize
                )}
                options={paginationOptions}
                onPageChange={handlePagination}
                onActionChange={handlePageSize}
                center
              />
            )}
          </>
        ) : (
          <EmptyData />
        )}
        <ModalLayout
          show={FAQState?.createModal}
          handleClose={handleCloseClick}
          title={FAQState?.isEdit ? "Edit FAQ" : "Add FAQ"}
          closeicon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
        >
          <div className="pro-m-5">
            <AddFAQ refetch={refetch} />
          </div>
        </ModalLayout>
        <ModalLayout
          show={FAQState?.deleteModal}
          handleClose={handleDeleteModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={"Are you sure delete the selected FAQ ?"}
              subTitle={"This action can't be undo "}
              cancelText={"No"}
              cancelAction={handleDeleteModal}
              submitText={"Yes"}
              submitAction={handleDeleteAction}
              isRight={true}
            />
          </div>
        </ModalLayout>
      </div>
    </div>
  );
};

export default FAQ;
