import { useSelector } from "react-redux";
import {
  useGetCampaignApprovalDataQuery,
  useUpdateCampaignApprovalTableHeadDataMutation,
} from "../../../Store/Queries/Campaign";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../Store/Slices/Global";
import { updateConfig } from "../../../Store/Slices/Campaign/Approval/campaignApprovalSlice";
import { subDays } from "date-fns";
import { getFormatedDate } from "../../../utils/Table";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Image } from "@wac-ui-dashboard/wac_component_library";
import Style from "./approval.module.scss";
import SocialIcons from "../../Global/SocialIcons";
import { campaignStatusChange } from "../api";
import { toast } from "react-toastify";

const useApproval = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const [initialDate, setInitialDate] = useState({
    startDate: subDays(new Date(), 30),
    endDate: new Date(),
    key: "selection",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const campaignApprovalState = useSelector((state) => state.campaignApproval);
  const { tableFields, showEditModal,currentUrl} = useSelector((state) => state.global);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const activeTab = searchParams.get("activeTab");

  const {
    data: campaignApprovalList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetCampaignApprovalDataQuery({
    items_per_page: campaignApprovalState.currentPageSize,
    current_page: campaignApprovalState.currentPage,
    sort_by: campaignApprovalState.sortBy,
    sort_order: campaignApprovalState.sortOrder,
    start_date: getFormatedDate(campaignApprovalState.startDate),
    end_date: getFormatedDate(campaignApprovalState.endDate),
    search: campaignApprovalState.search,
    status: `${
      campaignApprovalState.activeTab === "requests"
        ? "REQUESTED"
        : campaignApprovalState.activeTab === "approved"
        ? "ACCEPTED"
        : campaignApprovalState.activeTab?.toUpperCase()
    }`,
  });
  const [updateCampaignApprovalFields] =
    useUpdateCampaignApprovalTableHeadDataMutation();

  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = 10;
      })
    );
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = campaignApprovalList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [campaignApprovalList]);

  useEffect(() => {
    if (currentUrl?.includes("campaign-details")) {
      dispatch(
        updateConfig((state) => {
          state.activeTab = activeTab;
          state.clearSelection = true;
        })
      );
      dispatch(
        globalUpdateConfig((state) => {
          state.currentUrl=""
        })
      );
    }else{
      dispatch(
        updateConfig((state) => {
          state.activeTab = activeTab;
          state.clearSelection = true;
          state.currentPage = 1;
          state.currentPageSize = 10;
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const navigation = [
    {
      label: "Requests",
      title: "Requests",
      link: `/campaign/approval?activeTab=requests`,
      active: activeTab === "requests",
    },
    {
      label: "Approved",
      title: "Approved",
      link: `/campaign/approval?activeTab=approved`,
      active: activeTab === "approved",
    },
    {
      label: "Rejected",
      title: "Rejected",
      link: `/campaign/approval?activeTab=rejected`,
      active: activeTab === "rejected",
    },
  ];

  const handleApproveClick = (data) => {
    let body = {
      id: data?._id,
      status: 1,
    };
    campaignStatusChange(body).then((response) => {
      if (response?.data?.code === 200) {
        refetch();
        toast.success("Approved");
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  const handleRejectModalClick = (data) => {
    dispatch(
      updateConfig((state) => {
        state.rejectModal = true;
        state.rejectId = data?._id;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      title: (feild, data) => (
        <p
          className={`pro-mb-0 ${
            !campaignApprovalList?.data?.permissions?.can_view
              ? `pro-pnt text-truncate ${Style.name}`
              : ""
          }`}
          onClick={() => {
            if (!campaignApprovalList?.data?.permissions?.can_view) {
              navigate({
                pathname: `/campaign-details/${data?.capmaign_id}activeTab="for-approval"&type="approval"&active="${activeTab}"`,
              });
              sessionStorage.setItem("active-campaign", `${data?.capmaign_id}`);
            }
          }}
        >
          {data?.[feild]}
        </p>
      ),
      brand: (feild, data) => (
        <div className={Style.brand}>
          <div className="pro-avatar">
            <Image
              src={data["brand_thumb_image"]}
              alt="brand-logo"
              width={25}
              height={25}
            />
          </div>
          <p className="pro-mb-0 pro-ms-2 no-wrap">{data[feild]}</p>
        </div>
      ),
      social_media: (feild, data) => <SocialIcons icons={data[feild]} />,
      action: (_, data) => (
        <>
          {data?.is_expired ? (
            <span className={"badge-danger-outline pro-badge"}>
              {"Expired"}
            </span>
          ) : (
            <div className="pro-mb-0 pro-ms-2 no-wrap pro-d-flex ">
              <Button
                className={`pro-btn-outline-primary pro-px-5 pro-rounded-pill`}
                onClick={() => handleRejectModalClick(data)}
              >
                Reject
              </Button>
              <Button
                className={`pro-btn-primary pro-rounded-pill pro-ms-1`}
                onClick={() => handleApproveClick(data)}
              >
                Approve
              </Button>
            </div>
          )}
        </>
      ),

      category: (field, data) => {
        const categories = data[field];
        if (categories?.length > 2) {
          const displayedCategories = categories.slice(0, 2);
          return (
            <div className=" pro-d-flex pro-flex-wrap pro-gap-2">
              {displayedCategories.map((value, index) => (
                <span key={index} className={`pro-badge badge-primary-light`}>
                  {value}
                </span>
              ))}
              <span className={`pro-badge badge-primary-light`}>...</span>
            </div>
          );
        } else {
          return (
            <div className=" pro-d-flex pro-flex-wrap pro-gap-2">
              {categories?.map((value, index) => (
                <span key={index} className={`pro-badge badge-primary-light`}>
                  {value}
                </span>
              ))}
            </div>
          );
        }
      },
      user_name: (feild, data) => (
        <p
          className={`pro-mb-0 ${
            campaignApprovalList?.data?.permissions?.can_view_user
              ? `pro-pnt text-truncate ${Style.name}`
              : ""
          }`}
          onClick={() => {
            if (campaignApprovalList?.data?.permissions?.can_view_user) {
              navigate({
                pathname: `/user-details/${data?.user_id}`,
              });
            }
          }}
        >
          {data?.[feild]}
        </p>
      ),
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => {state.search = ""
      state.currentPage = 1;
      state.currentPageSize = 10;
      state.startDate = subDays(new Date(), 30);
      state.endDate = new Date();
    }));
    setInitialDate({
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: "selection",
    });
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (campaignApprovalState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            campaignApprovalState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleDateChange = (date) => {
    dispatch(
      updateConfig((state) => {
        state.startDate = new Date(date.startDate);
        state.endDate = new Date(date.endDate);
      })
    );
  };

  const handleCloseRejectModal = () => {
    dispatch(updateConfig((state) => (state.rejectModal = false)));
  };

  return {
    isLoading,
    isFetching,
    initialDate,
    tableFields,
    showEditModal,
    paginationOptions,
    campaignApprovalList,
    campaignApprovalState,
    tabNavs: navigation,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    handlePageSize,
    handleDateChange,
    handlePagination,
    handleClearClick,
    handleEditColumnsClick,
    handleCloseRejectModal,
    updateCampaignApprovalFields,
  };
};
export default useApproval;
