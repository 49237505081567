import {
  HeadingGroup,
  ModalLayout,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import EmptyData from "../../Global/EmptyData";
import { Pagination } from "../../Global/Pagination";
import useLanguage from "./useLanguage";
import AddLanguage from "./AddLanguage";

const Language = () => {
  const {
    isLoading,
    isFetching,
    tableFields,
    languageList,
    languageState,
    paginationOptions,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    handlePageSize,
    handleEditAction,
    handleClearClick,
    handlePagination,
    handleCloseClick,
    handleCreateClick,
  } = useLanguage();
  return (
    <div>
      <HeadingGroup
        title={"Language"}
        className={`pro-mb-4`}
        buttonTitle={languageList?.data?.permission?.can_create && "Create"}
        handleClick={handleCreateClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          showActions={false}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters
          handleClear={handleClearClick}
          searchInputProps={{ value: languageState?.search }}
        />
        {languageList?.data?.data?.length !== 0  ? (
          <>
            <Table
              extraClasssName={`table-wrap`}
              multiSelect={false}
              data={languageList?.data?.data || []}
              uniqueID={"_id"}
              fields={tableFields}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              handleEdit={handleEditAction}
              handleSort={handleSort}
              clear={languageState.clearSelection}
              getRow={getRow}
              loading={isFetching}
              perpage={languageState?.currentPageSize}
              editable={languageList?.data?.permission?.can_update}
              deletable={false}
              assignable={false}
              showCheckBox={languageList?.data?.permission?.can_update}
            />
            {languageList?.data?.data?.length > 0 && (
              <Pagination
                currentPage={languageState?.currentPage}
                totalPageCount={Math.ceil(
                  languageList?.data?.total_count /
                  languageState.currentPageSize
                )}
                options={paginationOptions}
                onPageChange={handlePagination}
                onActionChange={handlePageSize}
                defaultValue={paginationOptions.find((item)=>(item?.value===languageState?.currentPageSize))}
                center
              />
            )}
          </>
        ) : (
          <EmptyData />
        )}
        <ModalLayout
          show={languageState?.createModal}
          handleClose={handleCloseClick}
          title={languageState?.isEdit ? "Edit Language" : "Add Language"}
          closeicon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
        >
          <div className="pro-m-5">
            <AddLanguage refetch={refetch}/>
          </div>
        </ModalLayout>
      </div>
    </div>
  );
};

export default Language;
