import { useSelector } from "react-redux";
import {
  interests,
  useGetInterestsDataQuery,
} from "../../../Store/Queries/Interests";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../Store/Slices/Global";
import { updateConfig } from "../../../Store/Slices/Interests/interestsSlice";
import { updateStatus, viewInterest } from "./api";
import { toast } from "react-toastify";
import { current } from "@reduxjs/toolkit";

const useInterests = () => {
  const dispatch = useDispatch();
  const interestsState = useSelector((state) => state.interests);
  const { tableFields } = useSelector((state) => state.global);

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const {
    data: interestsList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetInterestsDataQuery({
    items_per_page: interestsState.currentPageSize,
    current_page: interestsState.currentPage,
    sort_by: interestsState.sortBy,
    sort_order: interestsState.sortOrder,
    search: interestsState.search,
  });

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = interestsList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [interestsList]);

  const handleChange = (data) => {
    const newStatus = data.status === "Active" ? true : false;
    let params = {
      interest_id: data?._id,
      status: newStatus ? "Inactive" : "Active",
    };
    updateStatus(params);
    dispatch(
      interests.util.updateQueryData(
        "getInterestsData",
        {
          items_per_page: interestsState.currentPageSize,
          current_page: interestsState.currentPage,
          sort_by: interestsState.sortBy,
          sort_order: interestsState.sortOrder,
          search: interestsState.search,
        },
        (cachedData) => {
          let updatedCachedData = { ...current(cachedData) };
          let newResult = updatedCachedData?.data?.data?.map((res) =>
            res?._id === data?._id
              ? {
                  ...res,
                  status: data?.status === "Active" ? "Inactive" : "Active",
                }
              : res
          );
          updatedCachedData = {
            ...updatedCachedData,
            data: {
              ...updatedCachedData.data,
              data: newResult,
            },
          };
          return updatedCachedData;
        }
      )
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      status: (feild, data) => {
        const status = data[feild] === "Active";
        return (
          <>
            {interestsList?.data?.permissions?.can_change_status ? (
              <div className="pro-toggle">
                <div className="pro-toggle-box">
                  <input
                    type="checkbox"
                    role="switch"
                    id="status"
                    checked={status}
                    onChange={() => handleChange(data)}
                  />
                  <span></span>
                </div>
              </div>
            ) : data[feild] === "Active" ? (
              <span className={"badge-success-outline pro-badge"}>
                {data[feild]}
              </span>
            ) : (
              <span className={"badge-warning-outline pro-badge"}>
                {data[feild]}
              </span>
            )}
          </>
        );
      },
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (interestsState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = interestsState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.isEdit = true;
        state.createModal = true;
        state.itemId = e?.[0];
      })
    );
    viewInterest(e?.[0]).then((response) => {
      if (response?.data?.code === 200) {
        dispatch(
          updateConfig((state) => (state.itemData = response?.data?.data))
        );
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.isEdit = false;
        state.createModal = true;
      })
    );
  };

  const handleCloseClick = () => {
    dispatch(updateConfig((state) => (state.createModal = false)));
  };

  return {
    isLoading,
    isFetching,
    tableFields,
    interestsList,
    interestsState,
    paginationOptions,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    handlePageSize,
    handleEditAction,
    handlePagination,
    handleCloseClick,
    handleClearClick,
    handleCreateClick,
  };
};

export default useInterests;
