import { getAxiosInstance } from "../../../../API";

export const submitLink = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/campaign/verify-link`, body);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const submitAmount = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/campaign/amount-add`, body);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
