const usePlatform = ({ formik }) => {
  const handleRemoveField = (index) => {
    const data = formik?.values?.platform?.filter(
      (_, itemIndex) => itemIndex !== index
    );
    const infData = formik?.values?.influencer_earnings?.filter(
      (_, itemIndex) => itemIndex !== index
    );
    formik.setFieldValue("platform", data);
    formik.setFieldValue("influencer_earnings", infData);
  };

  return {
    handleRemoveField,
  };
};

export default usePlatform;
