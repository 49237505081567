import React from "react";
import useReject from "./useReject";
import { Button } from "@wac-ui-dashboard/wac_component_library";
import Assets from "../../../../Assets";

const Reject = ({ refetch }) => {
  const { formik, getFieldError, handleCloseModal } = useReject({ refetch });
  return (
    <div className="pro-p-3">
      <div className="col-12 pro-mb-4">
        <div className="input-wrap">
          <label
            htmlFor="reason"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Reason
          </label>
          <textarea
            id="reason"
            rows={3}
            name="reason"
            className={`pro-input lg ${getFieldError("reason") && " error"} `}
            value={formik?.values?.reason}
            onChange={(e) => formik.setFieldValue("reason", e.target.value)}
          ></textarea>
          {getFieldError("reason") && (
            <span className="error-text">{getFieldError("reason")}</span>
          )}
        </div>
      </div>

      <div className="col-12 pro-mb-4 pro-rtl">
        <div className="input-wrap">
          <label
            htmlFor="ar_reason"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Reason (سبب)
          </label>
          <textarea
            id="ar_reason"
            rows={3}
            name="ar_reason"
            className={`pro-input lg ${
              getFieldError("ar_reason") && " error"
            } `}
            value={formik?.values?.ar_reason ?? ""}
            onChange={(e) => formik.setFieldValue("ar_reason", e.target.value)}
          ></textarea>
          {getFieldError("ar_reason") && (
            <span className="error-text">{getFieldError("ar_reason")}</span>
          )}
        </div>
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          className={"pro-btn-primary lg pro-ms-3"}
          onClick={formik.handleSubmit}
          disabled={formik?.isSubmitting}
        >
          {formik?.isSubmitting ? (
            <span className="btn-spinner">
              <img src={Assets.SPINNER} alt="spinner" />
            </span>
          ) : (
            "Reject"
          )}
        </Button>
      </div>
    </div>
  );
};

export default Reject;
