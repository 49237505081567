import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../Store/Slices/Global";
import { useGetSupportDataQuery } from "../../../Store/Queries/Support";
import { updateConfig } from "../../../Store/Slices/Support/suppportSlice";
import Select from "react-select";
import { statusChange } from "./api";
import { toast } from "react-toastify";
import parse from "html-react-parser";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
import {useNavigate } from "react-router-dom";
import Assets from "../../../Assets";

const useSupport = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const dispatch = useDispatch();
  const supportState = useSelector((state) => state.support);
  const { tableFields, basicData } = useSelector((state) => state.global);
  const navigate = useNavigate();
  const {
    data: supportList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetSupportDataQuery({
    items_per_page: supportState.currentPageSize,
    current_page: supportState.currentPage,
    sort_by: supportState.sortBy,
    sort_order: supportState.sortOrder,
    search: supportState.search,
  });

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = supportList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [supportList]);

  let status = "";

  const handleStatusChange = (value, id) => {
    let data = {
      support_id: id,
      status: value?.id,
    };
    statusChange(data).then((response) => {
      if (response?.data?.code === 200) {
        toast.success("Changed");
        refetch();
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  const handleChatClick = (data) => {
    dispatch(
      updateConfig((state) => {
        state.chatId = data?._id;
        state.chatModal = true;
        state.openedChatStatus = data?.status;
      })
    );
  };
  const containsHTML = (message) => {
    return /<\/?[a-z][\s\S]*>/i.test(message);
  };
  const handleClick=(data)=>{
    if(supportList?.data?.permission?.can_view_user){
      navigate({ pathname: `/user-details/${data?.user_id}` })
    }else{
      return
    }
  }
  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      message: (feild, data) => {
        let hasBubble =
          data["message_status"] === "unanswered" &&
          data["status"] === "Unsolved";
        return (
          <span
            className="pro-mb-0 pro-pnt"
            onClick={() =>
              // data["status"] === "Unsolved" && handleChatClick(data)
              handleChatClick(data)
            }
          >
            {containsHTML(data[feild]) ? (
              parse(data[feild])
            ) : (
              <span>{data[feild]}</span>
            )}
            {hasBubble && (
              <span className="material-symbols-outlined">
                mark_chat_unread
              </span>
            )}
          </span>
        );
      },
      status: (field, data) => {
        let statusArray = basicData?.support_status?.filter(
          (value) => value?.name === data[field]
        );
        status = statusArray[0];
        return (
          <Select
            id="status"
            options={basicData?.support_status}
            getOptionLabel={(option) => `${option.name}`}
            getOptionValue={(option) => `${option.id}`}
            value={status}
            classNamePrefix="pro-input"
            className="pro-input lg"
            menuPlacement="auto"
            menuPosition="fixed"
            onChange={(value) => handleStatusChange(value, data?._id)}
            isDisabled={data[field] === "Solved"}
          />
        );
      },
      influencer: (feild, data) => (
        <div className="pro-pnt text-truncate">
            <IconText
              icon={
                <img
                  src={data["influencer_thumbnail_image"]?? Assets.PROFILE_USER}
                  alt={"profile-pic"}
                  width={20}
                  height={20}
                  className="object-fit-cover"
                />
              }
              title={data[feild]}
              onClick={()=>handleClick(data)}
              propStyle={{
                rootIcon:
                  "pro-d-flex pro-items-center pro-rounded-circle pro-overflow-hidden",
              }}
            />
        </div>
      ),
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (supportState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = supportState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleCloseClick = () => {
    dispatch(
      updateConfig((state) => {
        state.chatModal = false;
      })
    );
    const clearChatTimeout = setTimeout(() => {
      dispatch(updateConfig((state) => (state.chats = {})));
      clearTimeout(clearChatTimeout);
    }, 500);
  };

  return {
    isLoading,
    isFetching,
    tableFields,
    supportList,
    supportState,
    paginationOptions,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    handlePageSize,
    handleClearClick,
    handleCloseClick,
    handlePagination,
  };
};

export default useSupport;
