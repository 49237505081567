import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../Store/Slices/Interests/interestsSlice";
import { createInterest, updateInterest } from "../api";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { getBasicData } from "../../../../Store/Slices/Global";

const useAddInterest = ({ refetch }) => {
  const dispatch = useDispatch();
  const { isEdit, itemData, itemId } = useSelector((state) => state.interests);

  const setBasicData = async () => {
    localStorage?.setItem("basicData", "");
    if (localStorage?.getItem("USER_ACCESS_TOKEN_INVESTO")) {
      dispatch(
        getBasicData({
          token: localStorage.getItem("USER_ACCESS_TOKEN_INVESTO"),
        })
      );
    }
  };
  const formik = useFormik({
    initialValues: {
      interest: "",
      ar_interest: "",
    },
    validate: (values) => {
      let errors = {};
      if (!values.interest) {
        errors.interest = "*Interest Is Required";
      }
      return errors;
    },
    onSubmit: (values) => {
      if (isEdit) {
        let data = {
          interest_id: itemId,
          interest: values?.interest,
          ar_interest: values?.ar_interest,
          status: 1,
        };
        updateInterest(data).then((response) => {
          if (response?.data?.code === 200) {
            toast.success("Added");
            setBasicData();
            refetch();
            handleCloseModal();
          } else if (response?.code === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
          } else {
            toast.error("Something went wrong");
          }
        });
      } else {
        let data = {
          interest: values?.interest,
          ar_interest: values?.ar_interest,
        };
        createInterest(data).then((response) => {
          if (response?.data?.code === 200) {
            toast.success("Added");
            setBasicData();
            refetch();
            handleCloseModal();
          } else if (response?.code === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
          } else {
            toast.error("Something went wrong");
          }
        });
      }
    },
  });

  useEffect(() => {
    if (isEdit && itemData) {
      formik?.setFieldValue("interest", itemData?.name);
      formik?.setFieldValue("ar_interest", itemData?.ar_name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, itemData]);

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.createModal = false;
        state.itemData = "";
      })
    );
  };
  return {
    formik,
    isEdit,
    getFieldError,
    handleCloseModal,
  };
};

export default useAddInterest;
