import { getAxiosInstance } from "../../API";

export const login = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("/admin/login", params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const forgotPassword = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("/admin/forget-password", params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const validateOTP = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("/admin/verify-otp", params);

    return response;
  } catch (error) {
    return error.response.data;
  }
};
export const resetPassword = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("/admin/reset-password", params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
