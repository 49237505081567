import React from "react";
import Style from "./platform.module.scss";
import Select from "react-select";
import usePlatform from "./usePlatform";

const Platform = ({ formik, itmIndex, basicData, tempIds,isCampaignStatus}) => {
  const { handleRemoveField } = usePlatform({ formik });
  let originalSocialMedia = [...basicData?.social_media];
  const platformOptions =
    formik.values.platform.length < 2
      ? originalSocialMedia.filter(
          (media) =>
            !formik.values.platform.some(
              (selectedPlatform) => selectedPlatform?.p_name?.id === media.id
            )
        )
      : originalSocialMedia.filter(
          (media) =>
            !formik.values.platform.some(
              (selectedPlatform) => selectedPlatform?.p_name?.id === media.id
            ) && media.name !== "google"
        );

  // media type formik value
  let mediaType = formik?.values?.platform?.[itmIndex]?.m_type;

  let isGoogle = formik?.values?.platform?.[0]?.p_name?.name === "google";

  // filtering media types based on condition
  const mediaTypes =
    mediaType?.length === 0
      ? basicData?.platform_media_types?.filter(
          (val) =>
            val?.stream_id === formik?.values?.platform?.[itmIndex]?.p_name?.id
        ) ?? []
      : mediaType?.some((obj) => obj?.id === "others")
      ? []
      : mediaType?.length > 0
      ? basicData?.platform_media_types?.filter(
          (val) =>
            val?.stream_id ===
              formik?.values?.platform?.[itmIndex]?.p_name?.id &&
            val?.id !== "others"
        ) ?? []
      : basicData?.platform_media_types?.filter(
          (val) =>
            val?.stream_id === formik?.values?.platform?.[itmIndex]?.p_name?.id
        ) ?? [];

  const categories = [
    { _id: 7, name: "All" },
    ...basicData?.influencer_category,
  ];

  const handleCatChange = (value) => {
    if (value?.some((obj) => obj?._id === 7))
      formik?.setFieldValue(
        `platform.${itmIndex}.p_type`,
        basicData?.influencer_category?.map((v) => v) || []
      );
    else {
      formik?.setFieldValue(
        `platform.${itmIndex}.p_type`,
        value?.map((v) => v) || []
      );
    }
  };

  return (
    <div
      className={`${Style.container} pro-d-flex pro-items-start pro-gap-3`}
      key={itmIndex}
    >
      <div className="flex-fill">
        <div className={`row gx-3`}>
          <div className="col-6">
            <div className="input-wrap">
              <Select
                id={`p_name${itmIndex}`}
                name={`p_name${itmIndex}`}
                isClearable={true}
                placeholder={"Name"}
                className={`pro-input lg ${
                  formik.touched?.platform?.[itmIndex]?.p_name &&
                  formik.errors?.platform?.[itmIndex]?.p_name &&
                  "error"
                }`}
                classNamePrefix="pro-input"
                options={platformOptions}
                getOptionLabel={(options) =>
                  options?.name?.charAt(0).toUpperCase() +
                  options?.name?.slice(1).toLowerCase()
                }
                getOptionValue={(options) => options?.id}
                onChange={(value) => {
                  formik?.setFieldValue(
                    `platform.${itmIndex}.p_name`,
                    value || ""
                  );
                  formik?.setFieldValue(`platform.${itmIndex}.m_type`, []);
                  formik?.setFieldValue(`platform.${itmIndex}.p_type`, []);
                }}
                value={formik?.values?.platform?.[itmIndex]?.p_name}
                onBlur={formik?.handleBlur(`platform.${itmIndex}.p_name`)}
                menuPlacement="auto"
                isDisabled={isCampaignStatus === "live" || isCampaignStatus === "stopped"||isCampaignStatus === "completed"}
              />
              {formik.touched?.platform?.[itmIndex]?.p_name &&
                formik.errors?.platform?.[itmIndex]?.p_name && (
                  <span className="error-text">
                    {formik.errors?.platform?.[itmIndex]?.p_name}
                  </span>
                )}
            </div>
          </div>
          {!isGoogle && (
            <div className="col-6">
              <div className="input-wrap">
                <Select
                  id={`p_type${itmIndex}`}
                  name={`p_type${itmIndex}`}
                  isClearable={true}
                  placeholder={"Type"}
                  className={`pro-input lg ${
                    formik.touched?.platform?.[itmIndex]?.p_type &&
                    formik.errors?.platform?.[itmIndex]?.p_type &&
                    "error"
                  }`}
                  classNamePrefix="pro-input"
                  options={categories ?? []}
                  getOptionLabel={(options) => options?.name}
                  getOptionValue={(options) => options?.id}
                  onChange={(value) => handleCatChange(value)}
                  value={formik?.values?.platform?.[itmIndex]?.p_type?.map(
                    (v) => v
                  )}
                  onBlur={formik?.handleBlur(`platform.${itmIndex}.p_type`)}
                  menuPlacement="auto"
                  isMulti
                  isDisabled={isCampaignStatus === "live" || isCampaignStatus === "stopped"||isCampaignStatus === "completed"}
                />
                {formik.touched?.platform?.[itmIndex]?.p_type &&
                  formik.errors?.platform?.[itmIndex]?.p_type && (
                    <span className="error-text">
                      {formik.errors?.platform?.[itmIndex]?.p_type}
                    </span>
                  )}
              </div>
            </div>
          )}
          <div className={`col-6 ${isGoogle ? "" : "pro-mt-2"}`}>
            <div className="input-wrap">
              <Select
                id={`m_type${itmIndex}`}
                name={`m_type${itmIndex}`}
                isClearable={true}
                placeholder={"Media Type"}
                className={`pro-input lg ${
                  formik.touched?.platform?.[itmIndex]?.m_type &&
                  formik.errors?.platform?.[itmIndex]?.m_type &&
                  "error"
                }`}
                classNamePrefix="pro-input"
                options={mediaTypes}
                getOptionLabel={(options) => options?.name}
                getOptionValue={(options) => options?.id}
                onChange={(value) =>
                  formik?.setFieldValue(
                    `platform.${itmIndex}.m_type`,
                    value?.map((v) => v) || []
                  )
                }
                value={mediaType || []?.map((v) => v ?? "")}
                onBlur={formik?.handleBlur(`platform.${itmIndex}.m_type`)}
                menuPlacement="auto"
                isMulti
                isDisabled={isCampaignStatus === "live" || isCampaignStatus === "stopped"||isCampaignStatus === "completed"}
              />
              {formik.touched?.platform?.[itmIndex]?.m_type &&
                formik.errors?.platform?.[itmIndex]?.m_type && (
                  <span className="error-text">
                    {formik.errors?.platform?.[itmIndex]?.m_type}
                  </span>
                )}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${Style.delete_btn} pro-d-flex pro-justify-center pro-items-center`}
      >
        {(formik?.values?.platform?.length > 1 ||(isCampaignStatus !== "live" && isCampaignStatus !== "stopped"&&isCampaignStatus !== "completed")) && (
          <span
            className="material-symbols-outlined x4"
            onClick={() => handleRemoveField(itmIndex)}
          >
            delete
          </span>
        )}
      </div>
    </div>
  );
};

export default Platform;
