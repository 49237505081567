import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useGetRolesListDataQuery,
  useUpdateRolesTableHeadDataMutation,
} from "../../../Store/Queries/Roles";
import { updateConfig } from "../../../Store/Slices/Roles/rolesSlice";
import { updateConfig as globalUpdateConfig } from "../../../Store/Slices/Global";

const useRoles = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const roleState = useSelector((state) => state.roles);
  const { tableFields, showEditModal, showDeleteModal, selectedId } =
    useSelector((state) => state.global);

  const {
    data: roleList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetRolesListDataQuery({
    page_size: roleState?.currentPageSize,
    page: roleState?.currentPage,
    sort: roleState?.sortBy,
    sort_order: roleState?.sortOrder,
    start: roleState?.startDate,
    end: roleState?.endDate,
    search: roleState?.search,
  });
  const [updateRoleFields] = useUpdateRolesTableHeadDataMutation();

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };
  const handleSort = (label) => {
    if (roleState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = roleState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.addModal = true;
        state.isEdit = false;
      })
    );
  };

  const handleEditAction = (e) => {
    navigate({
      pathname: "/configure/roles/role-permissions",
      search: `role=${e?.[0]}&update=true`,
    });
    dispatch(
      updateConfig((state) => {
        state.isEdit = true;
      })
    );
  };

  const handlecloseModal = () => {
    dispatch(updateConfig((state) => (state.addModal = false)));
  };

  return {
    roleList,
    roleState,
    isLoading,
    selectedId,
    isFetching,
    tableFields,
    showEditModal,
    showDeleteModal,
    paginationOptions,
    handleEditColumnsClick,
    handleCreateClick,
    updateRoleFields,
    handlePagination,
    handleClearClick,
    handleEditAction,
    handlecloseModal,
    handlePageSize,
    handleSearch,
    handleSort,
    refetch,
    getRow,
  };
};

export default useRoles;
