import { updateConfig as globalUpdateConfig } from "../../../Store/Slices/Global";
import { updateConfig } from "../../../Store/Slices/WalletTransaction/walletTransactionSlice";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  useGetWalletTransactionDataQuery,
  useUpdateWalletTransactionTableHeadDataMutation,
} from "../../../Store/Queries/Users";
import Assets from "../../../Assets";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
import { getFormatedDate } from "../../../utils/Table";

const useWalletTransaction = () => {
  const dispatch = useDispatch();
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const walletTransactionState = useSelector(
    (state) => state.walletTransaction
  );
  const navigate = useNavigate();
  const [updateFields] = useUpdateWalletTransactionTableHeadDataMutation();
  const [initialDate, setInitialDate] = useState({
    // startDate: subDays(new Date(), 30),
    // endDate: subDays(new Date(), -30),
    startDate: walletTransactionState.startDate
      ? walletTransactionState.startDate
      : null,
    endDate: walletTransactionState.endDate
      ? walletTransactionState.endDate
      : null,
    key: "selection",
  });
  const {
    data: walletTransactionList = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetWalletTransactionDataQuery({
    items_per_page: walletTransactionState.currentPageSize,
    current_page: walletTransactionState.currentPage,
    sort_by: walletTransactionState.sortBy,
    sort_order: walletTransactionState.sortOrder,
    search: walletTransactionState.search,
    start_date: walletTransactionState.startDate
      ? getFormatedDate(walletTransactionState.startDate)
      : "",
    end_date: walletTransactionState.endDate
      ? getFormatedDate(walletTransactionState.startDate)
      : "",
    status: walletTransactionState.status?.id,
  });

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = walletTransactionList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [walletTransactionList]);

  const handleClick = (data) => {
    if (walletTransactionList?.data?.permissions?.can_view_user) {
      navigate({
        pathname: `/user-details/${data?.user_id}active=""&type="wallet-transactions"`,
      });
    } else {
      return;
    }
  };
  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      campaign: (feild, data) => (
        <p
          className={`pro-mb-0 ${
            walletTransactionList?.data?.permissions?.can_view_campaign
              ? `pro-pnt text-truncate `
              : ""
          }`}
          onClick={() => {
            if (walletTransactionList?.data?.permissions?.can_view_campaign) {
              navigate({
                pathname: `/campaign-details/${data?.campaign_id}activeTab="all"&type="campaign"&active="all"`,
              });
            }
          }}
        >
          {data?.[feild]}
        </p>
      ),
      user: (feild, data) => (
        <div className="pro-pnt text-truncate">
          <IconText
            icon={
              <img
                src={data["influencer_thumbnail_image"] ?? Assets.PROFILE_USER}
                alt={"profile-pic"}
                width={20}
                height={20}
                className="object-fit-cover"
              />
            }
            title={data[feild]}
            onClick={() => handleClick(data)}
            propStyle={{
              rootIcon:
                "pro-d-flex pro-items-center pro-rounded-circle pro-overflow-hidden",
            }}
          />
        </div>
      ),
      task_links: (feild, data) => (
        data?.[feild]?.length>0 && <p
          className={`pro-mb-0 pro-pnt text-truncate`}
          onClick={() => {
            dispatch(
              updateConfig((state) => {
                state.viewLinkModal = true;
                state.linkData = data?.[feild];
              })
            );
          }}
        >
          View Links
        </p>
      ),
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (walletTransactionState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            walletTransactionState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.search = "";
        state.startDate = "";
        state.endDate = "";
        state.currentPageSize = 10;
        state.currentPage = 1;
        state.status=""
      })
    );
    setInitialDate({
      startDate: null,
      endDate: null,
      key: "selection",
    });
  };
  const handleLinkClose = () => {
    dispatch(
      updateConfig((state) => {
        state.viewLinkModal = false;
        state.linkData = [];
      })
    );
  };
  const handleDateChange = (date) => {
    dispatch(
      updateConfig((state) => {
        state.startDate = new Date(date.startDate);
        state.endDate = new Date(date.endDate);
      })
    );
  };
  const handleFilterClick = () => {
    dispatch(
      updateConfig((state) => {
        state.isFilter = false;
        state.createFilterModal = true;
      })
    );
  };
  const handleCloseFilter = () => {
    dispatch(updateConfig((state) => (state.createFilterModal = false)));
  };
  return {
    isLoading,
    isFetching,
    tableFields,
    showEditModal,
    paginationOptions,
    walletTransactionList,
    walletTransactionState,
    initialDate,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    updateFields,
    handlePageSize,
    handleClearClick,
    handlePagination,
    handleLinkClose,
    handleEditColumnsClick,
    handleDateChange,
    handleFilterClick,
    handleCloseFilter,
  };
};

export default useWalletTransaction;
