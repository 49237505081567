import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "",
  search: "",
  addModal: false,
  selectedItem: "",
  isEdit: false,
  itemData: "",
  imagePreview: "",
};

const adminactivitySlice = createSlice({
  name: "adminactivity",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = adminactivitySlice.actions;
export default adminactivitySlice.reducer;
