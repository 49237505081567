import { useNavigate, useParams } from "react-router-dom";
import {
  useGetAllBrandsDataQuery,
  useGetCampaignDetailsDataQuery,
} from "../../../Store/Queries/Campaign";
import { useSelector } from "react-redux";
import { useState } from "react";

const useCampaignDetailsLayout = () => {
  const { campaignID } = useParams();
  const [showAllReason, setShowAllReason] = useState(false);
  const [showAllDesc, setShowAllDesc] = useState(false);
  const navigate = useNavigate();
  const activeCampaign =
    campaignID.split(/activeTab="[^"]*"(?:&type="[^"]*")?/)[0] ||
    sessionStorage.getItem("active-campaign");
  const { basicData } = useSelector((state) => state.global);
  const { data: brands } = useGetAllBrandsDataQuery();
  const { data: campaignData, isFetching } = useGetCampaignDetailsDataQuery({
    campaign_id: activeCampaign,
  });

  const label = {
    campaign_unique_id: "Campaign ID",
    start_date: "Start Date",
    end_date: "End Date",
    region: "Region",
    campaign_type: "Campaign Type",
    location_name: "Campaign Location",
    distance: "Distance",
    engagement_type: "Engagement Type",
    payment_type: "Payment Type",
  };
  const regions = campaignData?.data?.region?.map((value) => value);
  const campiagnType = basicData?.campaign_type?.filter(
    (value) => value?.id === campaignData?.data?.campaign_type
  );
  const paymentType = basicData?.payment_type?.filter(
    (value) => value?.id === campaignData?.data?.payment_type
  );
  // const streams = Object?.keys(campaignData?.data?.social_media ?? {}).map(
  //   (value) => {
  //     return value.charAt(0).toUpperCase() + value.slice(1);
  //   }
  // );

  const brand = brands?.data?.filter(
    (value) => value?._id === campaignData?.data?.brand_id
  );

  const startInputDateString = campaignData?.data?.start_date;
  const startInputDate = new Date(startInputDateString);

  const startday = startInputDate.getDate();
  const startmonth = startInputDate.toLocaleString("en-US", { month: "long" });
  const startyear = startInputDate.getFullYear();

  const formattedStartDate = `${startday} ${startmonth} ${startyear}`;

  const endInputDateString = campaignData?.data?.end_date;
  const endInputDate = new Date(endInputDateString);

  const day = endInputDate.getDate();
  const month = endInputDate.toLocaleString("en-US", { month: "long" });
  const year = endInputDate.getFullYear();

  const formattedEndDate = `${day} ${month} ${year}`;

  const value = {
    campaign_unique_id: `${
      campaignData?.data?.campaign_unique_id ?? "Not specified"
    }`,
    start_date: `${formattedStartDate ?? "Not specified"}`,
    end_date: `${formattedEndDate ?? "Not specified"}`,
    region: `${regions ?? "Not specified"}`,
    campaign_type: `${campiagnType?.[0]?.name ?? "Not specified"}`,
    location_name: `${campaignData?.data?.location_name ?? "Not specified"}`,
    distance: `${campaignData?.data?.distance ?? "Not specified"}`,
    engagement_type: `${
      campaignData?.data?.engagement_type ?? "Not specified"
    }`,
    payment_type: `${paymentType?.[0]?.name ?? "Not specified"}`,
  };

  const basicDetails = Object.keys(value).map((key) => {
    return {
      label: label[key],
      value:
        typeof value[key] === "string"
          ? value?.[key]
          : typeof value[key] === "number"
          ? value?.[key]
          : value?.[key]?.name,
    };
  });
  const handleBackClick = () => {
    let typeValue = "";
    let active = "";
    const url = window.location.href;
    const typeParam = "type=";
    const typeIndex = url.indexOf(typeParam);

    if (typeIndex !== -1) {
      const startIndex = typeIndex + typeParam.length;
      const endIndex =
        url.indexOf("&", startIndex) !== -1
          ? url.indexOf("&", startIndex)
          : url.length;
      typeValue = url.substring(startIndex, endIndex);
      typeValue = decodeURIComponent(typeValue).replace(/"/g, "");
    }
    const activeParam = "active=";
    const activeIndex = url.indexOf(activeParam);
    if (activeIndex !== -1) {
      const startIndex = activeIndex + activeParam.length;
      const endIndex =
        url.indexOf("&", startIndex) !== -1
          ? url.indexOf("&", startIndex)
          : url.length;
      active = url.substring(startIndex, endIndex);
      active = decodeURIComponent(active).replace(/"/g, "");
    }
    navigate(
      `/${
        typeValue === "approval"
          ? `campaign/approval?activeTab=${active ? active : "requests"}`
          : typeValue === "content-verification"
          ? `campaign/content-verification?activeTab=${active ? active : "all"}`
          : typeValue === "report"
          ? `campaign/report`
          : `campaign?activeTab=${active ? active : "all"}`
      }`
    );
  };

  const handleReadMore = () => {
    setShowAllReason(true);
  };
  const handleReadMoreDesc = () => {
    setShowAllDesc(true);
  };

  return {
    isFetching,
    brand,
    basicDetails,
    campaignData,
    showAllReason,
    showAllDesc,
    handleReadMore,
    handleReadMoreDesc,
    handleBackClick,
  };
};

export default useCampaignDetailsLayout;
