import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../Store/Slices/Language/languageSlice";
import { createLanguage, updateLanguage } from "../api";
import { toast } from "react-toastify";
import { getBasicData } from "../../../../Store/Slices/Global";

const useAddLanguage = ({ refetch }) => {
  const dispatch = useDispatch();
  const { isEdit, itemData, itemId } = useSelector((state) => state.language);

  const setBasicData = async () => {
    localStorage?.setItem("basicData", "");
    if (localStorage?.getItem("USER_ACCESS_TOKEN_INVESTO")) {
      dispatch(
        getBasicData({
          token: localStorage.getItem("USER_ACCESS_TOKEN_INVESTO"),
        })
      );
    }
  };
  const formik = useFormik({
    initialValues: {
      name: itemData?.name ?? "",
      ar_name: itemData?.ar_name ?? "",
    },
    enableReinitialize: true,
    validate: (values) => {
      let errors = {};
      if (!values.name) {
        errors.name = "*Language Is Required";
      }
      if (!values.ar_name) {
        errors.ar_name = "(أدخل الرسالة)*Language Is Required";
      }
      return errors;
    },
    onSubmit: (values) => {
      if (isEdit) {
        let data = {
          id: itemId,
          name: values?.name,
          ar_name: values?.ar_name,
        };
        updateLanguage(data).then((response) => {
          if (response?.data?.code === 200) {
            toast.success("Added");
            setBasicData();
            refetch();
            handleCloseModal();
          } else if (response?.code === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
          } else {
            toast.error("Something went wrong");
          }
        });
      } else {
        let data = {
          name: values?.name,
          ar_name: values?.ar_name,
        };
        createLanguage(data).then((response) => {
          if (response?.data?.code === 200) {
            toast.success("Added");
            setBasicData();
            refetch();
            handleCloseModal();
          } else if (response?.code === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
          } else {
            toast.error("Something went wrong");
          }
        });
      }
    },
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.createModal = false;
        state.itemData = "";
      })
    );
  };
  return {
    formik,
    isEdit,
    getFieldError,
    handleCloseModal,
  };
};

export default useAddLanguage;
