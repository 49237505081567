import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useCampStop from "./useCampStop";

const CampStop = ({ refetch }) => {
  const { formik, getFieldError, handleCloseModal } = useCampStop({ refetch });
  return (
    <div>
      <div className="col-12 pro-mb-4">
        <div className="input-wrap">
          <label
            htmlFor="reason"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Reason
          </label>
          <textarea
            id="reason"
            rows={3}
            name="reason"
            className={`pro-input lg ${getFieldError("reason") && "error"} `}
            value={formik?.values?.reason ?? ""}
            onChange={(e) => formik.setFieldValue("reason", e.target.value)}
            onBlur={formik?.handleBlur("reason")}
          ></textarea>
          {getFieldError("reason") && (
            <span className="error-text">{getFieldError("reason")}</span>
          )}
        </div>
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end pro-py-4  pro-mt-auto ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          onClick={formik?.handleSubmit}
          type="submit"
        >
          &nbsp;
          {" Stop "}
          &nbsp;
        </Button>
      </div>
    </div>
  );
};

export default CampStop;
