import {
  HeadingGroup,
  OffCanvasLayout,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import EmptyData from "../../Global/EmptyData";
import useSupport from "./useSupport";
import { FaSort } from "react-icons/fa";
import Chat from "./Chat";
import { Pagination } from "../../Global/Pagination";

const Support = () => {
  const {
    isLoading,
    isFetching,
    supportList,
    tableFields,
    supportState,
    paginationOptions,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    handlePageSize,
    handleClearClick,
    handleCloseClick,
    handlePagination,
  } = useSupport();
  return (
    <div>
      <HeadingGroup title={"Support"} className={`pro-mb-4`} />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          showActions={false}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters
          handleClear={handleClearClick}
          searchInputProps={{ value: supportState?.search }}
        />
        {supportList?.data?.data?.length > 0 ? (
          <>
            <Table
              extraClasssName={`table-wrap`}
              multiSelect={false}
              data={supportList?.data?.data || []}
              uniqueID={"_id"}
              fields={tableFields}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              handleSort={handleSort}
              clear={supportState.clearSelection}
              getRow={getRow}
              loading={isFetching}
              perpage={supportState?.currentPageSize}
              editable={false}
              deletable={false}
              assignable={false}
              showCheckBox={false}
            />
            {supportList?.data?.data?.length > 0 && (
              <Pagination
                currentPage={supportState?.currentPage}
                totalPageCount={Math.ceil(
                  supportList?.data?.total_count / supportState.currentPageSize
                )}
                options={paginationOptions}
                onPageChange={handlePagination}
                onActionChange={handlePageSize}
                center
              />
            )}
          </>
        ) : (
          <EmptyData />
        )}
        <OffCanvasLayout
          show={supportState?.chatModal}
          handleClose={handleCloseClick}
          title={"Support"}
          closeicon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
        >
          <Chat
            refetch={refetch}
            openedChatStatus={supportState?.openedChatStatus}
          />
        </OffCanvasLayout>
      </div>
    </div>
  );
};

export default Support;
