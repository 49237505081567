import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../Store/Slices/Campaign/Creation/CreationSlice";

const useTasks = ({ formik }) => {
  // let previousLength = 0;
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.createModal = false)));
  };

  const handleNext = () => {
    dispatch(updateConfig((state) => (state.formActiveTab = "Dos&Don'ts")));
  };

  // old task change
  // const handleTaskChange = (event) => {
  //   const bullet = "\u2022";
  //   const newLength = event.target.value.length;
  //   const characterCode = event.target.value.substr(-1).charCodeAt(0);

  //   if (newLength > previousLength) {
  //     if (characterCode === 10) {
  //       event.target.value = `${event.target.value}${bullet} `;
  //     } else if (newLength === 1) {
  //       event.target.value = `${bullet} ${event.target.value}`;
  //     }
  //   }
  //   previousLength = newLength;
  //   formik.setFieldValue("tasks", event.target.value ?? "");
  // };

  //testing

  // const bullet = "\u2022";
  // const bulletWithSpace = `${bullet} `;
  // const enter = 13;

  // const handleTaskChange = (event) => {
  //   const { keyCode, target } = event;
  //   const { selectionStart, value } = target;

  //   if (keyCode === enter) {
  //     target.value = [...value]
  //       .map((c, i) => (i === selectionStart - 1 ? `\n${bulletWithSpace}` : c))
  //       .join("");
  //     formik.setFieldValue("tasks", target.value ?? "");

  //     target.selectionStart = selectionStart + bulletWithSpace.length;
  //     target.selectionEnd = selectionStart + bulletWithSpace.length;
  //   }

  //   if (value[0] !== bullet) {
  //     target.value = `${bulletWithSpace}${value}`;
  //   }
  // };

  const handleTaskChange = (value) => {
    formik.setFieldValue("tasks", value);
  };

  const handleArTaskChange = (value) => {
    formik.setFieldValue("ar_tasks", value);
  };

  return {
    handleCloseModal,
    handleNext,
    handleTaskChange,
    handleArTaskChange,
  };
};

export default useTasks;
