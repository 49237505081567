/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import Overview from "./Overview";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import Earnings from "./Earnings";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../Store/Slices/Campaign/Creation/CreationSlice";
import Tasks from "./Tasks";
import Uploads from "./Uploads";
import Dos from "./Dos";
import { campaignCreate, campaignUpdate } from "./api";
import { toast } from "react-toastify";
import { useGetAllBrandsDataQuery } from "../../../../Store/Queries/Campaign";
import { getFormatedDate } from "../../../../utils/Table";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import DOMPurify from "dompurify";
import htmlToDraft from "html-to-draftjs";
// import InfluencerEarnings from "./Earnings/InfluencerEarnings";

const useCreateForm = ({ refetch }) => {
  const {
    formActiveTab,
    campainId,
    isEdit,
    isDuplicate,
    selectedData,
    maps,
    selectedLocation,
    values: UploadedValues,
    isLiveEdit,
    isCampaignStatus,
  } = useSelector((state) => state.campaignCreate);
  const { basicData, interestData } = useSelector((state) => state.global);
  const { data: brands } = useGetAllBrandsDataQuery();
  const dispatch = useDispatch();
  const [moveToTab, setMoveToTab] = useState(false);
  const [selectedOption, setSelectedOption] = useState("flat");
  const [status, setStatus] = useState(true);
  const [content, setContent] = useState(false);
  const [influencerValues, setInfluencerValues] = useState(0);
  const [initialData, setInitialData] = useState({
    campaign_name: "",
    ar_campaign_name: "",
    platform: [
      {
        p_name: "",
        p_type: [],
        m_type: [],
      },
    ],
    region: [],
    gender: "",
    campaign_type: "",
    from_age: "",
    to_age: "",
    // post_type: "",
    brand: "",
    // languages: [],
    interests: [],
    // description: "",
    // ar_description: "",
    start_date: "",
    end_date: "",
    allowed_date: "",
    location: "",
    ar_location: "",
    address: "",
    ar_address: "",
    distance: "",
    status: "",
    content: "",
    earnings_type: "flat",
    budget: "",
    engagment_type: "",
    earnings_description: "",
    ar_earnings_description: "",
    // tasks: "",
    // ar_tasks: "",
    caption_ideas: "",
    ar_caption_ideas: "",
    hash_tags: "",
    ar_hash_tags: "",
    brand_mentions: "",
    ar_brand_mentions: "",
    dos: [
      {
        do: "",
        ar_do: "",
      },
    ],
    dons: [
      {
        don: "",
        ar_don: "",
      },
    ],
    influencer_earnings: [
      {
        amount: "",
      },
    ],
  });

  const validation = Yup.object({
    // region: Yup.array()
    //   .min(1, "Select Atleast One Region")
    //   .required("Select Atleast One Region"),
    // languages: Yup.array()
    //   .min(1, "Select Atleast One Language")
    //   .required("Select Atleast One Language"),
    interests: Yup.array()
      .min(1, "Select Atleast One Interest")
      .required("Select Atleast One Interest"),
    campaign_type: Yup.object().required("Select Campaign Type"),
    gender: Yup.object().required("Select Gender"),
    from_age: Yup.number()
      .min(18, "Minimum Age should be 18")
      .max(69, "Maximum Age should be 69")
      .typeError("Enter Valid Age")
      .integer("Enter Valid Age"),
    to_age: Yup.number()
      .min(19, "Minimum Age should be 19")
      .max(70, "Maximum Age should be 70")
      .typeError("Enter Valid Age")
      .integer("Enter Valid Age"),
    // post_type: Yup.object().required("Select Post Type"),
    brand: Yup.object().required("Select Brand"),
    engagment_type: Yup.object().when("earnings_type", {
      is: "engagment_rate",
      then: () => Yup.object().required("Select Type"),
    }),
    campaign_name: Yup.string().required("Enter Campaign Name").trim(),
    ar_campaign_name: Yup.string()
      .required("Enter Campaign Name(اسم الحملة)")
      .trim(),
    start_date: Yup.string().required("Select Start Date"),
    end_date: Yup.string().required("Select End Date"),
    allowed_date: Yup.mixed()
      .required("Enter Allowed date")
      .test(
        "valid-number",
        "Allowed date must be a valid number",
        function (value) {
          return /^\d+$/.test(value);
        }
      )
      .test(
        "max",
        "Allowed date must be less than or equal to the number of days between start and end dates",
        function (value) {
          const { start_date, end_date } = this.parent;
          const startDate = new Date(start_date);
          const endDate = new Date(end_date);
          const timeDifference = endDate - startDate;
          const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
          const maxAllowedValue = Math.round(daysDifference);
          return /^\d+$/.test(value) && value <= parseInt(maxAllowedValue) + 1;
        }
      ),

    // location: Yup.string()
    //   .required("Enter Location")
    //   .min(3, "Enter Valid Location"),
    address: Yup.string()
      .required("Enter Address")
      .min(5, "Enter Valid Address"),
    ar_address: Yup.string()
      .required("Enter Address(العنوان)")
      .min(5, "Enter Valid Address (العنوان)"),
    budget:
    (isCampaignStatus==="live" || isCampaignStatus==="") &&
      Yup.string()
        .required("Enter Budget")
        .matches(/^[1-9]\d*(\.\d+)?$/, "Enter Valid Number"),
    dos: Yup.array().of(
      Yup.object().shape({
        do: Yup.string().required("Enter Do").min(3, "Enter Valid Do "),
        ar_do: Yup.string()
          .required("Enter Do (التابع)")
          .min(3, "Enter Valid Do (التابع)"),
      })
    ),
    dons: Yup.array().of(
      Yup.object().shape({
        don: Yup.string().required("Enter Don't").min(3, "Enter Valid Don't"),
        ar_don: Yup.string()
          .required("Enter Don't (لا تفعل ذلك )")
          .min(3, "Enter Valid Don't (لا تفعل ذلك )"),
      })
    ),
    influencer_earnings: Yup.array().of(
      Yup.object().shape({
        amount: Yup.string()
          .required("Enter Amount")
          .matches(/^(0?\.\d+|[1-9]\d*(\.\d+)?)$/, "Enter Valid Amount"),
      })
    ),
    // description: Yup.string().required("Enter Description"),
    // // ar_description: Yup.string().required("Enter Description(الوصف)"),
    // tasks: Yup.string().required("Enter tasks"),
    // ar_tasks: Yup.string().required("Enter Tasks(المهام)"),
    caption_ideas: Yup.string(),
    ar_caption_ideas: Yup.string(),
    hash_tags: Yup.string().nullable(),
    ar_hash_tags: Yup.string(),
    brand_mentions: Yup.string(),
    ar_brand_mentions: Yup.string(),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    validationSchema: validation,
    validate: (values) => {
      let errors = {};
      const mentionedValue = selectedData?.budget?.total;
      const sum = values?.influencer_earnings?.reduce(
        (total, current) => total + Number(current?.amount),
        0
      );
      if (+sum > +values?.budget) {
        errors.budget = "Your total budget exceeds";
      }
      if (isCampaignStatus ==="live" && mentionedValue !== undefined && mentionedValue !== null&& +mentionedValue > +values?.budget) {
        errors.budget = "Budget must be greater than the current value";
      }
      const plainText = values?.description?.getCurrentContent()?.getPlainText();
      if (!plainText?.trim()) {
        errors.description = "*Enter Description";
      }

      const plainTextArabic = values?.ar_description
        ?.getCurrentContent()
        ?.getPlainText();
      if (!plainTextArabic?.trim()) {
        errors.ar_description = "*Enter Description(الوصف)";
      }
      
      const plainTextTask = values?.tasks
      ?.getCurrentContent()
      ?.getPlainText();
      if (!plainTextTask?.trim()) {
        errors.tasks = "*Enter tasks";
      }

      const plainTextTasksArabic = values?.ar_tasks
        ?.getCurrentContent()
        ?.getPlainText();
      if (!plainTextTasksArabic?.trim()) {
        errors.ar_tasks = "*Enter Tasks(المهام)";
      }
      // if (values?.tasks?.length === 0) {
      //   errors.tasks = "Enter tasks";
      // }
      // if (values?.ar_tasks?.length === 0) {
      //   errors.ar_tasks = "Enter Tasks(المهام)";
      // }

      if (values?.distance) {
        if (values?.distance !== undefined && values?.distance !== null) {
          // Check if distance is a number with decimals using regex
          const distanceRegex = /^\d+(\.\d+)?$/;

          if (!distanceRegex.test(values.distance)) {
            errors.distance = "Distance must be a number";
          }
        }
      }
      // Custom validation for 'platform'
      if (values?.platform && values?.platform?.length > 0) {
        // Iterate over each platform object
        values?.platform.forEach((platform, index) => {
          if (!platform.p_name) {
            errors.platform = errors.platform || [];
            errors.platform[index] = errors.platform[index] || {};
            errors.platform[index].p_name = "Select Platform";
          }
          if (
            platform?.p_name?.name !== "google" &&
            (!platform?.p_name?.name || platform?.p_type?.length < 1)
          ) {
            errors.platform = errors.platform || [];
            errors.platform[index] = errors.platform[index] || {};
            errors.platform[index].p_type = "Select Type";
          }
          if (!platform.m_type || platform.m_type.length < 1) {
            errors.platform = errors.platform || [];
            errors.platform[index] = errors.platform[index] || {};
            errors.platform[index].m_type = "Select Type";
          }

          if (
            formik?.values?.platform?.some(
              (item) => item?.p_name?.name?.toLowerCase() === "google"
            )
          ) {
            if (!selectedLocation?.lat) {
              errors.maps = errors.maps || [];
              errors.maps = errors.maps || {};
              errors.maps = "Location is required";
            }
          }
        });
      }
      return errors;
    },
    onSubmit: (values) => {
      const descriptionState = values.description.getCurrentContent();
      const descriptionRaw = convertToRaw(descriptionState);
      const descriptionText = draftToHtml(descriptionRaw);

      const descriptionStateArabic = values.ar_description.getCurrentContent();
      const descriptionRawArabic = convertToRaw(descriptionStateArabic);
      const descriptionTextArabic = draftToHtml(descriptionRawArabic);

      const taskState = values.tasks.getCurrentContent();
      const taskRaw = convertToRaw(taskState);
      const taskText = draftToHtml(taskRaw);

      const taskStateArabic = values.ar_tasks.getCurrentContent();
      const taskRawArabic = convertToRaw(taskStateArabic);
      const taskTextArabic = draftToHtml(taskRawArabic);
      let data = {
        title: values?.campaign_name,
        brand_id: values?.brand?._id,
        description: descriptionText,
        budget: values?.budget,
        campaign_type: values?.campaign_type?.id,
        payment_type: selectedOption === "flat" ? 0 : 1,
        engagement_type:
          selectedOption === "engagment_rate" ? values?.engagment_type?.id : "",
        payment_description: values?.earnings_description,
        // category: values?.post_type?.id,
        start_date: values?.start_date,
        end_date: values?.end_date,
        allowed_days: values?.allowed_date,
        deliverables: "",
        // language: values?.languages?.map((v) => v._id),
        interest: values?.interests?.map((v) => v._id),
        caption_ideas: values?.caption_ideas,
        hashtag: values?.hash_tags,
        brand_mention: values?.brand_mentions,
        // location: values?.location,
        age_from: values?.from_age,
        age_to: values?.to_age,
        address: values?.address,
        gender: values?.gender?.id,
        distance: values?.distance,
        location_name: values?.location,
        status: status === true ? 1 : 2,
        content_pre_check: content === true ? 1 : 2,
        "location[latitude]": selectedLocation?.lat,
        "location[longitude]": selectedLocation?.lng,
        //arabic inputs
        task: taskText,
        ar_task: taskTextArabic,
        ar_title: values?.ar_campaign_name,
        ar_description: descriptionTextArabic,
        ar_brand_mention: values?.ar_brand_mentions,
        ar_payment_description: values?.ar_earnings_description,
        ar_caption_ideas: values?.ar_caption_ideas,
        ar_hashtag: values?.ar_hash_tags,
        ar_address: values?.ar_address,
        ar_location_name: values?.ar_location,
      };

      const formData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== "") {
          if (Array.isArray(value)) {
            value.forEach((item, index) => {
              formData.append(`${key}[${index}]`, item);
            });
          } else {
            formData.append(key, value);
          }
        }
      });

      values?.dos?.forEach((item, index) => {
        formData.append(`dos[${index}]`, item?.do);
        formData.append(`ar_dos[${index}]`, item.ar_do);
      });
      values?.region?.forEach((item, index) => {
        formData.append(`region[${index}]`, item?.unique_state_id);
      });
      values?.dons?.map((value, index) => {
        formData?.append(`do_nots[${index}]`, value?.don);
        formData?.append(`ar_do_nots[${index}]`, value?.ar_don);
      });
      values?.platform?.map((value) => {
        // Assuming that p_type is an array
        value?.p_type?.map((typeValue, index) => {
          formData?.append(
            `social_media[${value?.p_name?.name}][influencer_category][${index}]`,
            typeValue?.name?.toLowerCase()
          );
        });
        value?.m_type?.map((typeValue, index) => {
          formData?.append(
            `social_media[${value?.p_name?.name}][media_type][${index}]`,
            typeValue?.name?.toLowerCase()
          );
        });
      });

      values?.influencer_earnings?.map((val, index) => {
        formData?.append(
          `social_media[${values?.platform?.[index]?.p_name?.name}][unit_rate]`,
          val?.amount
        );
      });

      // old task appending
      // let taksString = values?.tasks;
      // let resultList = taksString.split("\n");

      // resultList = resultList
      //   .filter((item) => item !== "")
      //   .map((item) => item.replace(/• /g, ""));

      // resultList.forEach((value, index) => {
      //   formData.append(`task[${index}]`, value.substring(0, 5));
      // });

      // let unformattedTasks = values?.tasks;
      // const elements = unformattedTasks
      //   .split("\n")
      //   .map((element) => element.trim()).filter(i => i !== "");
      // elements.map((val, index) => {
      //   formData.append(`task[${index}]`, val);
      // });

      // let unformattedARTasks = values?.ar_tasks;
      // const ar_elements = unformattedARTasks
      //   .split("\n")
      //   .map((ar_element) => ar_element.trim()).filter(i => i !== "");
      // ar_elements.map((val, index) => {
      //   formData.append(`ar_task[${index}]`, val);
      // });

      if (campainId) {
        // update action
        formData?.append("campaign_id", campainId);
        campaignUpdate(formData).then((response) => {
          if (response?.data?.code === 200) {
            dispatch(
              updateConfig((state) => {
                state.formActiveTab = "Uploads";
              })
            );
          } else if (response?.code === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              if(field==="task"){
               formik.setFieldError("tasks", errors[field]);
              }else if(field==="ar_task"){
              formik.setFieldError("ar_tasks", errors[field]);
            }else{
              formik.setFieldError(field, errors[field]);
            }
            });
            handleMoveToErrorTab();
          } else {
            toast.error("Something went wrong");
          }
        });
      } else {
        campaignCreate(formData).then((response) => {
          if (response?.data?.code === 200) {
            dispatch(
              updateConfig((state) => {
                state.campainId = response?.data?.data?.campaign_id;
                state.formActiveTab = "Uploads";
              })
            );
          } else if (response?.code === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              if(field==="task"){
                formik.setFieldError("tasks", errors[field]);
               }else if(field==="ar_task"){
               formik.setFieldError("ar_tasks", errors[field]);
               }else{
               formik.setFieldError(field, errors[field]);
             }
            });
            handleMoveToErrorTab();
          } else {
            toast.error("Something went wrong");
          }
        });
      }
    },
  });

  useEffect(() => {
    if ((isEdit && selectedData) || (selectedData && isDuplicate)) {
      let regions = [];
      // let languages = [];
      let interests = [];

      // brand
      const brand = brands?.data?.filter(
        (value) => value?._id === selectedData?.brand_id
      );

      // postType
      // const postType = basicData?.post_type?.filter(
      //   (value) => value?.id === selectedData?.category
      // );
      // camp type
      const campaignType = basicData?.campaign_type?.filter(
        (value) => value?.id === selectedData?.campaign_type
      );

      //region
      for (let i = 0; i < selectedData?.region_id?.length; i++) {
        const file = selectedData?.region_id[i];
        const country = basicData?.country
          ?.filter((val) => val?.code === "AE")?.[0]
          ?.state?.filter((value) => value?.unique_state_id == file);
        regions.push(country[0]);
      }

      // languages
      // for (let i = 0; i < selectedData?.language_ids?.length; i++) {
      //   const file = selectedData?.language_ids[i];
      //   const language = basicData?.language?.filter(
      //     (value) => value?._id == file
      //   );
      //   languages.push(language[0]);
      // }

      // interets
      for (let i = 0; i < selectedData?.interest_id?.length; i++) {
        const file = selectedData?.interest_id[i];
        const interest = interestData?.filter((value) => value?._id == file);
        interests.push(interest[0]);
      }
      //platform
      const socialMediaData = selectedData?.social_media;
      const influencerCategories = basicData?.influencer_category;
      const mediaCategories = basicData?.platform_media_types;

      const transformSocialMediaData = (data, categories, mediaCategories) => {
        return Object.keys(data).map((platformName) => {
          const platform = data[platformName];
          const selectedPlatform = basicData?.social_media?.find(
            (item) => item.name === platformName
          );

          const mediaTypes = platform?.media_type;

          const matchingTypeObjects = platform?.influencer_category?.map(
            (val) => categories?.find((obj) => obj.name == val)
          );

          const matchingObjects = mediaTypes.map((value) =>
            mediaCategories.find((obj) => obj.id === value)
          );

          return {
            p_name: {
              id: selectedPlatform?.id || "",
              name: platformName ?? "",
              logo: selectedPlatform?.logo || "",
            },
            p_type: matchingTypeObjects,
            m_type: matchingObjects,
          };
        });
      };

      const currentPlatformData = transformSocialMediaData(
        socialMediaData,
        influencerCategories,
        mediaCategories
      );
      const socialMediaData2 = selectedData?.social_media;

      let influencereEarnings = [
        {
          amount: "",
        },
      ];
      if (socialMediaData2) {
        Object.keys(socialMediaData2).forEach((platformName, index) => {
          const platformData = socialMediaData2[platformName];

          const unitRate = platformData ? platformData.unit_rate || 0 : "";

          if (index === 0) {
            influencereEarnings[0].amount = unitRate;
          } else {
            influencereEarnings.push({ amount: unitRate });
          }
        });
      }
      // tasks  old
      // const formattedTasks = selectedData?.task
      //   ?.map((task) => `• ${task}`)
      //   .join("/n");

      // //tasks
      // const formattedTasks = selectedData?.task?.filter(Boolean).join("\n");
      // //ar_tasks
      // const formattedArTasks = selectedData?.ar_task
      //   ?.filter(Boolean)
      //   .join("\n");

      // dos
      const transformedDos = selectedData?.dos?.flatMap((doItem, i) => ({
        do: doItem,
        ar_do: selectedData?.ar_dos[i],
      }));

      const transformedDons = selectedData?.donots?.map((value, i) => ({
        don: value,
        ar_don: selectedData?.ar_donots[i],
      }));

      // engagment rate
      const engagement_type = basicData?.engagement_type?.filter(
        (value) => value?.id === selectedData?.engagement_type
      );

      //gender
      const gender = basicData?.gender?.filter(
        (val) => val?.id === selectedData?.gender
      );
      const htmlContent = selectedData?.description?.replace(/\\/g, "");
      const sanitizedHtml = DOMPurify?.sanitize(htmlContent);
      const contentBlock = htmlToDraft(sanitizedHtml);
      const contentState = ContentState?.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);

      //setting up arabic description
      const htmlContentArabic = selectedData?.ar_description?.replace(/\\/g, "");
      const santizedHtmlArabic = DOMPurify?.sanitize(htmlContentArabic);
      const contentBlockArabic = htmlToDraft(santizedHtmlArabic);
      const contentStateArabic = ContentState?.createFromBlockArray(
        contentBlockArabic.contentBlocks
      );
      const editorStateArabic =
        EditorState.createWithContent(contentStateArabic);


        const taskContent = selectedData?.task?.replace(/\\/g, "");
        const sanitizedHtmlTask = DOMPurify?.sanitize(taskContent);
        const contentBlockTask = htmlToDraft(sanitizedHtmlTask);
        const contentStateTask = ContentState?.createFromBlockArray(
          contentBlockTask.contentBlocks
        );
        const editorStateTask = EditorState.createWithContent(contentStateTask);
        //setting up arabic description
        const htmlContentArabicTask = selectedData?.ar_task?.replace(/\\/g, "");
        const santizedHtmlArabicTask = DOMPurify?.sanitize(htmlContentArabicTask);
        const contentBlockArabicTask = htmlToDraft(santizedHtmlArabicTask);
        const contentStateArabicTask = ContentState?.createFromBlockArray(
          contentBlockArabicTask.contentBlocks
        );
        const editorStateArabicTask =
          EditorState.createWithContent(contentStateArabicTask);

      setInitialData({
        campaign_name: selectedData?.title??"",
        ar_campaign_name: selectedData?.ar_title??"",
        allowed_date: selectedData?.allowed_days??"",
        location: selectedData?.location_name??"",
        ar_location: selectedData?.ar_location_name??"",
        distance: selectedData?.distance??"",
        region: regions,
        brand: brand?.[0],
        // languages,
        interests,
        description: editorState,
        ar_description: editorStateArabic,
        address: selectedData?.address??"",
        ar_address: selectedData?.ar_address??"",
        from_age: selectedData?.age_from ?? "",
        to_age: selectedData?.age_to ?? "",
        campaign_type: campaignType?.[0],
        // post_type: postType?.[0],
        start_date: getFormatedDate(selectedData?.start_date),
        end_date: getFormatedDate(selectedData?.end_date),
        platform: currentPlatformData,
        budget: selectedData?.budget?.total,
        influencer_earnings: influencereEarnings,
        earnings_description: selectedData?.payment_description,
        ar_earnings_description: selectedData?.ar_payment_description,
        tasks: editorStateTask,
        ar_tasks:  editorStateArabicTask,
        caption_ideas: selectedData?.caption_ideas ?? "",
        ar_caption_ideas: selectedData?.ar_caption_ideas ?? "",
        hash_tags: selectedData?.hashtag ?? "",
        ar_hash_tags: selectedData?.ar_hashtag ?? "",
        brand_mentions: selectedData?.brand_mention ?? "",
        ar_brand_mentions: selectedData?.ar_brand_mention ?? "",
        dos: transformedDos,
        dons: transformedDons,
        gender: gender?.[0],
        // location: selectedData?.location_name,
        engagment_type:
          selectedData?.payment_type !== 0 ? engagement_type?.[0] : "",
      });
      dispatch(
        updateConfig(
          (state) =>
            (state.maps = {
              lat: selectedData?.location_?.latitude ?? 23.4241,
              lng: selectedData?.location_?.longitude ?? 53.8478,
              place: selectedData?.location_name ?? "",
              ar_place: selectedData?.ar_location_name ?? "",
            })
        )
      );
      dispatch(
        updateConfig(
          (state) =>
            (state.selectedLocation = {
              lat: selectedData?.location_?.latitude ?? "",
              lng: selectedData?.location_?.longitude ?? "",
              place: selectedData?.location_name ?? "",
              ar_place: selectedData?.ar_location_name ?? "",
            })
        )
      );
      setStatus(selectedData?.status === "Active" ? true : false);
      setContent(selectedData?.content_pre_check === 1 ? true : false);
      setSelectedOption(
        selectedData?.payment_type === 0 ? "flat" : "engagment_rate"
      );
      if(!isDuplicate){
      dispatch(
        updateConfig((state) => {
          state.isLiveEdit =
            selectedData?.is_live || selectedData?.status === "Completed";
          state.isCampaignStatus = selectedData?.is_live
            ? "live"
            : selectedData?.status === "Completed"
            ? "completed"
            : selectedData?.status === "Stopped"
            ? "stopped"
            : "";
        })
      );}
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, selectedData, brands, isDuplicate]);

  const tabs = [
    {
      label: "Overview",
      completed: calculateCompletionStatus([
        "campaign_name",
        "ar_campaign_name",
        // "p_name",
        // "p_type",
        // "m_type",
        "region",
        "campaign_type",
        "from_age",
        "to_age",
        "distance",
        // "post_type",
        "brand",
        // "languages",
        "interests",
        "description",
        "ar_description",
        "start_date",
        "end_date",
        "allowed_date",
        "location",
        "ar_location",
        "address",
        "ar_address",
      ]),
    },
    {
      label: "Earnings",
      completed: calculateCompletionStatus([
        "budget",
        "amount",
        "earnings_description",
        "ar_earnings_description",
      ]),
    },
    {
      label: "Tasks",
      completed: calculateCompletionStatus([
        "tasks",
        "ar_tasks",
        "caption_ideas",
        "ar_caption_ideas",
        "hash_tags",
        "ar_hash_tags",
        "brand_mentions",
        "ar_brand_mentions",
      ]),
    },
    {
      label: "Dos&Don'ts",
      completed: calculateCompletionStatus(["do", "don", "ar_do", "ar_don"]),
    },
    {
      label: "Uploads",
      completed: uploadComplete(),
    },
  ];

  function uploadComplete() {
    let uploadsFiles = UploadedValues;
    Object.keys(uploadsFiles).filter((key) => {
      return key === "banner"
        ? uploadsFiles?.[key]?.file
        : uploadsFiles?.[key]?.[0]?.file;
    });

    let hasValues = Object.keys(uploadsFiles).filter((key) => {
      return key === "banner"
        ? uploadsFiles?.[key]?.file
        : uploadsFiles?.[key]?.[0]?.file;
    });
    return (hasValues.length / Object.keys(uploadsFiles).length) * 100;
  }

  const tabsAndFields = [
    {
      label: "Overview",
      fields: [
        "campaign_name",
        "p_name",
        "p_type",
        "m_type",
        "region",
        "campaign_type",
        "from_age",
        "to_age",
        "distance",
        "maps",
        // "post_type",
        "brand",
        // "languages",
        "interests",
        "description",
        "start_date",
        "end_date",
        "allowed_date",
        "location",
        "address",
      ],
    },
    {
      label: "Earnings",
      fields: ["budget", "engagment_type", "amount", "earnings_description"],
    },
    {
      label: "Tasks",
      fields: ["tasks","ar_tasks","caption_ideas", "hash_tags", "brand_mentions"],
    },
    {
      label: "Dos&Don'ts",
      fields: ["do", "don"],
    },
    {
      label: "Uploads",
      fields: [""],
    },
  ];

  function calculateCompletionStatus(fields) {
    // eslint-disable-next-line array-callback-return
    var completedFields = fields?.filter((field) => {
      if (Array.isArray(formik?.values[field])) {
        return formik?.values[field]?.length > 0;
      } else if (
        field === "p_name" ||
        field === "p_type" ||
        field === "m_type"
      ) {
        return !!formik?.values?.platform?.[0]?.[field];
      } else if (field === "do") {
        return !!formik?.values?.dos?.[0]?.[field];
      } else if (field === "ar_don") {
        return !!formik?.values?.dos?.[0]?.[field];
      } else if (field === "don") {
        return !!formik?.values?.dons?.[0]?.[field];
      } else if (field === "ar_don") {
        return !!formik?.values?.dons?.[0]?.[field];
      } else {
        return !!formik?.values[field];
      }
    });
    return (completedFields.length / fields.length) * 130;
  }

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  useEffect(() => {
    if ((isEdit && selectedData) || (selectedData && isDuplicate)) {
      const assetsImages = selectedData?.assets?.images?.map((value) => ({
        file: value?.file_name,
        thumb: value?.thumbnail,
      }));

      const assetsVideos = selectedData?.assets?.videos?.map((value) => ({
        file: value?.file_name,
        thumb: value?.thumbnail,
      }));

      const refImages = selectedData?.reference_assets?.images?.map(
        (value) => ({
          file: value?.file_name,
          thumb: value?.thumbnail,
        })
      );

      const refVideos = selectedData?.reference_assets?.videos?.map(
        (value) => ({
          file: value?.file_name,
          thumb: value?.thumbnail,
        })
      );
      if (isEdit) {
        dispatch(
          updateConfig((state) => {
            state.values.banner.file = selectedData?.image ?? "";
            state.values.banner.thumb = selectedData?.thumbnail_image ?? "";
            state.values.assetsImages = assetsImages ?? [
              {
                file: "",
                thumb: "",
              },
            ];
            state.values.assetsVideos = assetsVideos ?? [
              {
                file: "",
                thumb: "",
              },
            ];
            state.values.refImages = refImages ?? [
              {
                file: "",
                thumb: "",
              },
            ];
            state.values.refVideos = refVideos ?? [
              {
                file: "",
                thumb: "",
              },
            ];
          })
        );
      }

      // const reduxTimeout = setTimeout(() => {
      //   dispatch(
      //     updateConfig((state) => {
      //       state.selectedData = "";
      //     })
      //   );
      //   clearTimeout(reduxTimeout);
      // }, 500);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, selectedData, isDuplicate]);

  const handleMoveToErrorTab = () => {
    // Move activeTab to the first tab with errors
    const firstErrorTab = Object.keys(formik.errors)[0];
    if (firstErrorTab === "platform" || firstErrorTab === "maps") {
      setActiveTabByName("Overview");
      return "";
    }
    if (firstErrorTab === "dos" ||  firstErrorTab ==="ar_dos") {
      setActiveTabByName("Dos&Don'ts");
      return "";
    }
    if (firstErrorTab === "dons" ||  firstErrorTab ==="ar_dons") {
      setActiveTabByName("Dos&Don'ts");
      return "";
    }
    if (firstErrorTab === "influencer_earnings" || firstErrorTab === "amount") {
      setActiveTabByName("Earnings");
      return "";
    }
    if (firstErrorTab && firstErrorTab !== formActiveTab) {
      const errorTab = tabsAndFields.find((tab) =>
        tab.fields.includes(firstErrorTab)
      );
      setActiveTabByName(errorTab?.label);
    }
    setMoveToTab(true);
  };

  const renderTabContent = () => {
    switch (formActiveTab) {
      case "Overview":
        return (
          <Overview
            tabs={tabs}
            formik={formik}
            status={status}
            content={content}
            setStatus={setStatus}
            setContent={setContent}
            getFieldError={getFieldError}
            isCampaignStatus={isCampaignStatus}
          />
        );
      case "Earnings":
        return (
          <Earnings
            formik={formik}
            tabs={tabs}
            getFieldError={getFieldError}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            isCampaignStatus={isCampaignStatus}
          />
        );
      case "Tasks":
        return (
          <Tasks formik={formik} tabs={tabs} getFieldError={getFieldError} isCampaignStatus={isCampaignStatus}/>
        );
      case "Dos&Don'ts":
        return (
          <Dos
            formik={formik}
            tabs={tabs}
            getFieldError={getFieldError}
            handleMoveToErrorTab={handleMoveToErrorTab}
            isLiveEdit={isLiveEdit}
            isEdit={isEdit}
            isCampaignStatus={isCampaignStatus}
          />
        );
      case "Uploads":
        return (
          <Uploads
            formik={formik}
            tabs={tabs}
            getFieldError={getFieldError}
            refetch={refetch}
            handleMoveToErrorTab={handleMoveToErrorTab}
            selectedData={selectedData}
            isLiveEdit={isLiveEdit}
            isEdit={isEdit}
            isCampaignStatus={isCampaignStatus}
          />
        );
      default:
        return null;
    }
  };

  function setActiveTabByName(active) {
    setMoveToTab(false);
    const updatedTab = tabs.find((tab) => tab?.label === active);
    dispatch(
      updateConfig((state) => {
        state.formActiveTab = updatedTab?.label;
      })
    );
  }

  const handleTabClick = (tab) => {
    if (tab.label !== "Uploads") {
      dispatch(
        updateConfig((state) => {
          state.formActiveTab = tab?.label;
        })
      );
    }
    if (isEdit) {
      dispatch(
        updateConfig((state) => {
          state.formActiveTab = tab?.label;
        })
      );
    }
    if (campainId) {
      dispatch(
        updateConfig((state) => {
          state.formActiveTab = tab?.label;
        })
      );
    }
  };
  useEffect(() => {
    if (moveToTab === true) {
      handleMoveToErrorTab();
    }
    setMoveToTab(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.errors, moveToTab]);

  //getting the sum of total influencer earnings
  useEffect(() => {
    const values = formik?.values?.influencer_earnings?.map(
      (item) => +item?.amount
    );
    const sum = values.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

    if (sum > 0) {
      setInfluencerValues(+sum);
    }
    //eslint-disable-next-line
  }, [formik?.values?.influencer_earnings]);

  return {
    tabs,
    formik,
    activeTab: formActiveTab,
    handleTabClick,
    renderTabContent,
  };
};

export default useCreateForm;
