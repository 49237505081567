import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { campaignStop } from "../../api";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../Store/Slices/Campaign/Creation/CreationSlice";
import { toast } from "react-toastify";

const useCampStop = ({ refetch }) => {
  const dispatch = useDispatch();
  const { selectedIteamId } = useSelector((state) => state.campaignCreate);
  const formik = useFormik({
    initialValues: {
      reason: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.reason.trim()) {
        errors.reason = "Reason is required";
      } else if (values.reason.trim().length <= 3) {
        errors.reason = "Reason must be greater than 3 characters";
      }
      return errors;
    },
    onSubmit: (values) => {
      let data = {
        campaign_id: selectedIteamId,
        reason: values?.reason,
      };
      campaignStop(data).then((response) => {
        if (response?.data?.code === 200) {
          toast.success("Stopped!");
          handleCloseModal();
          refetch();
        } else {
          toast.error("Something went wrong");
        }
      });
    },
  });

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.campaignStopModal = false)));
  };
  return {
    formik,
    getFieldError,
    handleCloseModal,
  };
};

export default useCampStop;
