import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useAddInterest from "./useAddInterest";

const AddInterest = ({ refetch }) => {
  const { formik, isEdit, getFieldError, handleCloseModal } = useAddInterest({
    refetch,
  });
  return (
    <div className="row">
      <Input
        label={"Interest"}
        type="text"
        id="interest"
        name="interest"
        className={`pro-input lg ${getFieldError("interest") && " error"}`}
        {...formik.getFieldProps("interest")}
        error={getFieldError("interest")}
        errorMessage={getFieldError("interest")}
      />

      <div className="pro-rtl">
        <Input
          label={"(اهتمامات) Interest"}
          type="text"
          id="ar_interest"
          name="ar_interest"
          className={`pro-input lg ${getFieldError("ar_interest") && " error"}`}
          {...formik.getFieldProps("ar_interest")}
          error={getFieldError("ar_interest")}
          errorMessage={getFieldError("ar_interest")}
        />
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>

        <Button
          type="submit"
          className={"pro-btn-primary lg pro-ms-3"}
          onClick={formik.handleSubmit}
        >
          {isEdit ? "Update" : "Add"}
        </Button>
      </div>
    </div>
  );
};

export default AddInterest;
