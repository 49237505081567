import { useSelector } from "react-redux";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useMemo } from "react";

const useCMS = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);
  const drawerMenu = [
    {
      title: "Interest",
      label: "Interest",
      link: "/CMS/interests",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x4">interests</span>,
      active: checkIfActiveRoute("/interests"),
    },
    {
      title: "Support",
      label: "Support",
      link: "/CMS/support",
      iconAsset: "DrawerIcon1",
      icon: (
        <span className="material-symbols-outlined x4">mark_unread_chat_alt</span>
      ),
      active: checkIfActiveRoute("/CMS/support"),
    },
    {
      title: "Language",
      label: "Language",
      link: "/CMS/language",
      iconAsset: "DrawerIcon1",
      icon: (
        <span className="material-symbols-outlined x4">language</span>
      ),
      active: checkIfActiveRoute("/CMS/language"),
    },
    {
      title: "FAQ",
      label: "FAQ",
      link: "/CMS/FAQ",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x4">contact_support</span>,
      active: checkIfActiveRoute("/CMS/FAQ"),
    },
    {
      title: "Policy",
      label: "Policy",
      link: "/CMS/policy",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x4">policy</span>,
      active: checkIfActiveRoute("/CMS/policy"),
    },
    {
      title: "Terms and Conditions",
      label: "Terms & Conditions",
      link: "/CMS/terms",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x4">gavel</span>,
      active: checkIfActiveRoute("/CMS/terms"),
    },
    {
      title: "Contact",
      label: "Contact",
      link: "/CMS/contact",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x4">perm_phone_msg</span>,
      active: checkIfActiveRoute("/CMS/contact"),
    },
    {
      title: "Template",
      label: "Template",
      link: "/CMS/template",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x4">business_messages</span>,
      active: checkIfActiveRoute("/CMS/template"),
    },
  ];
  const drawerMenuPermission = useMemo(() => {
    let menus = Object.values(
      globalState.menu_permissions?.menu_permissions ?? []
    )?.flatMap?.((menu) =>
      menu?.permissions?.map((side_menu) => side_menu?.sub_menu_name)
    );

    return drawerMenu
      ?.filter((menu) => {
        return menus?.includes?.(menu?.label);
      })
      ?.map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.menu_permissions, location.pathname]);

  return {
    drawerMenu: drawerMenuPermission,
  };
};

export default useCMS;
