import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useAddLanguage from "./useAddLanguage";

const AddLanguage = ({ refetch }) => {
  const { formik, isEdit, getFieldError, handleCloseModal } = useAddLanguage({
    refetch,
  });
  return (
    <div className="row">
      <Input
        label={"Language"}
        type="text"
        id="name"
        name="name"
        className={`pro-input lg ${getFieldError("name") && " error"}`}
        {...formik.getFieldProps("name")}
        error={getFieldError("name")}
        errorMessage={getFieldError("name")}
      />
      <div className="pro-rtl">
        <Input
          label={"(اهتمامات) Language"}
          type="text"
          id="ar_name"
          name="ar_name"
          className={`pro-input lg ${getFieldError("ar_name") && " error"}`}
          {...formik.getFieldProps("ar_name")}
          error={getFieldError("ar_name")}
          errorMessage={getFieldError("ar_name")}
        />
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>

        <Button
          type="submit"
          className={"pro-btn-primary lg pro-ms-3"}
          onClick={formik.handleSubmit}
        >
          {isEdit ? "Update" : "Add"}
        </Button>
      </div>
    </div>
  );
};

export default AddLanguage;
