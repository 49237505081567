import { useEffect, useRef, useState } from "react";
import { useGetDashboardDataQuery, useGetDashboardGraphDataQuery } from "../../../Store/Queries/Dashboard";
import { updateConfig } from "../../../Store/Slices/Dashboard/dashboardSlice";
import { updateConfig as globalUpdateConfig } from "../../../Store/Slices/Global";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PiTiktokLogo } from "react-icons/pi";
import { BiLogoInstagram } from "react-icons/bi";
import { SlSocialFacebook } from "react-icons/sl";
import { MdLogout } from "react-icons/md";
import { SlSocialYoutube } from "react-icons/sl";
import { MdPendingActions } from "react-icons/md";
import { getFormatedDate } from "../../../utils/Table";

const useDashboardHome = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const chartRef = useRef(null);
  const dashboardState = useSelector((state) => state.dashboard);
  const { tableFields } = useSelector((state) => state.global);
  const [listingType, setListingType] = useState();
  const [initialDate, setInitialDate] = useState({
    startDate: dashboardState.startDate ? dashboardState.startDate : null,
    endDate: dashboardState.endDate ? dashboardState.endDate : null,
    key: "selection",
  });
  const {
    data: dashboardData = {},
    isFetching,
    isSuccess,
  } = useGetDashboardDataQuery({
    start_date: dashboardState?.startDate
      ? getFormatedDate(dashboardState?.startDate)
      : "",
    end_date: dashboardState?.endDate
      ? getFormatedDate(dashboardState?.endDate)
      : "",
  });
  const {
    data: dashboardGraph = {},
  } = useGetDashboardGraphDataQuery({
    start_date: dashboardState?.startDate
      ? getFormatedDate(dashboardState?.startDate)
      : "",
    end_date: dashboardState?.endDate
      ? getFormatedDate(dashboardState?.endDate)
      : "",
  });
  useEffect(() => {
    if (isSuccess) {
      setListingType(Object.keys(dashboardData?.data || {}) ?? "");
    }
    //eslint-disable-next-line
  }, [isSuccess]);

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = dashboardData?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [dashboardData]);

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleClick = (item) => {
    switch (item) {
      case "total_campaigns":
        navigate("/campaign?activeTab=all");
        break;
      case "total_influencers":
        navigate("/user?activeTab=all");
        break;
      case "total_brands":
        navigate("/brand");
        break;
      case "total_supports":
        navigate("/CMS/support");
        break;
      default:
        break;
    }
  };

  const handleDateChange = (date) => {
    dispatch(
      updateConfig((state) => {
        state.startDate = new Date(date.startDate);
        state.endDate = new Date(date.endDate);
      })
    );
    setInitialDate({
      startDate: new Date(date.startDate),
      endDate: new Date(date.endDate),
      key: "selection",
    });
  };

  let dashboardItems = {
    total_campaigns: {
      label: "total_campaigns",
      title: "Total Campaigns",
      icon: "workspace_premium",
      handleClick,
    },
    total_influencers: {
      label: "total_influencers",
      title: "Total Influencers",
      icon: "group",
      handleClick,
    },
    total_brands: {
      label: "total_brands",
      title: "Total Brands",
      icon: "category",
      handleClick,
    },
    total_supports: {
      label: "total_supports",
      title: "Total Supports",
      icon: "speaker_notes",
      handleClick,
    },
    user_applied_campaigns: {
      label: "user_applied_campaigns",
      title: "User Applied Campaigns",
      icon: "workspace_premium",
    },
    no_social_media_user_count: {
      label: "no_social_media_user_count",
      title: "No Social Media User Count",
      icon: "group",
    },
    pending_connection_count: {
      label: "pending_connection_count",
      title: "Pending Connection Count",
      icon: "group",
    },
    instagram_connection_count: {
      label: "instagram_connection_count",
      title: "Instagram Connection Count",
      icon: "reactIcon",
      reactIcon: <BiLogoInstagram />,
    },
    tiktok_connection_count: {
      label: "tiktok_connection_count",
      title: "TikTok Connection Count",
      icon: "reactIcon",
      reactIcon: <PiTiktokLogo />,
    },
    facebook_connection_count: {
      label: "facebook_connection_count",
      title: "Facebook Connection Count",
      icon: "reactIcon",
      reactIcon: <SlSocialFacebook />,
    },
    youtube_connection_count: {
      label: "youtube_connection_count",
      title: "YouTube Connection Count",
      icon: "reactIcon",
      reactIcon: <SlSocialYoutube />,
    },
    withdrawal_request_count: {
      label: "withdrawal_request_count",
      title: "Withdrawal Request Count",
      icon: "reactIcon",
      reactIcon: <MdLogout />,
    },
    total_wallet_transaction_completed: {
      label: "total_wallet_transaction_completed",
      title: "Total Wallet Transaction Completed",
      icon: "paid",
      price:"AED"
    },
    pending_request_count: {
      label: "pending_request_count",
      title: "Pending Request Count",
      icon: "reactIcon",
      reactIcon: <MdPendingActions />,
    },
    pending_content_verification_count: {
      label: "pending_content_verification_count",
      title: "Pending Content Verification Count",
      icon: "verified_user",
    },
    pending_users_payment_to_wallet_count: {
      label: "pending_users_payment_to_wallet_count",
      title: "Pending Users' Payment to Wallet Count",
      icon: "work_history",
    },
    total_payout_completed: {
      label: "total_payout_completed",
      title: "Total Payout Completed",
      icon: "work_history",
      price:"AED"
    },
    remaining_wallet_credit: {
      label: "remaining_wallet_credit",
      title: "Remaining Wallet Credit",
      icon: "work_history",
      price:"AED"
    },
    // pending_approval: {
    //   label: "pending_approval",
    //   title: "Pending Approval",
    //   icon: "approval_delegation",
    //   handleClick,
    // },
  };
  let width, height, gradient;

  const getLineGradient = (ctx, chartArea) => {
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (!gradient || width !== chartWidth || height !== chartHeight) {
      width = chartWidth;
      height = chartHeight;
      gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
      gradient.addColorStop(0, '#FAAD4F');
      gradient.addColorStop(0.35, '#DD2A7B');
      gradient.addColorStop(0.62, '#9537B0');
      gradient.addColorStop(1, '#515BD4');
    }

    return gradient;
  };

  const getLineBackgroundGradient = (ctx, chartArea) => {
    const backgroundGradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
    backgroundGradient.addColorStop(0, 'rgba(229, 47, 94, 0.1)');
    backgroundGradient.addColorStop(0.5, 'rgba(229, 47, 94, 0.06)');
    backgroundGradient.addColorStop(1, 'rgba(255, 255, 255, 0)');
    return backgroundGradient;
  };

  const getBarGradient = (ctx, chartArea) => {
    // Create a single gradient for the entire chart area
    const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(0, '#FAAD4F'); // Start color
    gradient.addColorStop(0.35, '#DD2A7B'); // Middle color
    gradient.addColorStop(0.62, '#9537B0'); // Middle color
    gradient.addColorStop(1, '#515BD4'); // End color
    return gradient;
  };
  const doughnutOptions = {
    plugins: {
      legend: {
        display: false,
        position: "right",
        align: "end",
        labels: {
          boxWidth: 12,
          boxHeight: 12,
          font: {
            size: 12,
          },
        },
      },
      afterDraw: () => {},
    },
    scales: {
      y: {
        display: false,
      },
      x: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    width: 300,
    height: 300,
    aspectRatio: 1,
  };

  const lineOptions = {
    plugins: {
      legend: {
        display: false,  // Hides the legend
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        ticks: {
          beginAtZero: true,
        },
        grid: {
          display: false
        },
        border: {
          display: false,
          dash: [0, 0],
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    width: 300,
    height: 300,
    aspectRatio: 1,
  };


  const barOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          beginAtZero: true,
        },
        grid: {
          display: false,
        },
        barThickness: 100, // Set the thickness of the bars
        barPercentage: 0,
        categoryPercentage: 0,
      },
      y: {
        ticks: {
          beginAtZero: true,
        },
        grid: {
          color: "#eee", // Light grid color
        },
        border: {
          display: false,
          dash: [0, 0],
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false, // Allow responsive behavior
    width: 300, // Set the width of the chart
    height: 300, // Set the height of the chart
    aspectRatio: 1, // Set aspect ratio
  };

  const handleSort = (label) => {
    if (dashboardState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = dashboardState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  const doughNutData = {
    labels: dashboardGraph?.data?.data?.pie_chart?.labels,
    datasets: [
      {
        label: "No of Users",
        data: dashboardGraph?.data?.data?.pie_chart?.data,
        fill: false,
        backgroundColor: [
          "rgb(221, 42, 120)",
          "rgb(0, 0, 0)",
          "rgb(24, 119, 242)",
          "rgb(255, 2, 9)",
        ],
        hoverBackgroundColor: [
          "rgba(221, 42, 120, 0.8)",
          "rgba(0, 0, 0, 0.8)",
          "rgba(24, 119, 242, 0.8)",
          "rgba(255, 2, 9, 0.8)",
        ],
      },
    ],
  };
  const lineData = {
    labels: dashboardGraph?.data?.data?.line_chart?.labels,
    datasets: [
      {
        label: dashboardGraph?.data?.data?.line_chart?.data?.labels,
        data: dashboardGraph?.data?.data?.line_chart?.data?.data,
        tension: 0.5,
        pointRadius: 0,
        borderColor: (context) => {
          const { chart } = context;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return;
          }
          return getLineGradient(ctx, chartArea);
        },
        backgroundColor: (context) => {
          const { chart } = context;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return;
          }
          return getLineBackgroundGradient(ctx, chartArea);
        },
        fill: true,
        borderWidth: 2,
      },
    ],
  };
  const barData = {
    labels: dashboardGraph?.data?.data?.bar_chart?.labels,
    datasets: [
      {
        label: dashboardGraph?.data?.data?.bar_chart?.data?.labels,
        data: dashboardGraph?.data?.data?.bar_chart?.data?.data,
        backgroundColor: (context) => {
          const { chart } = context;
          const { ctx, chartArea } = chart;
  
          // Ensure chart area is available
          if (!chartArea) {
            return;
          }
          
          // Use the single gradient for all bars
          return getBarGradient(ctx, chartArea);
        },
        fill: false,
      },
    ],
  };
  return {
    dashboardData,
    listingType,
    dashboardItems,
    isFetching,
    isSuccess,
    initialDate,
    tableFields,
    dashboardState,
    doughnutOptions,
    lineOptions,
    barOptions,
    doughNutData,
    lineData,
    chartRef,
    barData,
    dashboardGraph,
    getRow,
    handleDateChange,
    handleSort,
  };
};

export default useDashboardHome;
