
import { useDispatch, useSelector } from "react-redux";
import { updateConfig } from "../../../Store/Slices/Admins/adminsSlice";
import { updateConfig as globalUpdateConfig } from "../../../Store/Slices/Global";
import { useEffect } from "react";
import {
  admins,
  useGetAdminsDataQuery,
  useUpdateAdminsTableHeadDataMutation,
  useUpdateStatusDataMutation,
} from "../../../Store/Queries/Admins";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
import Assets from "../../../Assets";
import { current } from "@reduxjs/toolkit";
import { viewAdmin } from "./api";

const useAdmins = () => {
  const dispatch = useDispatch();
  const adminsState = useSelector((state) => state.admins);

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const [updateAdminsFields] = useUpdateAdminsTableHeadDataMutation();
  const [updateStatus] = useUpdateStatusDataMutation();

  const {
    data: adminsList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetAdminsDataQuery({
    items_per_page: adminsState.currentPageSize,
    current_page: adminsState.currentPage,
    sort_by: adminsState.sortBy,
    sort_order: adminsState.sortOrder,
    search: adminsState.search,
  });

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = adminsList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [adminsList]);
  const handleChange = (data) => {
    const newStatus = data.status === "Active" ? true : false;
    let params = {
      subadmin_id: data?._id,
      status: newStatus ? 0 : 1,
    };

    updateStatus(params);
    dispatch(
      admins.util.updateQueryData(
        "getAdminsData",
        {
          items_per_page: adminsState.currentPageSize,
          current_page: adminsState.currentPage,
          sort_by: adminsState.sortBy,
          sort_order: adminsState.sortOrder,
          search: adminsState.search,
        },
        (cachedData) => {
          let updatedCachedData = { ...current(cachedData) };
          let newResult = updatedCachedData?.data?.data?.map((res) =>
            res?._id === data?._id
              ? {
                  ...res,
                  status: data?.status === "Active" ? "Inactive" : "Active",
                }
              : res
          );
          updatedCachedData = {
            ...updatedCachedData,
            data: {
              ...updatedCachedData.data,
              data: newResult,
            },
          };
          return updatedCachedData;
        }
      )
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      first_name: (feild, data) => (
        <IconText
          icon={
            <img
              src={data["image"] ?? Assets.PROFILE_USER}
              alt={"profile-pic"}
              width={20}
              height={20}
              className="object-fit-cover"
            />
          }
          title={data[feild]}
          propStyle={{
            rootIcon : 'pro-d-flex pro-items-center pro-rounded-circle pro-overflow-hidden',
            root : 'pro-d-flex pro-items-center pro-w-100 text-truncate'
          }}
        />
      ),
      status: (feild, data) => {
        const status = data[feild] === "Active";
        return (
          <>
            {adminsList?.data?.permission?.can_change_status ? (
              <div className="pro-toggle">
                <div className="pro-toggle-box">
                  <input
                    type="checkbox"
                    role="switch"
                    id="status"
                    checked={status}
                    onChange={() => handleChange(data)}
                  />
                  <span></span>
                </div>
              </div>
            ) : data[feild] === "Active" ? (
              <span className={"badge-success-outline pro-badge"}>
                {data[feild]}
              </span>
            ) : (
              <span className={"badge-warning-outline pro-badge"}>
                {data[feild]}
              </span>
            )}
          </>
        );
      },
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (adminsState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = adminsState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleCloseAddModal = () => {
    dispatch(
      updateConfig((state) => {
        state.addModal = false;
        state.imagePreview = "";
      })
    );
  };
  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.addModal = true;
        state.isEdit = false;
      })
    );
  };

  const handleEditAction = (e) => {
    let data = {
      id: e?.[0],
    };
    viewAdmin(data).then((response) => {
      if (response?.data?.code === 200) {
        dispatch(
          updateConfig((state) => (state.itemData = response?.data?.data))
        );
      }
    });
    dispatch(
      updateConfig((state) => {
        state.isEdit = true;
        state.addModal = true;
        state.selectedItem = data?.id;
      })
    );
  };

  return {
    paginationOptions,
    tableFields,
    adminsState,
    adminsList,
    isLoading,
    isFetching,
    showEditModal,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    handlePageSize,
    handleClearClick,
    handleEditAction,
    handlePagination,
    handleCreateClick,
    updateAdminsFields,
    handleCloseAddModal,
    handleEditColumnsClick,
  };
};

export default useAdmins;
