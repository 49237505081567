import React from "react";
import Style from "./profile.module.scss";
import ProfileCard from "./ProfileCard";
import useProfile from "./useProfile";
import { Button } from "@wac-ui-dashboard/wac_component_library";
import Select from "react-select";

const Profile = () => {
  const {
    formik,
    userDetails,
    globalState,
    showNewPassword,
    isPasswordChange,
    countryCodeOptions,
    showExistingPassword,
    handleCloseModal,
    handleShowNewPassword,
    handlePasswordChange,
    handleDeleteImage,
    handleProfileImageChange,
    handleShowExistingPassword,
  } = useProfile();
  return (
    <div className={Style.root}>
      <div className={Style.item}>
        <ProfileCard
          userData={userDetails}
          imagePreview={globalState?.profileThumb}
          formik={formik}
          handleProfileImageChange={handleProfileImageChange}
          handleDeleteImage={handleDeleteImage}
        />
      </div>
      <div className={Style.item}>
        <h6>Contact info</h6>
        <div className="row">
          <div className="col-12 pro-mb-4">
            <div className="input-wrap">
              <label
                htmlFor="email"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                Email
              </label>
              <input
                type="email"
                className={`pro-input lg ${
                  formik.errors.email && formik.touched.email && "error"
                }`}
                id="email"
                name="email"
                autoComplete="email"
                onBlur={formik?.handleBlur("email")}
                onChange={formik?.handleChange}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email && (
                <span className="error-text">{formik.errors.email}</span>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="input-country-wrap ">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Contact Number
              </label>
              <div className="input-main-wrap verify-btn-wrap">
                <div className="code-wrap pro-mb-4 ">
                  <Select
                    id="country_code"
                    options={countryCodeOptions}
                    getOptionLabel={(option) => `${option.label}`}
                    getOptionValue={(option) => `${option.value}`}
                    value={formik?.values?.country_code}
                    classNamePrefix="pro-input"
                    menuPlacement="auto"
                    onChange={(value) =>
                      formik.setFieldValue("country_code", value)
                    }
                  ></Select>
                </div>
                <input
                  type="text"
                  id="personal_number"
                  name="personal_number"
                  className={`pro-input lg ${
                    formik.errors.personal_number &&
                    formik.touched.personal_number &&
                    "error"
                  }`}
                  {...formik.getFieldProps("personal_number")}
                />
              </div>
              {formik.errors.personal_number &&
                formik.touched.personal_number && (
                  <span className="error-text">
                    {formik.errors.personal_number}
                  </span>
                )}
            </div>
          </div>
        </div>
        <div className="pro-check-box pro-mt-4">
          <input
            type="checkbox"
            className="pro-check"
            id="password_change"
            name="password_change"
            onChange={handlePasswordChange}
            checked={isPasswordChange}
          />
          <label htmlFor="password_change" className="pro-check-label">
            Change Password
          </label>
        </div>
      </div>
      {isPasswordChange && (
        <div className={Style.item}>
          <h6>Change Password</h6>
          <div className="row">
            <div className="col-12 pro-mb-4">
              <div className="input-wrap p-re">
                <label
                  htmlFor="existingPassword"
                  className="pro-font-sm pro-mb-1 pro-fw-medium"
                >
                  Existing password
                </label>
                <input
                  type={!showExistingPassword ? "password" : "text"}
                  className={`pro-input lg ${
                    formik.errors.old_password &&
                    formik.touched.old_password &&
                    "error"
                  }`}
                  id="old_password"
                  name="old_password"
                  autoComplete="new-password"
                  {...formik.getFieldProps("old_password")}
                />
                {showExistingPassword ? (
                  <button
                    className={`hide-icon`}
                    onClick={handleShowExistingPassword}
                  >
                    <span className="material-symbols-outlined">
                      visibility_off
                    </span>
                  </button>
                ) : (
                  <button
                    className={`hide-icon`}
                    onClick={handleShowExistingPassword}
                  >
                    <span className="material-symbols-outlined">
                      visibility
                    </span>
                  </button>
                )}
                {formik.touched.old_password && formik.errors.old_password && (
                  <span className="error-text">
                    {formik.errors.old_password}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="input-wrap p-re">
                <label
                  htmlFor="password"
                  className="pro-font-sm pro-mb-1 pro-fw-medium"
                >
                  New password
                </label>
                <input
                  type={!showNewPassword ? "password" : "text"}
                  className={`pro-input lg ${
                    formik.errors.password && formik.touched.password && "error"
                  }`}
                  id="password"
                  name="password"
                  autoComplete="new-password"
                  {...formik.getFieldProps("password")}
                />
                {showNewPassword ? (
                  <button
                    className={`hide-icon`}
                    onClick={handleShowNewPassword}
                  >
                    <span className="material-symbols-outlined">
                      {" "}
                      visibility_off{" "}
                    </span>
                  </button>
                ) : (
                  <button
                    className={`hide-icon`}
                    onClick={handleShowNewPassword}
                  >
                    <span className="material-symbols-outlined">
                      {" "}
                      visibility{" "}
                    </span>
                  </button>
                )}
                {formik.touched.password && formik.errors.password && (
                  <span className="error-text">{formik.errors.password}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={`col-12 pro-mt-4 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btns"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-mx-3"}
          onClick={formik.handleSubmit}
          type="submit"
        >
          Update
        </Button>
      </div>
    </div>
  );
};

export default Profile;
