import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useAddTemplate from "./useAddTemplate";

const AddTemplate = ({ refetch }) => {
  const { formik, isEdit, getFieldError, handleCloseModal } = useAddTemplate({
    refetch,
  });
  return (
    <div className="row">
      <Input
        label={"Title"}
        type="text"
        id="title"
        name="title"
        className={`pro-input lg ${getFieldError("title") && " error"}`}
        {...formik.getFieldProps("title")}
        error={getFieldError("title")}
        errorMessage={getFieldError("title")}
      />

      <div className="pro-rtl">
        <Input
          label={"(عنوان) Title"}
          type="text"
          id="ar_title"
          name="ar_title"
          className={`pro-input lg ${
            getFieldError("ar_title") && " error"
          }`}
          {...formik.getFieldProps("ar_title")}
          error={getFieldError("ar_title")}
          errorMessage={getFieldError("ar_title")}
        />
      </div>
      <div className="col-12 pro-mb-4">
        <div className="input-wrap">
          <label
            htmlFor="message"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Message
          </label>
          <textarea
            id="message"
            rows={3}
            name="message"
            className={`pro-input lg ${getFieldError("message") && "error"} `}
            value={formik?.values?.message ?? ""}
            onBlur={formik?.handleBlur("message")}
            onChange={(e) => formik.setFieldValue("message", e.target.value)}
          ></textarea>
          {getFieldError("message") && (
            <span className="error-text">{getFieldError("message")}</span>
          )}
        </div>
      </div>
      <div className="col-12 pro-mb-4">
        <div className="input-wrap pro-rtl">
          <label
            htmlFor="ar_message"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            (رسالة)Message
          </label>
          <textarea
            id="ar_message"
            rows={3}
            name="ar_message"
            className={`pro-input lg ${getFieldError("ar_message") && "error"} `}
            value={formik?.values?.ar_message ?? ""}
            onBlur={formik?.handleBlur("ar_message")}
            onChange={(e) =>
              formik.setFieldValue("ar_message", e.target.value)
            }
          ></textarea>
              {getFieldError("ar_message") && (
            <span className="error-text">{getFieldError("ar_message")}</span>
          )}
        </div>
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>

        <Button
          type="submit"
          className={"pro-btn-primary lg pro-ms-3"}
          onClick={formik.handleSubmit}
        >
          {isEdit ? "Update" : "Add"}
        </Button>
      </div>
    </div>
  );
};

export default AddTemplate;
