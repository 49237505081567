import React from "react";
import { usePagination, DOTS } from "./usePagination";

import Select from "react-select";
import PropTypes from "prop-types";
import Style from "./Pagination.module.scss";

export const Pagination = ({
  onPageChange,
  onActionChange,
  totalPageCount,
  siblingCount = 1,
  currentPage,
  defaultValue,
  actionIsSearchable,
  options,
  center,
}) => {
  const paginationRange = usePagination({
    totalPageCount,
    siblingCount,
    currentPage,
  });

  if (currentPage === 0 || paginationRange?.length < 1) {
    return null;
  }

  const onNext = () => {
    const inputElement = document.getElementById("pageInput");
    if (inputElement) {
      inputElement.value = "";
    }
    currentPage < totalPageCount && onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    const inputElement = document.getElementById("pageInput");
    if (inputElement) {
      inputElement.value = "";
    }
    currentPage > 1 && onPageChange(currentPage - 1);
  };
  return (
    <nav
      aria-label={`Page navigation example pro-pagination`}
      className={`${
        center ? `pro-pagination-wrapper pro-d-flex pro-justify-center` : ""
      }`}
    >
      <ul className="pro-pagination">
        <li
          className={`page-item ${currentPage === 1 && "disabled"}`}
          onClick={onPrevious}
        >
          <span className="page-link" aria-label="Previous">
            <span className="material-symbols-outlined">chevron_left</span>
          </span>
        </li>
        {paginationRange?.map((item, index) =>
          item === DOTS ? (
            <li key={index} className="page-item">
              <span className="page-link">...</span>
            </li>
          ) : (
            <li
              key={index}
              className={`page-item ${item === currentPage && "active"}`}
              onClick={() => {
                const inputElement = document.getElementById("pageInput");
                if (inputElement) {
                  inputElement.value = "";
                }
                onPageChange(item);
              }}
            >
              <span className="page-link">{item}</span>
            </li>
          )
        )}
        <li
          className={`page-item ${
            currentPage === totalPageCount && "disabled"
          }`}
          onClick={onNext}
        >
          <span className="page-link" aria-label="Next">
            <span className="material-symbols-outlined">chevron_right</span>
          </span>
        </li>
        <li className="pro-d-flex pro-justify-center pro-items-center">
          {options && (
            <Select
              isSearchable={actionIsSearchable}
              options={options}
              className={"pro-input "}
              classNamePrefix="pro-input"
              defaultValue={defaultValue ?? options[0]}
              id={3}
              onChange={onActionChange}
              menuPlacement="top"
            />
          )}
        </li>
      </ul>
      <div className={`input-wrap ${Style.pagination_search}`}>
        <input
          type="text"
          className={`pro-input icon-r lg`}
          id="pageInput"
          onChange={(e) => {
            const value = parseInt(e.target.value, 10);
            if (value && value <= totalPageCount && e.target.value !== "") {
              onPageChange(value);
            } else if (value > totalPageCount && e.target.value !== "") {
              e.target.value = totalPageCount;
              onPageChange(totalPageCount);
            }
          }}
          onKeyDown={(e) => {
            const regex = /^[0-9]$/;
            const allowedKeys = [
              "Backspace",
              "ArrowLeft",
              "ArrowRight",
              "Tab",
              "Delete",
            ];

            if (!regex.test(e.key) && !allowedKeys.includes(e.key)) {
              e.preventDefault();
            }
          }}
        />

        <span className="pro-icon">
          <span className="material-symbols-outlined x4"> search </span>
        </span>
      </div>
    </nav>
  );
};

Pagination.propTypes = {
  /**
   * Triggers when a page changes.
   */
  onPageChange: PropTypes.func,
  totalPageCount: PropTypes.number,
  /**
   * Determines how many items should be around an item
   * @default 1
   */
  siblingCount: PropTypes.number,
  currentPage: PropTypes.number,
  actionIsSearchable: PropTypes.bool,
  /**
   * @example [{value: 10, label: "10 per page" },
   *      {value: 20, label: "20 per page" }]
   */
  options: PropTypes.array,
  onActionChange: PropTypes.func,
  center: PropTypes.bool,
};
