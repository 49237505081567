import style from "./dashboardCard.module.scss";
const DashboardCard = ({
  title,
  data,
  prev,
  icon,
  active,
  transactions,
  reactIcon,
  price,
  isDashboard,
}) => {
  return (
    <div className={`${style.root} ${active ? `active` : ""}`}>
      <div className={`${style.inner}`}>
        <div className={`${style.inner_heading} pro-mb-3`}>
          {/* {icon !== "reactIcon" ? (
            <div className={`${style.icon}`}>
              <span className="material-symbols-outlined">{icon}</span>
            </div>
          ) : (
            <div className={`${style.icon}`}>{reactIcon}</div>
          )} */}
          <h6 className={`pro-mb-0 ${isDashboard ? `${style.text_alt}` : "pro-ttl h6 pro-fw-medium"}`}>
            {title}
          </h6>
        </div>
        <div className={`${style.body} pro-d-flex pro-gap-1 pro-items-center`}>
          <h3 className={`pro-ttl pro-mb-0 pro-fw-bold ${style.total_value}`}>
            {data?.total ? (
              <span className="material-symbols-outlined">currency_rupee</span>
            ) : (
              <></>
            )}
            {data?.total ? data?.total : data} 
          </h3>
          {price && <span className="pro-mt-1">{price}</span>}
        </div>
      </div>
    </div>
  );
};

export default DashboardCard;
