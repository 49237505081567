import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../../Store/Slices/Campaign/Creation/CreationSlice";
import { useGetAllBrandsDataQuery } from "../../../../../Store/Queries/Campaign";

const useOverview = ({ formik, status, setStatus, content, setContent }) => {
  const [tommorrow, setTommorow] = useState("");
  const { basicData,interestData} = useSelector((state) => state.global);

  const interests = interestData?[{ _id: 7, name: "All" }, ...interestData]:[];
  const countrydata = basicData?.country?.find(val => val?.code === "AE")?.state ?? [];
  const countrydatas = countrydata.length ? [{ unique_state_id: 30, name: "All" }, ...countrydata] : []; 
  const dispatch = useDispatch();
  const { data: brands } = useGetAllBrandsDataQuery();

  const handleAddPlatform = () => {
    let { platform } = formik?.values;
    let { influencer_earnings } = formik?.values;

    platform = [
      ...platform,
      {
        p_name: "",
        p_type: [],
        m_type: [],
      },
    ];

    influencer_earnings = [
      ...influencer_earnings,
      {
        amount: "",
      },
    ];
    formik.setFieldValue("platform", platform);
    formik.setFieldValue("influencer_earnings", influencer_earnings);
  };

  const setTommorowDate = (date) => {
    const inputDate = date;
    const [year, month, day] = inputDate.split("-").map(Number);
    const currentDate = new Date(year, month - 1, day);
    currentDate.setDate(currentDate.getDate() + 1);
    const nextDay = currentDate.getDate();
    const nextMonth = currentDate.getMonth() + 1;
    const nextYear = currentDate.getFullYear();
    const formattedNextDate = `${nextYear}-${
      (nextMonth < 10 ? "0" : "") + nextMonth
    }-${(nextDay < 10 ? "0" : "") + nextDay}`;
    setTommorow(formattedNextDate);
  };

  const handleStartDateChange = (e) => {
    const d = new Date(e?.target?.value);
    const day = d.getDate().toString().padStart(2, "0");
    const month = (d.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 as getMonth() returns zero-based month
    const year = d.getFullYear();
    formik?.setFieldValue("start_date", `${year}-${month}-${day}`);
    formik?.setFieldValue("end_date", "");
    setTommorowDate(e?.target?.value);
  };

  const handleEndDateChange = (e) => {
    const d = new Date(e?.target?.value);
    const day = d.getDate().toString().padStart(2, "0");
    const month = (d.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 as getMonth() returns zero-based month
    const year = d.getFullYear();
    formik?.setFieldValue("end_date", `${year}-${month}-${day}`);
  };

  const handleStatusChange = (e) => {
    setStatus(!status);
    formik?.setFieldValue("Status", e?.target?.checked);
  };
  const handleContentChange = (e) => {
    setContent(!content);
    formik?.setFieldValue("content", e?.target?.checked);
  };

  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.createModal = false)));
  };

  const handleNext = () => {
    dispatch(updateConfig((state) => (state.formActiveTab = "Earnings")));
  };

  const handleInterestsChange = (value) => {
    if (value?.some((obj) => obj?._id === 7)) {
      formik?.setFieldValue(
        "interests",
        interestData?.map((v) => v) || ""
      );
    } else {
      formik?.setFieldValue("interests", value.map((v) => v) || "");
    }
  };
  const handleRegionChange = (value) => {
    if (value?.some((obj) => obj?.unique_state_id === 30)) {
      formik?.setFieldValue(
        "region",
        countrydata?.map((v) => v) || ""
      );
    } else {
      formik?.setFieldValue("region", value.map((v) => v) || "");
    }
  };
  return {
    tommorrow,
    basicData,
    interests,
    countrydatas,
    brands: brands?.data,
    handleNext,
    handleCloseModal,
    handleAddPlatform,
    handleStatusChange,
    handleEndDateChange,
    handleContentChange,
    handleInterestsChange,
    handleRegionChange,
    handleStartDateChange,
  };
};

export default useOverview;
