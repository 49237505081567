import { createSlice } from "@reduxjs/toolkit";
import { subDays } from "date-fns";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "",
  search: "",
  rejectModal: false,
  activeTab: "requests",
  startDate: subDays(new Date(), 30),
  endDate: new Date(),
  rejectId: "",
};

const campaignApprovalSlice = createSlice({
  name: "campaignApproval",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = campaignApprovalSlice.actions;
export default campaignApprovalSlice.reducer;
