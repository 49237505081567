import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  endDate:"",
  startDate:"",
  sortBy: "",
  sortOrder: "",
  search: "",
  isPending: false,
  createFilterModal:false,
};

const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});
export const { updateConfig } = reportSlice.actions;
export default reportSlice.reducer;
