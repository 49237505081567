import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../API";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    if (method === "get") {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("start")}${getParams("end")}filter=${
            params?.filter || "all"
          }&${getParams("sort_by")}sort=${
            params?.sort_order || "desc"
          }&${getParams("sort")}&items_per_page=${
            params?.items_per_page || "10"
          }&${getParams("search")}&current_page=${params?.current_page || ""}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    } else {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("start")}${getParams("end")}filter=${
            params?.filter || "all"
          }&${getParams("sort_by")}sort_order=${
            params?.sort_order || "desc"
          }&${getParams("start_date")}&items_per_page=${
            params?.items_per_page || "10"
          }&${getParams("search")}&${getParams("social_media")}page=${
            params?.page || ""
          }`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };

export const template = createApi({
  reducerPath: "templateApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["template"],
  endpoints: (builder) => ({
    getTemplateData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/notification_template/list`,
      }),
      providesTags: ["template"],
    }),
  }),
});

export const { useGetTemplateDataQuery } = template;
