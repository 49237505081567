import React from "react";
import useAdmins from "./useAdmins";
import {
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import EmptyData from "../../Global/EmptyData";
import OrderColumn from "../../Global/OrderColumn";
import AddAdmin from "./AddAdmin";
import { Pagination } from "../../Global/Pagination";

const Admins = () => {
  const {
    isLoading,
    isFetching,
    adminsList,
    tableFields,
    adminsState,
    showEditModal,
    paginationOptions,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    handlePageSize,
    handleEditAction,
    handleClearClick,
    handlePagination,
    handleCreateClick,
    updateAdminsFields,
    handleCloseAddModal,
    handleEditColumnsClick,
  } = useAdmins();

  return (
    <div>
      <HeadingGroup
        title={"Admins"}
        className={`pro-mb-4`}
        buttonTitle={adminsList?.data?.permission?.can_create && "Create"}
        handleClick={handleCreateClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          showActions={true}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters
          handleClear={handleClearClick}
          handleActionClick={handleEditColumnsClick}
          searchInputProps={{ value: adminsState?.search }}
        />
        {adminsList?.data?.data?.length > 0 ? (
          <>
            <Table
              extraClasssName={`table-wrap`}
              multiSelect={false}
              data={adminsList?.data?.data || []}
              uniqueID={"_id"}
              fields={tableFields}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              handleSort={handleSort}
              clear={adminsState.clearSelection}
              getRow={getRow}
              loading={isFetching}
              perpage={adminsState?.currentPageSize}
              editable={adminsList?.data?.permission?.can_update}
              handleEdit={handleEditAction}
              deletable={false}
              assignable={false}
              showCheckBox={adminsList?.data?.permission?.can_update}
            />
            {adminsList?.data?.data?.length > 0 && (
              <Pagination
                currentPage={adminsState?.currentPage}
                totalPageCount={Math.ceil(
                  adminsList?.data?.total_count / adminsState.currentPageSize
                )}
                options={paginationOptions}
                onPageChange={handlePagination}
                onActionChange={handlePageSize}
                center
              />
            )}
          </>
        ) : (
          <EmptyData />
        )}
        <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={adminsList?.data?.fields}
              moduleId={adminsList?.data?.table_id}
              updateData={updateAdminsFields}
            />
          </div>
        </ModalLayout>
        <OffCanvasLayout
          show={adminsState?.addModal}
          handleClose={handleCloseAddModal}
          title={adminsState?.isEdit ? "Edit Admin" : "Add Admin"}
        >
          <AddAdmin refetch={refetch} />
        </OffCanvasLayout>
      </div>
    </div>
  );
};

export default Admins;
