import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useAddAdmin from "./useAddAdmin";
import Select from "react-select";
import FileUpload from "../../../Global/FileUpload";

const AddAdmin = ({ refetch }) => {
  const {
    formik,
    isEdit,
    basicData,
    imagePreview,
    isPasswordChange,
    countryCodeOptions,
    getFieldError,
    handleCloseModal,
    handleImageChange,
    handleImageRemove,
    handlePasswordChange,
  } = useAddAdmin({
    refetch,
  });
  return (
    <div>
      <div className="pro-mb-4">
        <FileUpload
          handleBlur={formik?.handleBlur("image")}
          title={"Display Picture"}
          filePreview={imagePreview}
          handleFileChange={handleImageChange}
          handleFileRemove={handleImageRemove}
          getFieldError={getFieldError}
        />
        {getFieldError("image") && (
          <span className="error-text">{getFieldError("image")}</span>
        )}
      </div>
      <Input
        label={"First Name"}
        type="text"
        id="first_name"
        name="first_name"
        className={`pro-input lg ${getFieldError("first_name") && " error"}`}
        {...formik.getFieldProps("first_name")}
        error={getFieldError("first_name")}
        errorMessage={getFieldError("first_name")}
      />
      <Input
        label={"Last Name"}
        type="text"
        id="last_name"
        name="last_name"
        className={`pro-input lg ${getFieldError("last_name") && " error"}`}
        {...formik.getFieldProps("last_name")}
        error={getFieldError("last_name")}
        errorMessage={getFieldError("last_name")}
      />
      <Input
        label={"Email"}
        type="text"
        id="email"
        name="email"
        className={`pro-input lg ${getFieldError("email") && " error"}`}
        {...formik.getFieldProps("email")}
        error={getFieldError("email")}
        errorMessage={getFieldError("email")}
      />
      <div className="input-wrap pro-mb-4">
        <label
          htmlFor="role_type"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Role Type
        </label>
        <Select
          id="role_type"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("role_type") && " error"}`}
          classNamePrefix="pro-input"
          name="role_type"
          options={basicData?.admin_role}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={formik?.values?.role_type}
          onBlur={formik.handleBlur("role_type")}
          onChange={(value) => formik.setFieldValue("role_type", value ?? "")}
          menuPlacement="auto"
          isClearable
        />
        {getFieldError("role_type") && (
          <span className="error-text">{getFieldError("role_type")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4 ">
        <div className="input-country-wrap ">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Contact Number
          </label>
          <div className="input-main-wrap verify-btn-wrap">
            <div className="code-wrap pro-mb-4 ">
              <Select
                id="country_code"
                options={countryCodeOptions}
                getOptionLabel={(option) => `${option.label}`}
                getOptionValue={(option) => `${option.value}`}
                value={formik?.values?.country_code}
                classNamePrefix="pro-input"
                onBlur={formik.handleBlur("country_code")}
                menuPlacement="auto"
                onChange={(value) =>
                  formik.setFieldValue("country_code", value)
                }
              ></Select>
            </div>
            <input
              type="text"
              id="personal_number"
              name="personal_number"
              className={`pro-input lg ${
                formik.errors.personal_number &&
                formik.touched.personal_number &&
                " error"
              }`}
              {...formik.getFieldProps("personal_number")}
            />
          </div>
          {formik.errors.personal_number && formik.touched.personal_number && (
            <span className="error-text">{formik.errors.personal_number}</span>
          )}
        </div>
      </div>
      {isEdit && (
        <div className="pro-check-box pro-mb-4">
          <input
            type="checkbox"
            className="pro-check"
            id="password_change"
            name="password_change"
            onChange={handlePasswordChange}
            checked={isPasswordChange}
          />
          <label htmlFor="password_change" className="pro-check-label">
            Change Password
          </label>
        </div>
      )}
      {(isEdit && isPasswordChange) || !isEdit ? (
        <Input
          label={"Password"}
          type="text"
          id="password"
          name="password"
          className={`pro-input lg ${getFieldError("password") && " error"}`}
          {...formik.getFieldProps("password")}
          error={getFieldError("password")}
          errorMessage={getFieldError("password")}
        />
      ) : (
        <></>
      )}
      <div
        className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          className={"pro-btn-primary lg pro-ms-3"}
          onClick={formik.handleSubmit}
        >
          {isEdit ? "Update" : "Add"}
        </Button>
      </div>
    </div>
  );
};

export default AddAdmin;
