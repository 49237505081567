import React from "react";
import CommonLayout from "../../Pages/Layouts/CommonLayout";
import { Outlet } from "react-router-dom";
import usePayments from "./usePayments";

const Payments = () => {
  const { drawerMenu } = usePayments();
  return (
    <CommonLayout drawerMenu={drawerMenu}>
      <Outlet />
    </CommonLayout>
  );
};

export default Payments;
