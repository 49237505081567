import { useSelector, useDispatch } from "react-redux";
import { updateConfig } from "../../../../../Store/Slices/User/userSlice";

const usePosts = ({ formik }) => {
  const dispatch = useDispatch();
  const { basicData } = useSelector((state) => state.global);
  const { isSocialConnectionEdit } = useSelector((state) => state.users);

  const handleAddPost = () => {
    let { post } = formik?.values;

    post = [
      ...post,
      {
        post_url: "",
        post_type: "",
        like_count: "",
        view_count: "",
        comment_count: "",
      },
    ];
    formik.setFieldValue("post", post);
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  return {
    basicData,
    isSocialConnectionEdit,
    handleAddPost,
    handleCloseModal,
  };
};

export default usePosts;
