import { useSelector } from "react-redux";
import {
  useGetLinkTabDataQuery,
  campaign,
} from "../../../../Store/Queries/Campaign";
import Style from "./verify.module.scss";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../Store/Slices/Campaign/Creation/CreationSlice";
import { toast } from "react-toastify";
// import { IoCheckmarkCircleSharp } from "react-icons/io5";
import Assets from "../../../../Assets";

const useVerify = () => {
  const dispatch = useDispatch();
  const { verifyItemId, verifyTab } = useSelector(
    (state) => state.campaignCreate
  );

  const { data: linkTabData, refetch } = useGetLinkTabDataQuery(
    {
      user_campaign_id: verifyItemId,
    },
    {
      skip: !verifyItemId,
    }
  );

  const handleTabClick = (item) => {
    const isPaymentDisabled = linkTabData?.data?.filter(
      (obj) => obj.process_name === "Verify Links"
    )?.[0];
    if (
      isPaymentDisabled?.status === "incomplete" &&
      item?.process_name === "Payment"
    ) {
      toast.warning("Tab is disabled");
    } else {
      dispatch(updateConfig((state) => (state.verifyTab = item?.process_name)));
      dispatch(campaign.util.invalidateTags(["linkdata"]));
    }
  };

  const divElements = linkTabData?.data?.map((item, index) => (
    <>
      <div
        className={`${Style.order_process} ${Style.active}`}
        key={index}
        onClick={() => handleTabClick(item)}
      >
        <div className="pro-flex-fill">
          <p className="pro-fw-medium pro-mb-1 ">{item?.process_name}</p>
          <span className="pro-mb-0 pro-fw-medium pro-font-xs pro-opacity-50">
            {item?.date}
          </span>
        </div>
        <button className={`pro-btn pro-p-1 ${Style.tick_btn}`}>
          {/* <span className="material-symbols-outlined x4">check_circle</span> */}
          {item?.status === "completed" ? (
            <span className={`${Style.check_tick} ${Style.checked}`}>
              <img src={Assets.CHECKED} alt="checked" />
            </span>
          ) : (
            <span className={`${Style.check_tick} ${Style.check_disable}`}>
              <img src={Assets.CHECK_DISABLE} alt="check disable" />
            </span>
          )}
        </button>
      </div>
    </>
  ));

  return { divElements, verifyTab, linkTabData, refetch };
};

export default useVerify;
