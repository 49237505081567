import {
  HeadingGroup,
  ModalLayout,
  NavGroup,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import Style from "../Users/users.module.scss";
import React from "react";
import { Link } from "react-router-dom";
import EmptyData from "../../Global/EmptyData";
import OrderColumn from "../../Global/OrderColumn";
import useInterestChange from "./useInterestChange";
import { FaSort } from "react-icons/fa";
import Reject from "./Reject";
import { Pagination } from "../../Global/Pagination";

const InterestChange = () => {
  const {
    getRow,
    refetch,
    handleSort,
    updateFields,
    handleSearch,
    handlePageSize,
    handlePagination,
    handleClearClick,
    handleEditColumnsClick,
    handleCloseRejecetModal,
    tabNavs,
    isLoading,
    isFetching,
    tableFields,
    showEditModal,
    paginationOptions,
    interestChangeList,
    interestChangeState,
  } = useInterestChange();
  return (
    <div className="col-auto pro-pt-3 pro-pb-6">
      <HeadingGroup title={"Interest Change Requests"} className={`pro-mb-4`} />
      <div className={`${Style.main_container}`}>
        <NavGroup navigations={tabNavs} type={"type-2"} Link={Link} />
      </div>
      <SearchFilters
        onSearchInput={handleSearch}
        showActions={true}
        handleActionClick={handleEditColumnsClick}
        loading={isLoading}
        SearchIcon={<span className="material-symbols-outlined"> search </span>}
        showClearFilters
        handleClear={handleClearClick}
        searchInputProps={{ value: interestChangeState?.search }}
        isDateRange={false}
        showDateRange={false}
      />
      {interestChangeList?.data?.data?.length > 0 ? (
        <>
          <Table
            extraClasssName={`table-wrap`}
            multiSelect={false}
            data={interestChangeList?.data?.data || []}
            uniqueID={"_id"}
            fields={tableFields}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            handleSort={handleSort}
            clear={interestChangeState.clearSelection}
            getRow={getRow}
            loading={isFetching}
            perpage={interestChangeState?.currentPageSize}
            editable={false}
            deletable={false}
            assignable={false}
            showCheckBox={interestChangeList?.data?.permissions?.can_update}
          />
          {interestChangeList?.data?.data?.length > 0 && (
            <Pagination
              currentPage={interestChangeState?.currentPage}
              totalPageCount={Math.ceil(
                interestChangeList?.data?.total_count /
                  interestChangeState.currentPageSize
              )}
              onPageChange={handlePagination}
              options={paginationOptions}
              onActionChange={handlePageSize}
              center
            />
          )}
        </>
      ) : (
        <EmptyData />
      )}
      <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
        <div className="pro-m-5">
          <OrderColumn
            title={"Choose which columns you see"}
            refetchData={refetch}
            tableFields={interestChangeList?.data?.fields}
            moduleId={interestChangeList?.data?.table_id}
            updateData={updateFields}
          />
        </div>
      </ModalLayout>
      <ModalLayout
        show={interestChangeState?.rejectModal}
        handleClose={handleCloseRejecetModal}
        title={"Reject"}
        closeIcon={<span className="material-symbols-outlined">close</span>}
      >
        <div className="pro-m-2">
          <Reject refetch={refetch} />
        </div>
      </ModalLayout>
    </div>
  );
};

export default InterestChange;
