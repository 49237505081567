import React from "react";
import UploadPhoto from "../../../../Global/UploadPhoto";
import { Button } from "@wac-ui-dashboard/wac_component_library";
import useUploads from "./useUploads";
import FileUpload from "../../../../Global/FileUpload";

const Uploads = ({ refetch, formik, handleMoveToErrorTab,isCampaignStatus}) => {
  const {
    values,
    errors,
    uploadStatus,
    isLiveEdit,
    handleSubmit,
    handleCloseModal,
    handleBannerDelete,
    handleBannerChange,
    handleRefVideosChange,
    handleRefVideosDelete,
    handleRefImagesChange,
    handleRefImagesDelete,
    handleAssetsImagesChange,
    handleAssetsImagesDelete,
    handleAssetsVideosChange,
    handleAssetsVideosDelete,
  } = useUploads({ refetch, formik, handleMoveToErrorTab });

  return (
    <div className="pro-d-flex pro-flex-column pro-h-100">
      <div className="pro-mb-4">
        <FileUpload
          id={"banner"}
          title={"Banner"}
          filePreview={values?.banner?.thumb}
          handleFileChange={handleBannerChange}
          handleFileRemove={handleBannerDelete}
          isError={errors?.banner ? true : false}
          errorMsg={errors?.banner}
          maxSize="5"
          status={uploadStatus?.banner}
          isLiveEdit={isLiveEdit}
          isCampaignStatus={isCampaignStatus}
        />
      </div>
      <div className="pro-mb-4">
        <UploadPhoto
          previews={values?.assetsImages}
          handleChange={handleAssetsImagesChange}
          title={"Assets Images"}
          handleDeleteClick={handleAssetsImagesDelete}
          mutiple
          status={uploadStatus?.assetsImages}
          accept={".png,.jpeg,.jpg"}
          addText={"Add Images"}
          isError={errors?.assetsImages ? true : false}
          errorMsg={errors?.assetsImages}
          showAddButton={values?.assetsImages?.length !== 5}
          isLiveEdit={isLiveEdit}
          isCampaignStatus={isCampaignStatus}
        />
      </div>
      <div className="pro-mb-4">
        <UploadPhoto
          previews={values?.assetsVideos}
          handleChange={handleAssetsVideosChange}
          title={"Assets Videos"}
          handleDeleteClick={handleAssetsVideosDelete}
          mutiple
          accept={".mp4 , .mov, .avi, .wmv , .mkv , .hevc"}
          addText={"Add Videos"}
          isError={errors?.assetsVideos ? true : false}
          errorMsg={errors?.assetsVideos}
          showAddButton={values?.assetsVideos?.length !== 3}
          status={uploadStatus?.assetsVideos}
          isLiveEdit={isLiveEdit}
          isCampaignStatus={isCampaignStatus}
        />
      </div>
      <div className="pro-mb-4">
        <UploadPhoto
          previews={values?.refImages}
          id={"ref_images"}
          handleChange={handleRefImagesChange}
          title={"Reference Images"}
          handleDeleteClick={handleRefImagesDelete}
          mutiple
          accept={".png, .jpeg, .jpg"}
          isError={errors?.refImages ? true : false}
          errorMsg={errors?.refImages}
          showAddButton={values?.refImages?.length !== 5}
          status={uploadStatus?.refImages}
          isLiveEdit={isLiveEdit}
          isCampaignStatus={isCampaignStatus}
        />
      </div>
      <div className="pro-mb-4">
        <UploadPhoto
          previews={values?.refVideos}
          handleChange={handleRefVideosChange}
          title={"Reference Videos"}
          handleDeleteClick={handleRefVideosDelete}
          mutiple
          accept={".mp4, .avi, .mov"}
          addText={"Add Videos"}
          isError={errors?.refVideos ? true : false}
          errorMsg={errors?.refVideos}
          showAddButton={values?.refVideos?.length !== 3}
          status={uploadStatus?.refVideos}
          isLiveEdit={isLiveEdit}
        />
      </div>
      {(isCampaignStatus !=="stopped" &&isCampaignStatus !== "completed")&& (
        <div
          className={`col-12 pro-d-flex pro-justify-end pro-pt-3  pro-mt-5 ${"offcanvas-footer-sticky-btn"}`}
        >
          <Button
            className={"pro-btn-link lg pro-px-5"}
            onClick={handleCloseModal}
            type="button"
          >
            Cancel
          </Button>

          <Button
            className={"pro-btn-primary lg pro-ms-3"}
            onClick={handleSubmit}
            type="button"
          >
            {"Upload"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Uploads;
