import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { updateConfig as globalUpdateConfig } from "../../../Store/Slices/Global";
import { useDispatch } from "react-redux";
import { statusChange, updateConfig } from "../../../Store/Slices/Payments/paymentsSlice";
import {
  useGetPaymentReportQuery,
  useGetPayoutDataQuery,
  useUpdatePayoutStatusDataMutation,
  useUpdatePayoutTableHeadDataMutation,
} from "../../../Store/Queries/Payments";
import Select from "react-select";
import { payoutTableExport } from "../api";
import { toast } from "react-toastify";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
import Assets from "../../../Assets";

const usePayout = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const paymentsState = useSelector((state) => state.payments);
  const { tableFields, showEditModal, basicData } = useSelector(
    (state) => state.global
  );
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const activeTab = searchParams.get("activeTab");

  const {
    data: paymentsList = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetPayoutDataQuery({
    items_per_page: paymentsState.currentPageSize,
    page: paymentsState.currentPage,
    sort_by: paymentsState.sortBy,
    sort_order: paymentsState.sortOrder,
    search: paymentsState.search,
    status:
      paymentsState?.activeTab.charAt(0).toUpperCase() +
      paymentsState?.activeTab.slice(1),
  });

  //payment report fetching
  const {
    data: paymentReport = {},
    isFetching: paymentReportFetching,
    refetch: paymentReportRefetch,
  } = useGetPaymentReportQuery();

  const [updatePaymentsFields] = useUpdatePayoutTableHeadDataMutation();
  const [updateStatus] = useUpdatePayoutStatusDataMutation();

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = paymentsList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [paymentsList]);

  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.activeTab = activeTab;
        state.currentPage = 1;
        state.currentPageSize = 10;
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const navigation = [
    {
      label: "All",
      title: "All",
      link: `/payments?activeTab=all`,
      active: activeTab === "all",
    },
    {
      label: "Pending",
      title: "Pending",
      link: `/payments?activeTab=pending`,
      active: activeTab === "pending",
    },
    {
      label: "Accepted",
      title: "Accepted",
      link: `/payments?activeTab=accepted`,
      active: activeTab === "accepted",
    },
    {
      label: "Completed",
      title: "Completed",
      link: `/payments?activeTab=completed`,
      active: activeTab === "completed",
    },
    {
      label: "Declined",
      title: "Declined",
      link: `/payments?activeTab=declined`,
      active: activeTab === "declined",
    },
  ];

  const handleStatusChange = (value, id) => {
    let data = {
      wallet_transaction_id: id?._id,
      status: value?.id,
    };
    if (value?.id === "Declined") {
      dispatch(statusChange({
        wallet_transaction_id: id?._id,
        status: value?.id
      }));
      dispatch(
        updateConfig((state) => {
          state.declineStopModal = true;
        })
      );
    } else {
      updateStatus(data);
      paymentReportRefetch();
    }
  };
  const handleClick=(data)=>{
    if(paymentsList?.data?.permissions?.can_view_user){
      navigate({ pathname: `/user-details/${data?.user_id}` })
    }else{
      return
    }
  }
  const getRow = (feild, data) => {
    let status = {};
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      status: (feild, data) => {
        const currentStatus = basicData?.wallet_status?.filter(
          (value) => value?.id === data[feild]
        );
        status = currentStatus?.[0];
        let statusOptions;
        if(status?.id==="Accepted"){
           statusOptions = basicData?.wallet_status?.filter(
            (val) => val?.id !== "All"  && val?.id !== "Pending" && val?.id !== "Declined"
          ) ?? [] 
        }else{
           statusOptions = basicData?.wallet_status?.filter(
            (val) => val?.id !== "All" 
          ) ?? []
        }
        return (
          <div className="pro-mb-0">
            {paymentsList?.data?.permissions?.can_change_status ? (
              <Select
                id="status"
                placeholder={"Select"}
                className={`pro-input lg`}
                classNamePrefix="pro-input"
                name="status"
                options={statusOptions}
                getOptionValue={(option) => option?.id}
                getOptionLabel={(option) => option?.name}
                value={status}
                onChange={(value) => handleStatusChange(value, data)}
                menuPlacement="auto"
                menuPosition="fixed"
                isDisabled={status?.id === "Completed"||status?.id === "Declined"}
              />
            ) : data[feild] === "Approved" ? (
              <span className={"badge-warning-outline pro-badge"}>
                {data[feild]}
              </span>
            ) : data[feild] === "Completed" ? (
              <span className={"badge-success-outline pro-badge"}>
                {data[feild]}
              </span>
            ) : (
              <span className={"badge-pending-outline pro-badge"}>
                {data[feild]}
              </span>
            )}
          </div>
        );
      },
      influencer: (feild, data) => (
        <div className="pro-pnt text-truncate">
            <IconText
              icon={
                <img
                  src={data["influencer_thumbnail_image"]?? Assets.PROFILE_USER}
                  alt={"profile-pic"}
                  width={20}
                  height={20}
                  className="object-fit-cover"
                />
              }
              title={data[feild]}
              onClick={()=>handleClick(data)}
              propStyle={{
                rootIcon:
                  "pro-d-flex pro-items-center pro-rounded-circle pro-overflow-hidden",
              }}
            />
        </div>
      ),
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (paymentsState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = paymentsState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const headerLabel = {
    pending_deduction_amount: "Pending Amount",
    pending_requests: "Pending Requests",
    total_deducted_amount: "Total Amount",
    total_requests: "Total Requests",
  };

  const HeaderValue = {
    pending_deduction_amount:
      paymentReport?.data?.pending_deduction_amount !== undefined || null
        ? `${"AED " + paymentReport?.data?.pending_deduction_amount}`
        : " Unavailable",
    pending_requests: `${` ${paymentReport?.data?.pending_requests}` ?? "Unavailable"
      }`,
    total_deducted_amount:
      paymentReport?.data?.total_deducted_amount !== undefined || null
        ? `${` ${"AED " + paymentReport?.data?.total_deducted_amount}`}`
        : "Unavailable",
    total_requests:
      paymentReport?.data?.total_requests !== undefined || null
        ? `${` ${paymentReport?.data?.total_requests}`}`
        : "Unavailable",
  };

  const paymentReportDetails = Object.keys(HeaderValue).map((key) => {
    return {
      label: headerLabel[key],
      value:
        typeof HeaderValue[key] === "string"
          ? HeaderValue?.[key]
          : typeof HeaderValue[key] === "number"
            ? HeaderValue?.[key]
            : HeaderValue?.[key]?.name,
    };
  });

  const handleExportTable = () => {
    let data = {
      search: paymentsState.search,
      status:
        paymentsState?.activeTab.charAt(0).toUpperCase() +
        paymentsState?.activeTab.slice(1),
    };

    payoutTableExport(data).then((response) => {
      if (response?.data?.code === 200) {
        toast.success(
          response?.data?.message ?? "Table has send to registered email"
        );
      } else {
        toast.error("Something went wrong");
      }
    });
  };
  const handleClosedeclineModal = () => {
    dispatch(
      updateConfig((state) => {
        state.declineStopModal = false;
      })
    );
  }

  return {
    paginationOptions,
    paymentsList,
    paymentsState,
    isFetching,
    isLoading,
    tableFields,
    showEditModal,
    tabNavs: navigation,
    paymentReportDetails,
    paymentReportFetching,
    handleSearch,
    handleSort,
    handlePageSize,
    handleExportTable,
    handlePagination,
    handleEditColumnsClick,
    handleClearClick,
    getRow,
    updatePaymentsFields,
    refetch,
    handleClosedeclineModal,
    paymentReportRefetch
  };
};

export default usePayout;
