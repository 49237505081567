import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useFilterWallet from "./useFilterWallet";

const FilterWallet = ({ refetch }) => {
  const { formik, handleCloseModal, basicData } = useFilterWallet({
    refetch,
  });
  return (
    <div className="col">
      <div className="pro-mb-4">
        <label
          htmlFor="status"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Status
        </label>
        <Select
          id="status"
          placeholder={"Status"}
          className={`pro-input lg`}
          classNamePrefix="pro-input"
          name="status"
          options={basicData?.wallet_transaction_type ?? []}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={formik?.values?.status}
          onBlur={formik.handleBlur("status")}
          onChange={(value) =>
            formik.setFieldValue("status", value ?? "")
          }
          menuPlacement="auto"
          isClearable
        />
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end pro-py-4  pro-mt-auto offcanvas-footer-sticky-btn`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          onClick={formik?.handleSubmit}
          type="submit"
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default FilterWallet;
