import React from "react";
import Style from "../dos.module.scss";
import useDons from "./useDons";

const Dons = ({ formik, itmIndex,isCampaignStatus}) => {
  const { handleRemoveField } = useDons({ formik });
  return (
    <div className={Style.outer_wrap}>
      <div
        className={`${Style.box_container} pro-py-2 pro-d-flex pro-flex-column pro-px-2 pro-rounded-3`}
      >
        <label className="pro-font-sm pro-mb-1 pro-fw-medium">Don'ts</label>
        {formik?.values?.dons?.length > 1 && (
          <div className={`pro-d-flex ${Style.dlt_btn}`}>
            <button
              className="pro-btn pro-btn-link pro-py-0 pro-px-0"
              onClick={() => handleRemoveField(itmIndex)}
              disabled={isCampaignStatus === "stopped"||isCampaignStatus === "completed"}
            >
              <span className="material-symbols-outlined x4">close</span>
            </button>
          </div>
        )}
        <div className="input-wrap flex-fill">
          <textarea
            id={`don${[itmIndex]}`}
            rows={1}
            name={`don${[itmIndex]}`}
            className={`pro-input lg ${
              formik.errors.dons?.[itmIndex]?.don &&
              formik.touched?.dons?.[itmIndex]?.don &&
              "error"
            }`}
            value={formik?.values?.dons?.[itmIndex]?.don ?? ""}
            onBlur={formik?.handleBlur(`dons.${itmIndex}.don`)}
            onChange={(e) =>
              formik.setFieldValue(`dons.${itmIndex}.don`, e.target.value)
            }
            disabled={isCampaignStatus === "stopped"||isCampaignStatus === "completed"}
          ></textarea>
          {formik.touched.dons?.[itmIndex]?.don &&
            formik.errors.dons?.[itmIndex]?.don && (
              <span className="error-text">
                {formik.errors.dons?.[itmIndex]?.don}
              </span>
            )}
        </div>
        {/* <div
          className={`${Style.delete_btn} pro-justify-center pro-d-flex pro-items-center`}
        >
          {formik?.values?.dons?.length > 1 && (
            <span
              className="material-symbols-outlined x4"
              onClick={() => handleRemoveField(itmIndex)}
            >
              delete
            </span>
          )}
        </div> */}
      </div>
      <div
        className={`${Style.box_container} pro-py-2 pro-d-flex pro-flex-column pro-px-2 pro-rounded-3`}
      >
        <label className="pro-font-sm pro-mb-1 pro-fw-medium pro-rtl">
          (لا تفعل ذلك ) Don'ts
        </label>
        <div className="input-wrap flex-fill pro-rtl">
          <textarea
            id={`ar_don${[itmIndex]}`}
            rows={1}
            name={`ar_don${[itmIndex]}`}
            className={`pro-input lg ${
              formik.errors.dons?.[itmIndex]?.ar_don &&
              formik.touched?.dons?.[itmIndex]?.ar_don &&
              "error"
            }`}
            value={formik?.values?.dons?.[itmIndex]?.ar_don ?? ""}
            onBlur={formik?.handleBlur(`dons.${itmIndex}.ar_don`)}
            onChange={(e) =>
              formik.setFieldValue(`dons.${itmIndex}.ar_don`, e.target.value)
            }
            disabled={isCampaignStatus === "stopped"||isCampaignStatus === "completed"}
          ></textarea>
          {formik.touched.dons?.[itmIndex]?.ar_don &&
            formik.errors.dons?.[itmIndex]?.ar_don && (
              <span className="error-text">
                {formik.errors.dons?.[itmIndex]?.ar_don}
              </span>
            )}
        </div>
      </div>
      {/* {formik?.values?.dons?.length > 1 && (
        <div className={`pro-justify-end pro-d-flex pro-mt-1`}>
          <button
            className="pro-btn pro-btn-link pro-py-2"
            onClick={() => handleRemoveField(itmIndex)}
          >
            Delete
          </button>
        </div>
      )} */}
    </div>
  );
};

export default Dons;
