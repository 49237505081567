import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../Store/Slices/Notifications/notificationSlice";
import { updateConfig as updateSupportConfig } from "../../../Store/Slices/Support/suppportSlice";
import { useSelector } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../Store/Slices/Global";
import { useNavigate } from "react-router-dom";
import {
  useGetNotificationsListDataQuery,
  useUpdateNotificationTableHeadDataMutation,
} from "../../../Store/Queries/Notifications";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
import Assets from "../../../Assets";
import {
  markNotificationAsRead,
  MarkNotificationRead,
} from "../../../Pages/Layouts/CommonLayout/HeaderActions/api";
import { formattedDate, getFormatedDate } from "../../../utils/Table";
import { subDays } from "date-fns";

const useNotificationListing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notificationState = useSelector((state) => state.notifications);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const [initialDate, setInitialDate] = useState({
    startDate: notificationState.startDate
      ? notificationState.startDate
      : subDays(new Date(), 60),
    endDate: notificationState.endDate
      ? notificationState.endDate
      : subDays(new Date(), 0),
    key: "selection",
  });
  const {
    data: notificationList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetNotificationsListDataQuery({
    items_per_page: notificationState.currentPageSize,
    current_page: notificationState.currentPage,
    sort_by: notificationState.sortBy,
    sort_order: notificationState.sortOrder,
    search: notificationState.search,
    start_date: notificationState.startDate
      ? getFormatedDate(notificationState.startDate)
      : getFormatedDate(subDays(new Date(), 60)),
    end_date: notificationState.endDate
      ? getFormatedDate(notificationState.endDate)
      : getFormatedDate(subDays(new Date(), 0)),
    notification_type: notificationState.notification_type?.id,
  });
  const [updateNotificationsFields] =
    useUpdateNotificationTableHeadDataMutation();
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = notificationList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [notificationList]);
  const handleClick = (data) => {
    if (notificationList?.data?.permission?.can_view_user && data?.user_id) {
      navigate({ pathname: `/user-details/${data?.user_id}` });
    } else {
      return;
    }
  };
  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      read_status: (feild, data) => {
        const status = data[feild];
        return (
          <>
            {status ? (
              <span className="material-symbols-outlined x4 icon-check">check</span>
            ) : (
              <span className="material-symbols-outlined x4 icon-close">close</span>
            )}
          </>
        );
      },
      user: (feild, data) => (
        <div
          className={
            notificationList?.data?.permission?.can_view_user
              ? `pro-pnt text-truncate`
              : `pro-pnt-none`
          }
        >
          {data?.user_id && (
            <IconText
              icon={
                <img
                  src={data["user_thumb_image"] ?? Assets.PROFILE_USER}
                  alt={"profile-pic"}
                  width={20}
                  height={20}
                  className="object-fit-cover"
                />
              }
              title={data[feild]}
              onClick={() => handleClick(data)}
              propStyle={{
                rootIcon:
                  "pro-d-flex pro-items-center pro-rounded-circle pro-overflow-hidden",
              }}
            />
          )}
        </div>
      ),
      notification: (feild, data) => (
        <p
          className={`pro-mb-0 pro-pnt text-truncate `}
          onClick={() => {
            handleRedirect(data);
          }}
        >
          {data?.[feild]}
        </p>
      ),
      created_at: (feild, data) => (
        <p className="pro-mb-0">{formattedDate(data?.[feild])}</p>
      ),
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };
  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = 10;
        state.currentPage = 1;
        state.sortBy = "";
        state.sortOrder = "";
        state.startDate = getFormatedDate(subDays(new Date(), 30));
        state.endDate = getFormatedDate(subDays(new Date(), 0));
        state.search = "";
        state.notification_type = "";
      })
    );
    setInitialDate({
      startDate: subDays(new Date(), 30),
      endDate: subDays(new Date(), 0),
      key: "selection",
    });
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handleRedirect = async (item) => {
    const url = item?.redirection_url;
    const id = item?._id;
    if (item?.redirection_url.includes("/CMS/support")) {
      dispatch(
        updateSupportConfig((state) => {
          state.chatId = item?.redirection_id;
          state.chatModal = true;
        })
      );
    }
    let data = {
      notification_id: id,
    };
    MarkNotificationRead(data).then(() => {});
    refetch();
    navigate(`${url}`);
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (notificationState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            notificationState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };
  const handleDateChange = (date) => {
    dispatch(
      updateConfig((state) => {
        state.startDate = new Date(date.startDate);
        state.endDate = new Date(date.endDate);
      })
    );
  };
  const handleCloseFilter = () => {
    dispatch(updateConfig((state) => (state.createFilterModal = false)));
  };
  const handleFilterClick = () => {
    dispatch(
      updateConfig((state) => {
        state.isFilter = false;
        state.createFilterModal = true;
      })
    );
  };
  const handleNotificationsRedirect = () => {
    markNotificationAsRead().then((response) => {
      if (response?.data?.code === 200) {
        dispatch(
          updateConfig((state) => {
            state.isNewNotification = false;
          })
        );
      }
    });
    refetch();
    handleModal()
  };
  const handleModal = () => {
    dispatch(
      updateConfig((state) => {
        state.deleteModal = !state.deleteModal;
      })
    );
  };
  return {
    notificationList,
    notificationState,
    isLoading,
    isFetching,
    tableFields,
    paginationOptions,
    initialDate,
    showEditModal,
    updateNotificationsFields,
    getRow,
    handleRedirect,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handlePageSize,
    handleSearch,
    handleClearClick,
    handleDateChange,
    handleCloseFilter,
    refetch,
    handleFilterClick,
    handleNotificationsRedirect,
    handleModal
  };
};

export default useNotificationListing;
