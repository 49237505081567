import React from "react";
import Style from "./profilecard.module.scss";
import Assets from "../../../../../../Assets";

const ProfileCard = ({
  formik,
  userData,
  handleProfileImageChange,
  imagePreview,
  handleDeleteImage,
}) => {
  return (
    <div className={Style.root}>
      <div className={Style.img_wrap}>
        <figure className="pro-mb-0">
          <label htmlFor="profileInput">
            {imagePreview ? (
              <img
                src={imagePreview}
                alt="staff_image"
                onClick={handleProfileImageChange}
              />
            ) : (
              <img
                src={Assets?.ADMIN_PROFILE}
                alt="staff_image"
                onClick={handleProfileImageChange}
              />
            )}
          </label>
          <input
            type="file"
            accept=".jpeg, .jpg , .png"
            className={`pro-input ${" error"}`}
            style={{ display: "none" }}
            id="profileInput"
            name="profile"
            onChange={(e) => handleProfileImageChange(e)}
          />
        </figure>
        <span
          className={`material-symbols-outlined x4 ${Style.image_delete}`}
          onClick={handleDeleteImage}
        >
          delete
        </span>
        {formik.touched.image && formik.errors.image && (
          <span className={`${Style.image_error}`}>
            {formik?.errors?.image}
          </span>
        )}
      </div>
      <div className="pro-ps-4 pro-pt-1 pro-pb-2 pro-d-flex pro-flex-column">
        {userData?.first_name && (
          <h4 className="pro-ttl h4 pro-mb-1">
            {(userData?.first_name ?? " ") + " " + (userData?.last_name ?? "")}
          </h4>
        )}
        {<p className="pro-mb-2">{userData?.role_name}</p>}
      </div>
      <div className="input-wrap pro-mb-4">
        <div className="input-drag">
          {/* This input is hidden and triggered by the label */}
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
