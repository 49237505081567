import React from "react";
import EmptyData from "../../Global/EmptyData";
import DashboardHero from "../DashboardHero";
import Style from "./dashboardListing.module.scss";
import useDashboardHome from "./useDashboardHome";
import {
  DataContainer,
  HeadingGroup,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import Charts from "../../Global/Charts";
import Assets from "../../../Assets";

const DashboardHome = () => {
  const {
    dashboardItems,
    dashboardData,
    isFetching,
    listingType,
    initialDate,
    tableFields,
    dashboardState,
    doughnutOptions,
    lineOptions,
    barOptions,
    doughNutData,
    lineData,
    chartRef,
    barData,
    dashboardGraph,
    getRow,
    handleDateChange,
    handleSort,
  } = useDashboardHome();
  return (
    <div className={`${Style.dashboard_wrap} pro-mb-6`}>
      <div className="row ">
        <div className="col ">
          <HeadingGroup
            title={"Overview"}
            extraClassName={`pro-mb-0 heading-wrap`}
          />
        </div>
        <div className="col-auto">
          <SearchFilters
            searchable={false}
            showActions={false}
            isDateRange
            showDateRange={true}
            initialDateRange={initialDate}
            onDateChange={handleDateChange}
          />
        </div>
      </div>
      <div className={`pro-w-100 pro-my-5`}>
        <DashboardHero
          data={dashboardData?.data?.count}
          obj={dashboardItems}
          loading={isFetching}
          listingType={listingType}
          shimmerCount={18}
          isDashboard={true}
        />
      </div>
      <div className={`pro-w-100 pro-my-5`}>
        <div className="row gx-5 pro-mb-5">
          <div className="col-lg-4 ">
            <div className={`${Style.graph_box} flex-fill`}>
              <DataContainer>
                <div
                  className={`${Style.title_wrap} pro-border-bottom  pro-pb-1 pro-mb-5 `}
                >
                  <h6 className="pro-ttl h4 pro-px-4">
                    Social Media Connection Count
                  </h6>
                </div>
                <div className={Style.doughnut_chart_wrap}>
                  <div className={Style.doughnut_chart}>
                    <Charts
                      type="doughnut"
                      labels={doughNutData?.labels}
                      datasets={doughNutData?.datasets}
                      options={doughnutOptions}
                    />
                  </div>
                  <div className={Style.doughnut_chart_labels}>
                    <ul>
                      {dashboardGraph?.data?.data?.pie_chart?.labels.map(
                        (item, index) => (
                          <li key={index}>
                            <span className={Style.icon}>
                              <img
                                src={
                                  item === "Instagram"
                                   ? Assets.INSTAGRAM_ICON
                                   : item === "Youtube"
                                   ? Assets.YOUTUBE_ICON
                                   : item === "Facebook"
                                   ? Assets.FACEBOOK_ICON
                                   : item === "Tiktok"
                                   ? Assets.TIKTOK_ICON
                                   : Assets.DEFAULT_ICON 
                               }
                                alt={item}
                              />
                            </span>
                            {item}{" "}
                            <span className={Style.count}>
                              {dashboardGraph?.data?.data?.pie_chart?.data[index]}
                            </span>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
              </DataContainer>
            </div>
          </div>

          <div className="col-lg-4">
            <div className={`${Style.graph_box} flex-fill`}>
              <DataContainer>
                <div
                  className={`${Style.title_wrap} pro-border-bottom pro-pb-1  pro-mb-5 `}
                >
                  <h6 className="pro-ttl h4 pro-px-4">Influencers</h6>
                </div>
                <Charts
                  type="line"
                  labels={lineData?.labels}
                  datasets={lineData?.datasets}
                  options={lineOptions}
                  ref={chartRef}
                />
              </DataContainer>
            </div>
          </div>

          <div className="col-lg-4">
            <div className={`${Style.graph_box} flex-fill`}>
              <DataContainer>
                <div
                  className={`${Style.title_wrap} pro-border-bottom  pro-pb-1 pro-mb-5 `}
                >
                  <h6 className="pro-ttl h4 pro-px-4">Payment</h6>
                </div>
                <Charts
                  type="bar"
                  labels={barData?.labels}
                  datasets={barData?.datasets}
                  options={barOptions}
                  ref={chartRef}
                />
              </DataContainer>
            </div>
          </div>
        </div>
      </div>

      {dashboardData?.data?.data &&
      Object.keys(dashboardData?.data?.data)?.length !== 0 ? (
        <div className={` ${Style.tbl_col_title}`}>
          <div
            className={`${Style.title_wrap}  pro-bg-white pro-border-left pro-border-right pro-border-top pro-pb-1 pro-pt-4`}
          >
            <h6 className="pro-ttl h4 pro-px-4">
              Social Media Connection Count
            </h6>
          </div>
          <Table
            extraClasssName={`table-wrap`}
            multiSelect={false}
            data={dashboardData?.data?.data || []}
            uniqueID={"_id"}
            fields={tableFields}
            SortIcon={<FaSort />}
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            perpage={dashboardState?.currentPageSize}
            editable={false}
            deletable={false}
            assignable={false}
            showCheckBox={false}
          />
        </div>
      ) : (
        <>
          {!isFetching ? (
            <EmptyData />
          ) : (
            <div className="app-loading fill"></div>
          )}
        </>
      )}
    </div>
  );
};

export default DashboardHome;
