import React from "react";
import Style from "./socialContainerShimmer.module.scss";

const SocialContainnerShimmer = () => {
  return (
    <div className="pro-w-100">
      <div className={`pro-p-3 ${Style.shimmer_container}`}>
        <div className="pro-d-flex pro-mb-1 pro-gap-2">
          <div className="shimmer pro-px-4 pro-py-4 pro-rounded-3"></div>
          <div className="shimmer pro-px-6 pro-py-3 para"></div>
        </div>
        {[...Array(3)].map((_, index) => {
          return (
            <div
              className="pro-d-flex pro-mb-1 pro-gap-2 pro-d-flex pro-w-100"
              key={index}
            >
              <div className="shimmer pro-px-6 pro-py-3 para"></div>
              <div className="shimmer pro-px-4 pro-py-4 pro-rounded-3"></div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SocialContainnerShimmer;
