import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { validateOTP } from "../api";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../Store/Slices/Global";
import useValidations from "../../../utils/hooks/useValidations";

const useForgotPassword = (emailRef) => {
  const dispatch = useDispatch();
  const { onlyNumbers } = useValidations();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [errors, setErrors] = useState("");
  const [otpStatus, setOtpStatus] = useState("idle");
  const [otpInfo, setOtpInfo] = useState({});

  useEffect(() => {
    if (!emailRef?.current) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailRef]);

  useEffect(() => {
    if (otpStatus === "success") {
      navigate("/login/reset-password");
    } else if (otpStatus === "failed") {
      setErrors(otpInfo?.otp);
    }
    // eslint-disable-next-line
  }, [otpStatus]);

  const handleBlur = () => {
    setErrors("");
  };

  const handleChange = (e) => {
    setErrors("");
    if (
      (onlyNumbers(e.target.value) && e.target.value.length <= 6) ||
      e.target.value.length === 0
    ) {
      setOtp(e.target.value);
    }
  };

  const handleLoginClick = () => {
    navigate("/login");
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    if (otp) {
      setOtpStatus("pending");
      validateOTP({ email: emailRef.current, otp: parseInt(otp) }).then(
        (response) => {
          if (response?.status === 200) {
            setOtpStatus("success");
            dispatch(
              updateConfig(
                (state) => (state.adminID = response?.data?.data?.admin_id)
              )
            );
          } else if (response?.code === 400) {
            setOtpInfo({
              otp: response?.message?.otp?.[0],
            });
            setOtpStatus("failed");
          } else if (response?.code === 422) {
            setOtpInfo({
              otp: response?.errors?.otp,
            });
            setOtpStatus("failed");
          } else setOtpStatus("An error occured");
        }
      );
    } else {
      setErrors("*OTP cannot be empty");
    }
  };

  return {
    otp,
    errors,
    otpStatus,
    handleChange,
    handleSubmit,
    handleBlur,
    handleLoginClick,
  };
};

export default useForgotPassword;
