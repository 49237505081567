import { getAxiosInstance } from "../../../../API";

export const campaignCreate = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/campaign/create`, body);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const campaignCreateUpload = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/campaign/file-add`, body);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const campaignCreateDelete = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `/admin/campaign/file-delete?campaign_id=${params?.campaign_id}&category=${params?.category}&file_type=${params?.file_type}&file_url=${params?.file_url}`
    );
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getCampaignEditData = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/admin/campaign/${id}/view`);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const campaignUpdate = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/campaign/update`, body);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
