import { useFormik } from "formik";
import countryData from "../../../utils/countryCode";
import { useEffect, useState } from "react";
import { getContactData, updateContactData } from "./api";
import { toast } from "react-toastify";

const useContact = () => {
  const [canUpdate, setCanUpdate] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      country_code: "",
      mobile: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.name.trim()) {
        errors.name = "Name is required";
      }
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!values.email.trim()) {
        errors.email = "Email is required";
      } else if (!emailRegex.test(values.email)) {
        errors.email = "Invalid email format";
      }
      const mobileRegex = /^\d{7,17}$/;
      if (!values.mobile.trim()) {
        errors.mobile = "Mobile is required";
      } else if (!mobileRegex.test(values.mobile)) {
        errors.mobile = "Mobile must be a number with 7 to 17 digits";
      }

      return errors;
    },
    onSubmit: (values) => {
      let data = {
        name: values?.name,
        email: values?.email,
        country_short_name: values?.country_code?.code,
        country_code: values?.country_code?.value,
        mobile: values?.mobile,
      };
      updateContactData(data).then((resp) => {
        if (resp?.data?.code === 200) {
          toast.success("Updated");
        } else if (resp?.code === 422) {
          let errors = resp?.errors;
          let errorFields = Object.keys(errors);
          errorFields?.forEach((field) => {
            formik.setFieldError(field, errors[field]);
          });
        } else {
          toast.error("Something went wrong");
        }
      });
    },
  });

  const getContactDetails = () => {
    getContactData().then((res) => {
      if (res?.data?.code === 200) {
        setCanUpdate(res?.data?.data?.permissions?.can_update);
        const data = res?.data?.data?.data;
        const countryCode = countryData?.filter(
          (val) => val?.value === data?.country_code
        );
        formik?.setFieldValue("name", data?.name);
        formik?.setFieldValue("email", data?.email);
        formik?.setFieldValue("mobile", data?.mobile_number);
        formik?.setFieldValue("country_code", countryCode[0]);
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  useEffect(() => {
    getContactDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleReset = () => {
    getContactDetails();
  };
  return {
    formik,
    canUpdate,
    countryCodeOptions: countryData,
    handleReset,
    getFieldError,
  };
};

export default useContact;
