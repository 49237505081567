import { getAxiosInstance } from "../../../API";

export const createInterest = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/interest/create`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const updateInterest = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/interest/update`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const viewInterest = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/admin/interest/view?interest_id=${id}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const updateStatus = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/interest/status-change`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
