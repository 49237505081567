import { FormTabs } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useCreateForm from "./useCreateForm";
import Style from "./form.module.scss";

const CreateForm = ({ refetch }) => {
  const {
    tabs,
    activeTab,
    formik,
    map,
    setMap,
    renderTabContent,
    handleTabClick,
  } = useCreateForm({ refetch });
  return (
    <div className="pro-h-100 pro-d-flex pro-flex-column">
      <FormTabs
        propStyle={{ root: Style.root }}
        map={map}
        tabs={tabs}
        active={activeTab}
        handleClick={handleTabClick}
        setMap={setMap}
      />
      <form className="pro-h-100" onSubmit={formik.handleSubmit}>
        {renderTabContent()}
      </form>
    </div>
  );
};

export default CreateForm;
