import { getAxiosInstance } from "../../../API";

export const getContactData = async () => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/admin/contact/view`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const updateContactData = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/contact/update`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
