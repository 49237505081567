import { getAxiosInstance } from "../../../API";

export const createFAQ = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/faq/create`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const updateFAQ = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/faq/update`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const viewFAQ = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/admin/faq/view?faq_id=${id}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteFAQ = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/admin/faq/delete?faq_id=${id}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const updateStatus = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/faq/status-change`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
