import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../Store/Slices/User/userSlice";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { addWalletAmount } from "../api";
import { toast } from "react-toastify";

const useAddAmount = ({ refetch }) => {
  const { userID } = useParams();
  const activeUser = userID.split(/active="[^"]*"(?:&type="[^"]*")?/)[0] ||
  sessionStorage.getItem("active-user");
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      amount: "",
      details: "",
      ar_details: "",
    },
    validate: (values) => {
      let errors = {};
      if (!values?.amount) {
        errors.amount = "*Enter Amount";
      }
      if (values?.amount) {
        if (!/^[0-9]+(\.[0-9]+)?$/.test(values?.amount)) {
          errors.amount = "Enter Valid Amount";
        }
      }
      if (!values?.details) {
        errors.details = "*Enter Details";
      }
      if (!values?.ar_details) {
        errors.ar_details = "*Enter Details (تفاصيل)";
      }
      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      let data = {
        user_id: activeUser,
        amount: values?.amount,
        details: values?.details,
        ar_details: values?.ar_details,
      };
      addWalletAmount(data).then((response) => {
        if (response?.data?.code === 200) {
          handleCloseModal();
          toast.success("Amount Added");
          refetch();
        } else if (response?.code === 422) {
          let errors = response?.errors;
          let errorFields = Object.keys(errors);
          errorFields?.forEach((field) => {
            formik.setFieldError(field, errors[field]);
          });
          setSubmitting(false);
        } else {
          toast.error("Something went wrong");
          setSubmitting(false);
        }
      });
    },
  });

  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.addAmountModal = false)));
  };

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  return {
    formik,
    getFieldError,
    handleCloseModal,
  };
};

export default useAddAmount;
