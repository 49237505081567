import { getAxiosInstance } from "../../../API";

export const changeInterestStatus = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(
      `admin/interest_request/status-change`,
      body
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};
