import React from "react";
import {
  HeadingGroup,
  ModalLayout,
} from "@wac-ui-dashboard/wac_component_library";
import EditTerms from "./EditTerms";
import Style from "./terms.module.scss";
import useTerms from "./useTerms";

const Terms = () => {
  const { terms, editModal, refetch, handleCloseModal, handleOpenModal } =
    useTerms();
  const parse = require("html-react-parser");
  const content = terms?.data?.data?.description ?? "";
  const ar_content = terms?.data?.data?.ar_description ?? "";

  return (
    <>
      <HeadingGroup extraClassName={`pro-mb-2`}>
        {terms?.data?.permissions?.can_update && (
          <button
            className="pro-btn pro-btn-primary pro-items-center"
            onClick={handleOpenModal}
          >
            <span className="material-symbols-outlined">edit_square</span>
            
            <span>Edit</span>
          </button>
        )}
      </HeadingGroup>
      <div className={Style.main_policy}>
        <div className={Style.main_policy_half}>
          <HeadingGroup
            title={"Terms And Conditions"}
            extraClassName={`pro-mb-2 pro-mt-3`}
          />
          <div className={`pro-pb-5 ${Style.admin_content_area}`}>
            {parse(content)}
          </div>
        </div>
        <div className={Style.main_policy_half}>
          <HeadingGroup
            title={"الشروط والأحكام"}
            extraClassName={`pro-mb-2 pro-mt-3 pro-rtl`}
          />
          <div className={`pro-pb-5 pro-rtl ${Style.admin_content_area}`}>
            {parse(ar_content)}
          </div>
        </div>
      </div>

      <ModalLayout
        centered={false}
        show={editModal}
        handleClose={handleCloseModal}
        title={"Update Terms And Conditions"}
        closeIcon={<span className="material-symbols-outlined">close</span>}
        backdrop="static"
        propStyle={{ root: Style.modal_root }}
      >
        <div className="modal_content_wrap">
          <EditTerms closeModal={handleCloseModal} refetch={refetch} />
        </div>
      </ModalLayout>
    </>
  );
};

export default Terms;
