import {
  DataContainer,
  OffCanvasLayout,
  SearchFilters,
  SimpleReport,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Style from "./brands_details.module.scss";
import useBrandsDetail from "./useBrandsDetail";
import EmptyData from "../../../Global/EmptyData";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../../Global/OrderColumn";
import { Pagination } from "../../../Global/Pagination";

const BrandDetails = () => {
  const {
    isLoading,
    tableFields,
    headerDetails,
    reportFetching,
    tableFetching,
    showEditModal,
    brandCreateState,
    initialDate,
    paginationOptions,
    brandDetailsTableList,
    getRow,
    refetch,
    handleSort,
    handlePageSize,
    handleExportTable,
    handleClearClick,
    handleEditColumnsClick,
    handleSearch,
    handleDateChange,
    handlePagination,
    updateBrandDetailsTableFields,
  } = useBrandsDetail();


  return (
    <div className="pro-m-4 pro-mx-5">
      <DataContainer>
        {reportFetching ? (
          <p>Loading</p>
        ) : (
          <SimpleReport
            data={headerDetails}
            propStyle={{ item: Style.report_root }}
          />
        )}
      </DataContainer>
      <div className="col-auto pro-pt-3 ">
        <SearchFilters
          onSearchInput={handleSearch}
          showActions={true}
          handleActionClick={handleEditColumnsClick}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters
          handleClear={handleClearClick}
          searchInputProps={{
            value: brandCreateState?.detailsPage.search,
          }}
          isDateRange
          showDateRange={true}
          initialDateRange={initialDate}
          onDateChange={handleDateChange}
          filterChildren={
            <>
              {brandDetailsTableList?.data?.permissions?.can_export && (
                <div className="col-auto pro-ms-auto">
                  <button
                    className={"pro-btn pro-items-center pro-btn-outline "}
                    onClick={handleExportTable}
                  >
                    <span className="material-symbols-outlined x4">
                      outgoing_mail
                    </span>
                    <span>Export Table</span>
                  </button>
                </div>
              )}
            </>
          }
        />
      </div>
      {brandDetailsTableList?.data?.data?.length > 0 ? (
        <>
          <Table
            multiSelect={false}
            data={brandDetailsTableList?.data?.data || []}
            uniqueID={"_id"}
            fields={tableFields}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            getRow={getRow}
            loading={tableFetching}
            perpage={brandCreateState?.detailsPage.currentPageSize}
            editable={false}
            deletable={false}
            assignable={false}
            handleSort={handleSort}
            SortIcon={<FaSort />}
            extraClasssName={`table-wrap`}
          />
          {brandDetailsTableList?.data?.data?.length > 0 && (
            <Pagination
              currentPage={brandCreateState?.detailsPage?.currentPage}
              totalPageCount={Math.ceil(
                brandDetailsTableList?.data?.total_count /
                  brandCreateState?.detailsPage?.currentPageSize
              )}
              options={paginationOptions}
              onPageChange={handlePagination}
              onActionChange={handlePageSize}
              center
            />
          )}
        </>
      ) : (
        <EmptyData />
      )}
      <OffCanvasLayout
        show={showEditModal}
        handleClose={handleEditColumnsClick}
        title={"Choose which columns you see"}
      >
        <OrderColumn
          refetchData={refetch}
          tableFields={brandDetailsTableList?.data?.fields}
          moduleId={brandDetailsTableList?.data?.table_id}
          updateData={updateBrandDetailsTableFields}
        />
      </OffCanvasLayout>
    </div>
  );
};

export default BrandDetails;
