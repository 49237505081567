import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../Store/Slices/Template/templateSlice";
import { createTemplate, updateTemplate } from "../api";
import { toast } from "react-toastify";
import { useEffect } from "react";
import * as Yup from "yup";

const useAddTemplate = ({ refetch }) => {
  const dispatch = useDispatch();
  const { isEdit, itemData, itemId } = useSelector((state) => state.template);

  const validationSchema = Yup.object({
    title: Yup.string().required("Enter Title").matches(/^[^\s].*$/, "No leading or trailing spaces allowed"),
    ar_title: Yup.string().required("(أدخل العنوان)Enter Title").matches(/^[^\s].*$/, "(لا يسمح بالمسافات في البداية أو النهاية)No leading or trailing spaces allowed"),
    message: Yup.string().required("Enter Message").matches(/^[^\s].*$/, "No leading or trailing spaces allowed"),
    ar_message: Yup.string().required("(أدخل الرسالة)Enter Message").matches(/^[^\s].*$/, "(لا يسمح بالمسافات في البداية أو النهاية)No leading or trailing spaces allowed"),
  });
  const formik = useFormik({
    initialValues: {
      title: "",
      ar_title: "",
      message: "",
      ar_message: "",
    },
    validationSchema,
    onSubmit: (values) => {
      if (isEdit) {
        let data = {
          id: itemId,
          title: values?.title,
          ar_title: values?.ar_title,
          message: values?.message,
          ar_message: values?.ar_message,
        };
        updateTemplate(data).then((response) => {
          if (response?.data?.code === 200) {
            toast.success("Added");
            refetch();
            handleCloseModal();
          } else if (response?.code === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
          } else {
            toast.error("Something went wrong");
          }
        });
      } else {
        let data = {
          title: values?.title,
          ar_title: values?.ar_title,
          message: values?.message,
          ar_message: values?.ar_message,
        };
        createTemplate(data).then((response) => {
          if (response?.data?.code === 200) {
            toast.success("Added");
            refetch();
            handleCloseModal();
          } else if (response?.code === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
          } else {
            toast.error("Something went wrong");
          }
        });
      }
    },
  });

  useEffect(() => {
    if (isEdit && itemData) {
      formik?.setFieldValue("title", itemData?.title);
      formik?.setFieldValue("ar_title", itemData?.ar_title);
      formik?.setFieldValue("message", itemData?.message);
      formik?.setFieldValue("ar_message", itemData?.ar_message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, itemData]);

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.createModal = false;
        state.itemData = "";
      })
    );
  };
  return {
    formik,
    isEdit,
    getFieldError,
    handleCloseModal,
  };
};

export default useAddTemplate;
