import { getAxiosInstance } from "../../API";

export const brandsInnerTableExport = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(
      "/admin/brand/brand-collaboration-export",
      body
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};
